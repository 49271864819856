import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    listPart: [],
    listQuestion: [],
    listChoice: [],
    listFlag: [],
    currentPart: null,
    currentQuestion: null,
    totalQuestion: null,
    startPart: [],
};

const topicSlice = createSlice({
    name: 'topic',
    initialState,
    reducers: {
        setListPart: (state, action) => {
            state.listPart = action.payload;
        },
        setListQuestion: (state, action) => {
            state.listQuestion = action.payload;
        },
        setListChoice: (state, action) => {
            state.listChoice = action.payload;
        },
        setListFlag: (state, action) => {
            state.listFlag = action.payload;
        },
        setCurrentQuestion: (state, action) => {
            state.currentQuestion = action.payload;
        },
        setCurrentPart: (state, action) => {
            state.currentPart = action.payload;
        },

        setTotalQuestion: (state, action) => {
            state.totalQuestion = action.payload;
        },

        setStartPart: (state, action) => {
            state.startPart = action.payload;
        },
        goToNextQuestion: (state) => {
            const nextQuestionIndex = state.listQuestion.findIndex((item) => 
                item.questionId === state.currentQuestion) + 1;
            const nextPartIndex = state.listPart.findIndex((item) => 
                item.partId === state.currentPart) + 1;

            if (nextQuestionIndex < state.listQuestion.length) {
                state.currentQuestion = state.listQuestion[nextQuestionIndex].questionId;
                state.currentPart = state.listQuestion[nextQuestionIndex].partId;
            } else if (nextPartIndex < state.listPart.length) {
                state.currentPart = state.listPart[nextPartIndex].partId;
            }
        },
    },
});

export const {
    setListPart,
    setListQuestion,
    setListChoice,
    setListFlag,
    setCurrentPart,
    setCurrentQuestion,
    setTotalQuestion,
    setStartPart,
    goToNextQuestion
} = topicSlice.actions;

export default topicSlice.reducer;
