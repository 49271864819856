import './Fulltest.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import FulltestItem from '../FulltestItem/FulltestItem';

import { useState, useEffect } from 'react';
import axios from '~/api/axios';

function Fulltest(props) {
    const { t } = useTranslation('common');
    const { backgroundColor } = props;

    const controller = new AbortController();
    const [isLoading, setIsLoading] = useState(false);
    const [listTopic, setListTopic] = useState();

    const getListTopic = async () => {
        try {
            setIsLoading(true);

            const response = await axios.get('/topic/listTopic', {
                params: {
                    size: 8,
                    type: 'full',
                    direction: 'DESC',
                },
                signal: controller.signal,
            });

            if (response.data.status === 'OK') {
                setListTopic(response.data.responseData.listTopic);
            }
            console.log(response.data);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getListTopic();

        return () => {
            controller.abort();
        };
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: (
            <button type="button" className="slick-next">
                Next
            </button>
        ),
        prevArrow: (
            <button type="button" className="slick-prev">
                Previous
            </button>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                },
            },
        ],
    };

    return (
        <div
            className={`w-full h-full flex text-center flex-col py-10 bg-[${backgroundColor}]`}
            data-aos="fade-left"
            data-aos-duration="1000"
        >
            <h1 className="lg:text-[40px] text-3xl font-BeVietnamSemiBold text-[#309]">{t('full_test')}</h1>
            {listTopic ? (
                <div className="lg:px-[70px] px-4 pt-6 relative">
                    <Slider {...settings}>
                        {listTopic.map((topic, index) => (
                            <FulltestItem key={index} topic={topic} t={t} />
                        ))}
                    </Slider>
                </div>
            ) : null}
        </div>
    );
}

export default Fulltest;
