import React from 'react';
import { STEPS } from '../../AddExamModal.constant';

const ProgressBar = ({ currentStep }) => {
    return (
        <div className="flex justify-center items-center w-full px-6 mt-4 ml-12">
            <div className="flex justify-center w-full max-w-2xl">
                {STEPS.map((step, index) => (
                    <div key={index} className="flex-1">
                        <div className="flex items-center">
                            {/* Vòng tròn của mỗi bước */}
                            <div
                                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                                    index + 1 <= currentStep ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-500'
                                }`}
                            >
                                {index + 1}
                            </div>
                            {/* Đường nối giữa các bước */}
                            {index < STEPS.length - 1 && (
                                <div
                                    className={`flex-1 h-1 mx-1 ${
                                        index + 1 < currentStep ? 'bg-blue-600' : 'bg-gray-300'
                                    }`}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProgressBar;
