//Core
import React, { useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";

//App
import useAxiosPrivate from "../../../../../../../hooks/useAxiosPrivate";

// Internal
import { uploadImage, deleteImage } from '../../../../../../../api/uploadApi/uploadApi';

// Component
const CustomUpload = ({ onImageUpload, initialImageUrl   }) => {
    //States
    const axiosPrivate = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState(initialImageUrl || '');
    const inputFileRef = useRef(null);

    useEffect(() => {
        setImageUrl(initialImageUrl);
    }, [initialImageUrl]);
    // Methods
    const beforeUploadImage = (file, allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']) => {
        const isAllowedType = allowedTypes.includes(file.type);
        if (!isAllowedType) {
            message.error('Chỉ được upload file hình ảnh');
        }
        return isAllowedType;
    };

    const convertImageToPng = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const newFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".png"), {
                                type: "image/png",
                                lastModified: Date.now()
                            });
                            resolve(newFile);
                        } else {
                            reject(new Error("Lỗi chuyển đổi ảnh"));
                        }
                    }, "image/png");
                };
                img.onerror = () => reject(new Error("Lỗi load ảnh"));
                img.src = e.target.result;
            };
            reader.onerror = () => reject(new Error("Lỗi khi đọc file"));
            reader.readAsDataURL(file);
        });
    };

    const handleUploadImage = async (file) => {
        setUploading(true);
        try {
            const pngFile = await convertImageToPng(file);
            const response = await uploadImage(
                axiosPrivate,
                pngFile,
                '/',
                false,
                '',
                '',
            );
            const imageLink = response.responseData;
            setImageUrl(imageLink);
            onImageUpload(imageLink);
            message.success('Upload ảnh đại diện thành công');
        } catch (error) {
            console.error(error);
            message.error('Upload ảnh đại diện thất bại');
        } finally {
            setUploading(false);
        }
    };

    const handleDeleteImage = async () => {
        setUploading(true);
        try {
            await deleteImage(axiosPrivate, imageUrl);
            message.success('Xóa ảnh thành công');
            setImageUrl('');
            onImageUpload('');
            inputFileRef.current.value = null;
        } catch (error) {
            console.error(error);
            message.error('Xóa ảnh thất bại');
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file && beforeUploadImage(file)) {
            await handleUploadImage(file);
        }
    };

    // Template
    return (
        <div className="flex flex-col items-center justify-center space-y-4">
            {/* text*/}
            <h3 className="text-black-600 font-bold mb-4">Bước 1: Upload ảnh đại diện cho bài thi:</h3>
            {/* upload */}
            <label
                className="flex items-center justify-center w-full h-96 border-4 border-dashed border-gray-400 rounded-lg cursor-pointer"
                onDrop={(e) => {
                    e.preventDefault();
                    const file = e.dataTransfer.files[0];
                    if (file && beforeUploadImage(file)) {
                        handleUploadImage(file);
                    }
                }}
                onDragOver={(e) => e.preventDefault()}
            >
                {imageUrl ? (
                    <div className="relative w-full h-full">
                        <img
                            src={imageUrl}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded-lg"
                        />
                        <div className="absolute top-0 right-0 flex items-center justify-center w-full h-full opacity-0 hover:opacity-100 transition-opacity bg-black bg-opacity-50"
                            onClick={() => inputFileRef.current.click()}
                        >
                            <Button icon={<EyeOutlined />} className="bg-transparent border-none text-white" onClick={(e) => {
                                e.stopPropagation();
                                window.open(imageUrl, '_blank');
                            }} />
                            <Button icon={<DeleteOutlined />} className="bg-transparent border-none text-white" onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteImage();
                            }} />
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center space-y-2">
                        <PlusOutlined className="text-6xl" />
                        <p className="text-gray-500">Kéo thả hoặc nhấp để chọn ảnh</p>
                    </div>
                )}
                <input
                    type="file"
                    ref={inputFileRef}
                    className="hidden"
                    onChange={handleFileChange}
                    disabled={uploading}
                />
            </label>
        </div>
    );
};

export default CustomUpload;
