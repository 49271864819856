
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';

import { changeProfile } from '~/api/userApi';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { EditIcon } from '../../Icons';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { setUser } from '~/redux/userSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

function ChangeProfile(props) {
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const user = useSelector((state) => state.user.user);

    const [disableSelect, setDisableSelect] = useState({
        disableName: true,
        disableAddress: true,
        disablePhone: true,
    });

    const [informationProfile, setInformationProfile] = useState({
        name: '',
        address: '',
        phone: '',
        avatar: '',
        err: {},
    });

    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);

    const handleChangeAvatar = (info) => {
        if (info.fileList.length > 0) {
            const file = info.file.originFileObj;
            if (file) {
                setInformationProfile((prevProfile) => ({
                    ...prevProfile,
                    avatar: file,
                }));
                const reader = new FileReader();
                reader.onload = (info) => {
                    setImageUrl(info.target.result);
                };
                reader.readAsDataURL(file);
            }
        } else {
            setImageUrl(null);
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const validate = () => {
        const vietnameseNameRegex = /^[A-Za-zÀ-ỹ\s]+$/;
        if (informationProfile.name === '') {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Vui lòng nhập họ tên'));
            return false;
        }
        if (!vietnameseNameRegex.test(informationProfile.name)) {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Họ và Tên không được chứa số và ký tự đặc biệt'));
            return false;
        }
        if (informationProfile.address === '') {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Vui lòng nhập địa chỉ'));
            return false;
        }
        if (informationProfile.phone === '') {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Vui lòng nhập số điện thoại'));
            return false;
        }
        const phoneRegex = /^[0-9]+$/;
        if (!phoneRegex.test(informationProfile.phone)) {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Định dạng số điện thoại không đúng'));
            return false;
        }
        if (informationProfile.phone.length !== 10) {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Định dạng số điện thoại không đúng'));
            return false;
        }
        const validPrefixes = ['03', '05', '07', '08', '09'];
        const phonePrefix = informationProfile.phone.substring(0, 2);

        if (!validPrefixes.includes(phonePrefix)) {
            dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
            dispatch(setErrMessage('Định dạng số điện thoại không đúng'));
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (user && user.User) {
            if (user.User.avatar) {
                setImageUrl(user.User.avatar);
            }
            setInformationProfile((prevProfile) => ({
                ...prevProfile,
                name: user.User.name,
                address: user.User.address,
                phone: user.User.phone,
            }));
        }
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                dispatch(startLoading());
                const formData = new FormData();
                formData.append('name', informationProfile.name);
                formData.append('address', informationProfile.address);
                formData.append('phone', informationProfile.phone);
                if (informationProfile.avatar !== '') {
                    formData.append('avatar', informationProfile.avatar);
                }

                const response = await changeProfile(axiosPrivate, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (response?.violations === 401) {
                    dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
                    dispatch(setErrMessage('Unauthorized'));
                } else if (response?.status === 'fail') {
                    dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
                    dispatch(setErrMessage(response?.message));
                } else {
                    setDisableSelect((prev) => ({
                        ...prev,
                        disableName: true,
                        disableAddress: true,
                        disablePhone: true,
                    }));
                    dispatch(setUser(response.responseData));
                    dispatch(setStatusMessage('Cập nhật thông tin thành công'));
                    dispatch(setErrMessage('Thành công'));
                }
            } catch (err) {
                dispatch(setStatusMessage('Cập nhật thông tin thất bại'));
                dispatch(setErrMessage('Cập nhật thông tin thất bại'));
            } finally {
                dispatch(stopLoading());
            }
        }
    };

    return (
        <div className="flex flex-col gap-3 lg:mt-16 mt-4">
            {user ? (
                <form
                    className=" w-full bg-white lg:p-12 p-4 flex flex-col gap-6 font-BeVietNamRegular text-base rounded-xl shadow-[0_4px_10px_0_rgba(0, 0, 0, 0.1)] mb-4"
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <div className="flex flex-row gap-8 items-center">
                        <span>Ảnh đại diện</span>
                        <Upload
                            className="!w-1/3"
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeAvatar}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: "contain",
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </div>

                    <div className="flex flex-col gap-2">
                        <span>Họ và tên:</span>
                        <div className="flex flex-row justify-between items-center">
                            <input
                                type="text"
                                className="lg:w-[90%] w-4/5 h-full pl-4 py-3 font-be-vietnam text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                                defaultValue={user.User.name}
                                onChange={(e) =>
                                    setInformationProfile((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                                disabled={disableSelect.disableName}
                            ></input>
                            <button
                                className="border border-b-[1px] bg-hotlink-gray-f4f4f4 p-2"
                                type="button"
                                onClick={() =>
                                    setDisableSelect((prev) => ({
                                        ...prev,
                                        disableName: false,
                                    }))
                                }
                            >
                                <EditIcon />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span>Email:</span>
                        <input
                            type="text"
                            className="w-full h-full pl-4 py-3 font-BeVietnamSemiBold text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            defaultValue={user.User.email}
                            disabled
                        ></input>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span>Địa chỉ:</span>
                        <div className="flex flex-row justify-between items-center">
                            <input
                                type="text"
                                className="lg:w-[90%] w-4/5 h-full pl-4 py-3 font-BeVietnamSemiBold text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                                defaultValue={user.User.address}
                                onChange={(e) =>
                                    setInformationProfile((prev) => ({
                                        ...prev,
                                        address: e.target.value,
                                    }))
                                }
                                disabled={disableSelect.disableAddress}
                            ></input>
                            <button
                                className="border border-b-[1px] bg-hotlink-gray-f4f4f4 p-2"
                                type="button"
                                onClick={() =>
                                    setDisableSelect((prev) => ({
                                        ...prev,
                                        disableAddress: false,
                                    }))
                                }
                            >
                                <EditIcon />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span>Số điện thoại:</span>
                        <div className="flex flex-row justify-between items-center">
                            <input
                                type="text"
                                className="lg:w-[90%] w-4/5 h-full pl-4 py-3 font-BeVietnamSemiBold text-sm text-hotlink-black-161616 bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                                defaultValue={user.User.phone}
                                onChange={(e) =>
                                    setInformationProfile((prev) => ({
                                        ...prev,
                                        phone: e.target.value,
                                    }))
                                }
                                disabled={disableSelect.disablePhone}
                            ></input>
                            <button
                                className="border border-b-[1px] bg-hotlink-gray-f4f4f4 p-2"
                                type="button"
                                onClick={() =>
                                    setDisableSelect((prev) => ({
                                        ...prev,
                                        disablePhone: false,
                                    }))
                                }
                            >
                                <EditIcon />
                            </button>
                        </div>
                    </div>

                    <button
                        className={`mt-4 text-lg bg-hotlink-yellow-FBCA1F font-BeVietnamSemiBold py-[0.6em] px-[1.3em] font-black border-[3px] border-solid border-black rounded-[0.4em] shadow-[0.1em_0.1em]
                         hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                         active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em] ${disableSelect.disableAddress &&
                                disableSelect.disableName &&
                                disableSelect.disablePhone &&
                                informationProfile.avatar === ''
                                ? 'cursor-not-allowed'
                                : null
                            }`}
                        disabled={
                            disableSelect.disableAddress &&
                            disableSelect.disableName &&
                            disableSelect.disablePhone &&
                            informationProfile.avatar === ''
                        }
                    >
                        Lưu thay đổi
                    </button>
                </form>
            ) : null}
        </div>
    );
}
export default ChangeProfile;
