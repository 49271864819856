import React from 'react';
import { Button, Result } from 'antd';

function ResultPage(props) {
    return (
        <Result
            className="flex justify-center items-center flex-col bg-white rounded-md font-BeVietNamRegular"
            status={props.status}
            title={
                props.status === 'success' 
                    ? 'GỬI EMAIL THÀNH CÔNG' 
                    : 'THẤT BẠI'
            }
            subTitle={
                props.page === 'register'
                    ? (
                        <span className="font-bold text-2xl text-black">
                            Vui lòng đến Email đã đăng ký để xác thực tài khoản, cảm ơn. 
                            <br/>
                            <span className="text-sm font-medium text-gray-800">
                            (Lưu ý: có thể Email bị cho vào mục spam)
                            </span>
                        </span>
                    )
                    : props.page === 'registerConfirm'
                    ? props.status === 'success'
                        ? 'Xác nhận đăng ký thành công. Tới trang đăng nhập'
                        : props.message
                    : props.page === 'changePassword'
                    ? props.status === 'success'
                        ? 'Thay đổi mật khẩu thành công. Tới trang đăng nhập'
                        : props.message
                    : ''
            }
            extra={
                props.page === 'register'
                    ? [
                          <Button type="primary" href="/login" danger key="1">
                              Tới trang đăng nhập
                          </Button>,
                      ]
                    : props.page === 'registerConfirm'
                    ? props.status === 'error'
                        ? [
                              <Button type="primary" href="/register" danger key="2">
                                  Quay lại trang đăng ký
                              </Button>,
                          ]
                        : [
                              <Button type="primary" href="/login" danger key="1">
                                  Tới trang đăng nhập
                              </Button>,
                          ]
                    : props.page === 'changePassword'
                    ? [
                          <Button type="primary" href="/login" danger key="1">
                              Tới trang đăng nhập
                          </Button>,
                      ]
                    : ''
            }
        />
    );
}

export default ResultPage;
