import { Table, Space, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';

import { DetailIcon } from '~/components/Icons';

function ProfileTestItem(props) {
    const navigate = useNavigate();

    const columns = [
        {
            title: 'Ngày làm',
            dataIndex: 'createAt',
            key: 'createAt',
        },
        {
            title: 'Kết quả',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Thời gian làm bài',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Chi tiết',
            dataIndex: 'detail',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Popover content={<div>Thông tin chi tiết</div>}>
                        <button
                            className="flex flex-row gap-3 justify-center items-center"
                            onClick={() => navigate('/test/detail?result=' + record.mockTestID)}
                        >
                            <DetailIcon className="cursor-pointer" />
                        </button>
                    </Popover>
                </Space>
            ),
        },
    ];

    const data = props.listMockTest.map((item, i) => ({
        key: item.mockTestID,
        mockTestID: item.mockTestID,
        number: i,
        score: item.score,
        time: item.time,
        topicId: item.topicId,
        createAt: item.createAt,
        updateAt: item.updateAt,
    }));
    return (
        <div className="bg-white p-8 w-full flex flex-col gap-4 font-BeVietNamMedium text-xs rounded-xl shadow-[0_4px_10px_0_rgba(0, 0, 0, 0.1)] mb-4">
            <h5 className="font-BeVietNamRegular text-lg">{props.topic.topicName}</h5>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
    );
}
export default ProfileTestItem;
