import { MoonOutlined, SettingOutlined, SunOutlined, GlobalOutlined } from '@ant-design/icons';
import { Avatar, Drawer, Switch, Typography, Divider, Select } from 'antd';
import { useState } from 'react';
import { useAppConfigStore } from '../../../../../../stores';

const { Title, Text } = Typography;
const { Option } = Select;

const SettingLayout = () => {
    const [openSettingLayout, setOpenSettingLayout] = useState(false);
    const [language, setLanguage] = useState('en');
    const { theme, toggleTheme } = useAppConfigStore();

    return (
        <div>
            {/* Avatar Icon */}
            <Avatar
                icon={<SettingOutlined />}
                size={35}
                onClick={() => setOpenSettingLayout(true)}
                className="cursor-pointer transition-all duration-300 hover:rotate-12 hover:scale-110 dark:bg-gray-800 dark:text-gray-300"
                style={{ backgroundColor: '#1677ff', color: '#fff' }}
            />

            {/* Drawer */}
            <Drawer
                open={openSettingLayout}
                onClose={() => setOpenSettingLayout(false)}
                placement="right"
                width={350}
                closable={false}
                className={`${theme === 'dark' && 'dark'} dark:bg-[#121212] dark:text-gray-200`}
            >
                <div className="flex flex-col space-y-5">
                    {/* Header */}
                    <div className="flex items-center justify-between">
                        <Title level={4} className="m-0 dark:text-gray-100">
                            Settings
                        </Title>
                        <Avatar
                            icon={<SettingOutlined />}
                            size={40}
                            className="cursor-pointer bg-gray-300 transition-transform duration-300 hover:rotate-180 dark:bg-gray-800 dark:text-gray-300"
                            onClick={() => setOpenSettingLayout(false)}
                        />
                    </div>

                    <Divider className="dark:bg-gray-700" />

                    {/* Language Selector */}
                    <div className="flex items-center justify-between rounded-lg bg-gray-100 p-3 shadow-lg dark:border dark:border-gray-700 dark:bg-gray-800">
                        <div>
                            <Title level={5} className="m-0 dark:text-gray-100">
                                Language
                            </Title>
                            <Text className="dark:text-gray-400">Select your preferred language</Text>
                        </div>
                        <Select
                            value={language}
                            onChange={setLanguage}
                            style={{ width: 120 }}
                            suffixIcon={<GlobalOutlined className="dark:text-gray-300" />}
                            className="transition-all duration-300 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 hover:dark:bg-gray-700"
                        >
                            <Option value="en">English</Option>
                            <Option value="vi">Tiếng Việt</Option>
                        </Select>
                    </div>

                    {/* Dark Mode Toggle */}
                    <div className="flex items-center justify-between rounded-lg bg-gray-100 p-3 shadow-lg dark:border dark:border-gray-700 dark:bg-gray-900">
                        <div>
                            <Title level={5} className="m-0 dark:text-gray-100">
                                Dark Mode
                            </Title>
                            <Text className="dark:text-gray-400">Switch theme to dark mode</Text>
                        </div>
                        <Switch
                            defaultChecked={theme === 'dark' ? true : false}
                            checkedChildren={<MoonOutlined />}
                            unCheckedChildren={<SunOutlined />}
                            onChange={toggleTheme}
                            className="scale-125 border-none bg-gray-300 outline-none transition-all duration-300 dark:bg-gray-700 hover:dark:bg-gray-600"
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default SettingLayout;
