import { Input } from 'antd';
import { useDispatch } from 'react-redux';

import { createFeedback } from '~/api/feedbackApi';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { CloseIcon } from '~/components/Icons';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import UploadImage from '~/components/Upload/UploadImage';

const { TextArea } = Input;

function AddFeedback(props) {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();

    const [feedbackContent, setFeedbackContent] = useState({ name: '', description: '', content: '', avatar: '' });

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [feedbackContent]);

    function validate() {
        if (feedbackContent.avatar === '') {
            dispatch(setStatusMessage('Create feedback fail'));
            dispatch(setErrMessage('Please insert image of user'));
            return false;
        }
        if (feedbackContent.name === '') {
            dispatch(setStatusMessage('Create feedback fail'));
            dispatch(setErrMessage('Please enter name'));
            return false;
        }

        if (feedbackContent.description === '') {
            dispatch(setStatusMessage('Create feedback fail'));
            dispatch(setErrMessage('Please enter description'));
            return false;
        }

        if (feedbackContent.content === '') {
            dispatch(setStatusMessage('Create feedback fail'));
            dispatch(setErrMessage('Please enter content of feedback'));
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                dispatch(startLoading());
                const formData = new FormData();
                formData.append('avatar', feedbackContent.avatar);
                formData.append('name', feedbackContent.name);
                formData.append('description', feedbackContent.description);
                formData.append('content', feedbackContent.content);

                const response = await createFeedback(axiosPrivate, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (response?.violations === 401) {
                    dispatch(setErrMessage('Unauthorized'));
                    dispatch(setStatusMessage('Create feedback fail'));
                } else if (response?.status === 'fail') {
                    dispatch(setStatusMessage('Create feedback fail'));
                    dispatch(setErrMessage(response?.message));
                } else {
                    const newData = {
                        key: response.responseData.feedbackId,
                        number: 1,
                        name: response.responseData.name,
                        description: response.responseData.description,
                        content: response.responseData.content,
                        avatar: response.responseData.avatar,
                        enable: response.responseData.enable,
                        create_at: response.responseData.createAt,
                    };

                    const updatedDataSource = [newData, ...props.dataSource].map((item, index) => ({
                        ...item,
                        number: index + 1,
                    }));

                    props.setState((prevState) => ({
                        ...prevState,
                        dataSource: updatedDataSource,
                    }));

                    dispatch(setStatusMessage('Successful'));
                    dispatch(setErrMessage(response.message));
                    props.setOpenAdd(false);
                }
            } catch (err) {
                dispatch(setStatusMessage('Create feedback fail'));
                dispatch(setErrMessage('Create feedback fail'));
            } finally {
                dispatch(stopLoading());
            }
        }
    };

    return (
        <div className="fixed z-[100] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center font-BeVietNamRegular">
            <div className="w-1/2 max-h-[80%] flex flex-col bg-white pb-8 rounded-xl">
                <header className="flex flex-row justify-between items-center bg-hotlink-blue-0A426E pt-8 pb-4 px-8 rounded-t-xl">
                    <span className="text-xl font-beVietnamPro text-hotlink-white-f5f5f5 after:max-w-full after:w-1/2 after:h-[3px] after:block after:bg-hotlink-red-ff4d4f">
                        Thêm phản hồi mới
                    </span>
                    <button onClick={() => props.setOpenAdd(false)}>
                        <CloseIcon width="30" height="32" className="cursor-pointer" fill="#ffffff" />
                    </button>
                </header>

                <form className="flex flex-col gap-6 px-8 overflow-y-auto pt-4" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-col gap-4 border border-hotlink-white-d9d9d9 border-solid rounded-lg p-4">
                        <div className="flex flex-row items-center justify-start gap-4">
                            <span className="font-BeVietNamRegular text-base font-bold">Ảnh đại diện: </span>
                            <UploadImage
                                setContent={(value) =>
                                    setFeedbackContent((prevContentfeedback) => ({
                                        ...prevContentfeedback,
                                        avatar: value,
                                    }))
                                }
                            />
                        </div>
                        <div className="flex flex-col items-start justify-start gap-4">
                            <span className="font-BeVietNamRegular text-lg font-bold">Tên người đăng</span>

                            <Input
                                className="h-10 font-BeVietNamRegular text-base"
                                placeholder="Nhập tiêu đề"
                                onChange={(e) => {
                                    setFeedbackContent((prevState) => ({
                                        ...prevState,
                                        name: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <div className="flex flex-col items-start justify-start gap-4">
                            <span className="font-BeVietNamRegular text-lg font-bold">Mô tả người đăng</span>

                            <Input
                                className="h-10 font-BeVietNamRegular text-base"
                                placeholder="Nhập mô tả"
                                onChange={(e) => {
                                    setFeedbackContent((prevState) => ({
                                        ...prevState,
                                        description: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <div className="flex flex-col items-start justify-start gap-4">
                            <span className="font-BeVietNamRegular text-lg font-bold">Nội dung</span>

                            <TextArea
                                rows={4}
                                className="h-10 font-BeVietNamRegular text-base"
                                placeholder="Nhập nội dung"
                                onChange={(e) => {
                                    setFeedbackContent((prevState) => ({
                                        ...prevState,
                                        content: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end items-center">
                        <button
                            className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                            type="submit"
                        >
                            <span className="text-base font-BeVietNamRegular font-bold leading-normal text-center text-white">
                                Thêm phản hồi mới
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddFeedback;
