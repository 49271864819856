//Core
import React, { useState, useEffect } from 'react';
import { message } from 'antd';

// App
import { CloseIcon } from '~/components/Icons';
import { deleteTopic } from '~/api/topicApi';
// Internal
import { UploadExam, UploadImageBackground, UploadPartAudio, UploadPartImage, UploadStructure } from './components';
import ProgressBar from './components/ProgressBar';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';

// Component
const AddExamModal = ({ closeModal }) => {
    // States
    const [fileBlob, setFileBlob] = useState(null); // Lưu file đã upload
    const [uploadedFileName, setUploadedFileName] = useState(''); // Lưu tên file

    const [imageUrl, setImageUrl] = useState('');
    const [topicId, setTopicId] = useState('');
    const [currentStep, setcurrentStep] = useState(1);
    const [UploadComplete, setUploadComplete] = useState();
    const [isClosing, setIsClosing] = useState(false);
    const [partImages, setPartImages] = useState([]);
    const [partAudios, setPartAudios] = useState([]);
    const [examData, setExamData] = useState({});


    const axiosPrivate = useAxiosPrivate();
    // Methods
    const handleImageUpload = (url) => {
        if (isClosing) return;
        setImageUrl(url);
    };

    const handleExcelUpload = (id) => {
        if (isClosing) return;
        setTopicId(id);
        console.log('Topic ID:', id);
    };

    const handleComplete = (CP) => {
        if (isClosing) return;
        setUploadComplete(CP);
        if (CP === 1) {
            message.success('Đề thi đã được tải lên thành công!');
            closeModal();
        } else {
            message.error('Đã xảy ra lỗi khi tải lên, vui lòng thử lại!');
        }
    };

    const resetData = () => {
        setImageUrl('');
        setTopicId('');
        setcurrentStep(1);
        setUploadComplete(undefined);
    };

    const handleCloseModal = async () => {
        setIsClosing(true);
        try {
            if (topicId) {
                await deleteTopic(axiosPrivate, topicId);
            }
        } catch (error) {
            console.error('Error deleting topic:', error);
        } finally {
            resetData();
            closeModal();
        }
    };


    const nextStep = () => {
        if (isClosing) return;

        if (currentStep === 1 && !imageUrl) {
            message.warning('Vui lòng upload ảnh đại diện trước khi chuyển sang bước tiếp theo!');
            return;
        }

        if (currentStep === 2 && !topicId) {
            message.warning('Vui lòng upload cấu trúc đề thi trước khi chuyển sang bước tiếp theo!');
            return;
        }

        if (currentStep === 4) {
        const incompleteAudios = partAudios.filter(
            (audio) => !audio.uploaded || !audio.code
        );
        if (incompleteAudios.length > 0) {
            message.warning('Vui lòng upload đầy đủ audio và nhập mã code trước khi tiếp tục!');
            return;
        }
    }

        if (currentStep === 5) {
            handleComplete(1);
            return;
        }

        if (currentStep < 5) {
            setcurrentStep(currentStep + 1);
        }
    };
    const prevStep = () => {
        if (isClosing || currentStep === 1) return;
        setcurrentStep(currentStep - 1);
    };
    const renderStepContent = () => {


        switch (currentStep) {
            case 1:
                return <UploadImageBackground
                    onImageUpload={handleImageUpload}
                    initialImageUrl={imageUrl}
                />;
            case 2:
                return (
                    <UploadStructure
                        imageUrl={imageUrl}
                        topicId={topicId}
                        fileBlob={fileBlob}
                        uploadedFileName={uploadedFileName}
                        setFileBlob={setFileBlob}
                        setUploadedFileName={setUploadedFileName}
                        onTopicIdUpdate={(updatedTopicId) => setTopicId(updatedTopicId)}
                    />

                );

            case 3:
                return (
                    <UploadPartImage
                        topicId={topicId}
                        initialPartImages={partImages}
                        onUpdatePartImages={setPartImages}
                    />
                );


            case 4:
                return (
                    <UploadPartAudio
                        topicId={topicId}
                        initialPartAudios={partAudios}
                        onUpdatePartAudios={setPartAudios}
                    />
                );
            case 5:
                return (
                    <UploadExam
                        topicId={topicId}
                        initialExamData={examData}
                        onUpdateExamData={setExamData}
                    />
                );

            default:
                return null;
        }
    };

    // Template
    return (
        <div className="fixed z-[101] inset-0 w-full h-full bg-gray-600 bg-opacity-75 flex justify-center items-center">
            <div className="w-full max-w-[90%] sm:max-w-[80%] md:max-w-[60%] lg:max-w-3xl h-auto max-h-[90vh] flex flex-col bg-white pb-4 rounded-xl overflow-hidden">
                {/* Header */}
                <header className="flex justify-between items-center bg-hotlink-blue-309 p-4 sm:p-6 rounded-t-xl">
                    <span className="text-lg sm:text-xl text-white">Thêm đề thi</span>
                    <button onClick={handleCloseModal} className="text-white">
                        <CloseIcon width="24" height="24" fill="#ffffff" />
                    </button>
                </header>

                {/* Progress bar */}
                <ProgressBar currentStep={currentStep} />

                {/* Step Content */}
                <div className="flex-grow p-4 overflow-y-auto">{renderStepContent()}</div>

                {/* Navigation Buttons */}
                <div className="flex justify-between mt-4 px-4 sm:px-6">
                    {currentStep > 1 && (
                        <button
                            onClick={prevStep}
                            className="bg-gray-300 text-black px-4 py-2 rounded-md sm:px-6"
                        >
                            Quay lại
                        </button>
                    )}

                    <button
                        onClick={nextStep}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md sm:px-6 ml-auto"
                    >
                        {currentStep === 5 ? 'Tạo đề thi' : 'Tiếp theo'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddExamModal;
