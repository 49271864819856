import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';

import { listFlashCardUser } from '~/api/flashCardApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import FlashCardItem from './FlashCardItem';
import AddFlashCard from './AddFlashCard';

const FlashCardList = () => {
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const isMobile = window.innerWidth <= 767;
    let itemsPerPage;

    if (isMobile) {
        itemsPerPage = 6;
    } else {
        itemsPerPage = 9;
    }

    const [listFlashCard, setListFlashCard] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const totalItems = listFlashCard.length;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentFlashCards = listFlashCard.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getFlashCardToUser = async () => {
        try {
            const response = await listFlashCardUser(axiosPrivate);
            if (response.status === 'OK') {
                setListFlashCard(response.responseData);
            }
        } catch (err) { }
    };

    useEffect(() => {
        getFlashCardToUser();

        return () => {
            controller.abort();
        };
    }, []);

    const addFlashCardList = (newFlashCard) => {
        setListFlashCard([newFlashCard, ...listFlashCard]);
    };

    const removeFlashCard = (flashCardId) => {
        const updatedList = listFlashCard.filter((item) => item.flashCardId !== flashCardId);
        setListFlashCard(updatedList);
    };

    const updateFlashCard = (updatedFlashCard) => {
        const updatedList = listFlashCard.map((item) =>
            item.flashCardId === updatedFlashCard.flashCardId ? updatedFlashCard : item,
        );
        setListFlashCard(updatedList);
    };
    return (
        <div className="w-full flex flex-col justify-start gap-8 py-10 px-6 rounded-xl bg-white">
            {openAdd ? <AddFlashCard closeAdd={() => setOpenAdd(false)} addFlashCardList={addFlashCardList} /> : null}
            <div className="flex lg:flex-row flex-col gap-2 justify-between">
                <div className="flex flex-col gap-2 font-BeVietnamRegular text-sm text-justify text-black">
                    <span className="font-BeVietNambold text-base">Học từ vựng qua FlashCard</span>
                    <span>
                        Chương trình bao gồm 50 chủ đề từ. Mỗi từ vựng có phiên âm, hình ảnh, âm thanh, dịch, câu ví dụ
                        và có thu âm so sánh, chấm điểm.
                    </span>
                </div>

                <button
                    className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                    onClick={() => {
                        setOpenAdd(true);
                    }}
                >
                    <span className="text-base font-Bold leading-normal text-center font-medium text-white">
                        Thêm FlashCard
                    </span>
                </button>
            </div>

            <div className="flex flex-col gap-7">
                <span className="font-BeVietnamRegular font-BeVietNambold text-base text-justify text-black lg:px-[50px] px-4">
                    FlashCard của tôi
                </span>

                <div className="flex flex-row gap-10 flex-wrap lg:px-[126px] px-2 font-BeVietNamRegular">
                    {currentFlashCards
                        ? currentFlashCards.map((item) => (
                            <FlashCardItem
                                key={item.flashCardId}
                                flashCard={item}
                                removeFlashCard={removeFlashCard}
                                updateFlashCard={updateFlashCard}
                            />
                        ))
                        : null}
                </div>

                <Pagination
                    className="format-table flex justify-center font-BeVietNamRegular"
                    showTotal={(total) => `${total} chủ đề`}
                    pageSizeOptions={['6', '9', '12']}
                    locale={{ items_per_page: '/ trang' }}
                    total={totalItems}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={itemsPerPage}
                    onChange={handlePageChange}
                />
            </div>

            {/* <div className="flex flex-col gap-7">
                <span className="font-poppinsRegular font-semibold text-base text-justify text-black px-[50px]">
                    FlashCard của hệ thống
                </span>

                <div className="flex flex-row gap-10 flex-wrap px-[126px]">
                    {listFlashCard
                        ? listFlashCard.map((item) => <FlashCardItem key={item.title} flashCard={item} />)
                        : null}
                </div>
                <Pagination
                    className="format-table flex justify-center"
                    showTotal={(total) => `${total} chủ đề`}
                    pageSizeOptions={['5', '10', '20']}
                    locale={{ items_per_page: '/ trang' }}
                    total={500}
                    defaultCurrent={1}
                />
            </div> */}
        </div>
    );
};

export default FlashCardList;
