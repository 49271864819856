// Core
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// App
import Search from '~/components/Dictionary/Search';
import DictionaryList from '../../components/Dictionary/DictionaryList/DictionaryList';

// Constant
const defaultKeyword = "beach";

function Dictionary() {
    // Hooks
    const location = useLocation();

    // Functions
    const searchWord = useMemo(() => {
        const currentWord = new URLSearchParams(location.search).get('search');
        return currentWord === null || currentWord === "" ? defaultKeyword : currentWord;
    }, [location.search]);

    return (
        <div className="flex mt-[-30px] items-center flex-col gap-6 bg-[#f7f3ff]">
            <div className="lg:w-[58%] w-[90%] flex rounded-sm flex-col justify-start items-center gap-6 mt-[86px]">
                <Search defaultKeyword={searchWord} />
                <DictionaryList searchWord={searchWord} />
            </div>
        </div>
    );
}

export default Dictionary;
