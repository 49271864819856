import { PlusIcon } from '~/components/Icons';

const AddExam = (props) => {
    return (
        <div className="w-full flex flex-row gap-4 justify-start items-end h-10 font-BeVietNamRegular text-sm text-white leading-[1.57]">
            <button className="h-full flex justify-center items-center py-1 px-[15px] rounded-sm text-white shadow-[0_2px_0px_0px_rgba(0,0,0,0.4)] border-solid border-[1px] border-hotlink-green-198038 bg-hotlink-green-198038">
                <span className="text-white">Quản lý khóa học</span>
            </button>
            <button
                className="h-full flex flex-row justify-center items-center gap-2 py-1 px-[15px] rounded-sm text-white shadow-[0_2px_0px_0px_rgba(0,0,0,0.4)] border-solid border-[1px] border-hotlink-green-198038 bg-hotlink-green-198038"
                onClick={() => {
                    props.openModal();
                }}
            >
                <PlusIcon />
                <span className="text-white">Thêm đề thi</span>
            </button>
        </div>
    );
};

export default AddExam;
