import { AutoComplete } from 'antd';
import { SearchDictionaryIcon } from '~/components/Icons';
import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

import './Search.scss';
import axios from '~/api/axios';

const SEARCH_URL = '/dictionary/suggest';

const Search = (props) => {
    const [options, setOptions] = useState([]);
    const [nowValue, setNowValue] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const navigate = useNavigate();

    const handleSearch = (value) => {
        if (!value) {
            setOptions([]);
            return;
        }
        setNowValue(value);
        axios
            .get(SEARCH_URL + `/${value}`)
            .then((response) => {
                setOptions(response.data.responseData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    const onSelect = (data) => {
        setSelectedValue(data);
        navigate(`/dictionary?search=${data}`);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setSelectedValue(e.target.value);
            navigate(`/dictionary?search=${e.target.value}`);
        }
    };

    const handleClick = () => {
        navigate(`/dictionary?search=${nowValue}`);
    };

    const customRenderOption = (option, value) => {
        const searchValue = option.key;

        const startIndex = searchValue.indexOf(value);
        const beforeStr = searchValue.substr(0, startIndex);
        const afterStr = searchValue.substr(startIndex + value.length);

        return (
            <span>
                {beforeStr}
                <strong>{value}</strong>
                {afterStr}
            </span>
        );
    };

    return (
        <div className="search-container flex flex-row items-center w-full mx-[0.7%] lg:h-24 h-16 bg-white rounded-xl shadow-[0_8px_40px_0px_rgba(0,0,0,0.1)]">
            <button
                className="h-full flex items-center justify-center bg-hotlink-white-fafafa w-[5.825%] rounded-xl"
                onClick={handleClick}
            >
                <SearchDictionaryIcon className="cursor-pointer" />
            </button>
            <AutoComplete
                className="w-full h-full"
                options={options.map((option) => ({
                    value: option,
                    label: customRenderOption({ key: option }, nowValue),
                }))}
                onSearch={debouncedChangeHandler}
                onSelect={onSelect}
                placeholder={props.defaultKeyword}
                onKeyDown={handleKeyDown}
            ></AutoComplete>
        </div>
    );
};

export default Search;
