function HotNewsItem({ hotNewPicture, title, day, description }) {
    const truncateDescription = (text, maxLength = 100) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };
    return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col h-full">
            <div className="h-48 overflow-hidden">
                <img
                    src={hotNewPicture}
                    alt="hot-news"
                    className="w-full h-full object-cover"
                />
            </div>

            <div className="p-4 flex flex-col flex-grow">
                <h3 className="text-lg font-semibold text-gray-900 mb-2 line-clamp-2 h-12">
                    {title}
                </h3>
                <p className="text-gray-600 text-sm mb-4 line-clamp-3 flex-grow">
                {truncateDescription(description, 20)}
                </p>
            </div>
            
            <div className="p-4 pt-0 text-right text-sm text-gray-400">
                <span>{day}</span>
            </div>
        </div>
    );
}

export default HotNewsItem;