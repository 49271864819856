// Core
import { Link } from 'react-router-dom';

// App
import images from '~/assets/images';

// Internal
import ButtonCustom from '../../../components/Button/ButtonCustom/ButtonCustom';

// Component
const NotFoundPage = () => {

    // Template
    return (
    <div className='w-screen h-screen flex justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-4'>
            <div style={{width: "400px", height: "300px"}}>
                <img className='w-full h-full' src={images.image404} alt='404_error' />
            </div>
            <Link to={'/'}>
                <ButtonCustom content={"Go Back Home"} />
            </Link>
        </div>
    </div>
    )
}

export default NotFoundPage;