// Core
import React, { useState, useEffect, useMemo } from 'react';

// App
import { SoundIcon } from '~/components/Icons';
import { getDictionaryImageList } from '~/api/dictionaryApi';

// Internal
import { HeartFillIcon, HeartIcon } from '../../../../Icons/Icons';
import { DefinitionList, RelatedWordList } from './components';

// Constant
const playSound = (sound) => {
    const audioElement = document.createElement('audio');
    audioElement.src = sound;
    audioElement.play();
};

// Component
const DictionaryItem = ({ dictionaryItem }) => {
    // States
    const [meanImage, setMeanImage] = useState([]);
    const [isLiked, setIsLiked] = useState(false);

    const firstMeaning = dictionaryItem.meanings[0];

    const meaningList = useMemo(() => (dictionaryItem?.meanings ? dictionaryItem.meanings : []), [dictionaryItem]);
    const phoneticList = useMemo(() => (dictionaryItem?.phonetics ? dictionaryItem.phonetics : []), [dictionaryItem]);

    // Effects
    useEffect(() => {
        const handleDictionaryResponse = async () => {
            try {
                if (dictionaryItem?.word) {
                    const responseImage = await getDictionaryImageList(dictionaryItem.word);
                    responseImage?.full !== null ? setMeanImage(responseImage) : setMeanImage(null);
                } else {
                    setMeanImage(null);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        handleDictionaryResponse();
    }, [dictionaryItem]);

    const listSound = useMemo(
        () =>
            phoneticList.map((element, index) => (
                <div key={index} className="flex flex-row items-center gap-2">
                    <button onClick={() => playSound(element.audio)}>
                        <SoundIcon />
                    </button>

                    <div className="text-xs text-hotlink-gray-5b5b5b">{element.text}</div>
                </div>
            )),
        [phoneticList],
    );

    const dataMeanings = meaningList || [];

    return (
        <div className="flex flex-col justify-center items-start w-full lg:mb-16 mb-8 gap-4 bg-white shadow-shadow-meanings rounded-xl lg:p-8 py-8 px-4 pb-12">
            <div className="flex flex-col justify-start items-start gap-2">
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-lg font-medium  text-hotlink-black-085 first-letter:uppercase">
                        {dictionaryItem.word}
                    </h1>
                    {isLiked ? (
                        <HeartFillIcon
                            className="text-hotlink-yellow-f6ae2d cursor-pointer"
                            onClick={() => setIsLiked(false)}
                        />
                    ) : (
                        <HeartIcon
                            className="text-gray-100 cursor-pointer hover:text-yellow-400"
                            onClick={() => setIsLiked(true)}
                        />
                    )}
                </div>
                <p className="text-sm my-2">{firstMeaning.partOfSpeech}[C]</p>
                <div className="flex flex-row justify-start items-center gap-10">{listSound}</div>
                <RelatedWordList antonymList={firstMeaning.antonyms} synonymList={firstMeaning.synonyms} />
                <div className="flex lg:flex-row flex-col gap-4 rounded-2xl lg:p-6 lg:pl-2 py-6 px-4">
                    {meanImage && (
                        <div className="w-1/3">
                            <img
                                alt="test"
                                style={{ maxHeight: '200px' }}
                                className="w-full h-full rounded-md"
                                src={meanImage.small}
                            />
                        </div>
                    )}
                    <div className="w-2/3 flex flex-col justify-center gap-2 lg:px-4">
                        <DefinitionList definitionList={firstMeaning.definitions} />
                    </div>
                </div>
                <div className="space-y-8">
                    {dataMeanings.slice(1).map((meaningItem, index) => (
                        <div
                            key={index}
                            className="text-base font-medium text-hotlink-black-3d3d3d first-letter:uppercase"
                        >
                            <RelatedWordList
                                partOfSpeech={meaningItem.partOfSpeech}
                                antonymList={meaningItem.antonyms}
                                synonymList={meaningItem.synonyms}
                            />
                            <DefinitionList definitionList={meaningItem.definitions} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DictionaryItem;
