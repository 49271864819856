import './ToeicExam.scss';
import { useTranslation } from 'react-i18next';
import Next from '~/assets/svg/exam-next.svg';
import Prev from '~/assets/svg/exam-prev.svg';
import ToeicPart from '../ToeicPart/ToeicPart';
import { useLocation } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { useSelector, useDispatch } from 'react-redux';
import { setListPart, setCurrentPart, setCurrentQuestion, setTotalQuestion, setListQuestion } from '~/redux/topicSlice';
import { goToNextQuestion } from '~/redux/topicSlice';

function ToeicExam(props) {
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const dispatch = useDispatch();
    const listPart = useSelector((state) => state.topic.listPart);
    const startPart = useSelector((state) => state.topic.startPart);
    const currentPart = useSelector((state) => state.topic.currentPart);
    const listQuestion = useSelector((state) => state.topic.listQuestion);
    const currentQuestion = useSelector((state) => state.topic.currentQuestion);
    const totalQuestion = useSelector((state) => state.topic.totalQuestion);

    const [isLoading, setIsLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [showExam, setShowExam] = useState(false);

    const { t } = useTranslation('common');

    const topicId = new URLSearchParams(location.search).get('id');


    const initializeExam = async () => {
        try {
            setIsLoading(true);
            const partsResponse = await axiosPrivate.get(`/topic/${topicId}/listPart`, {
                signal: controller.signal,
            });

            if (partsResponse.data.status === 'OK') {
                const parts = partsResponse.data.responseData;
                dispatch(setListPart(parts));
                dispatch(setCurrentPart(parts[0].partId));

                const total = parts.reduce((acc, part) => acc + part.totalQuestion, 0);
                dispatch(setTotalQuestion(total));

                const allQuestions = [];
                let numberical = 0;

                for (const part of parts) {
                    const questionsResponse = await axiosPrivate.get(`/topic/${topicId}/listQuestionToPart`, {
                        params: { partId: part.partId },
                        signal: controller.signal,
                    });

                    if (questionsResponse.data.status === 'OK') {
                        questionsResponse.data.responseData.forEach(questionData => {
                            numberical++;
                            const processedQuestion = {
                                ...questionData,
                                numberical: numberical
                            };

                            if (questionData.questionGroup) {
                                numberical += questionData.questionGroup.length - 1;
                            }

                            allQuestions.push(processedQuestion);
                        });
                    }
                }

                dispatch(setListQuestion(allQuestions));
                if (allQuestions.length > 0) {
                    dispatch(setCurrentQuestion(allQuestions[0].questionId));
                }
                setIsInitialized(true);
            }
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const getFirstQuestionIdByPart = (partId) => {
        const firstQuestionInPart = listQuestion.find(item => item.partId === partId);
        if (firstQuestionInPart) {
            dispatch(setCurrentQuestion(firstQuestionInPart.questionId));
        }
        dispatch(setCurrentPart(partId));
    };

    const showQuestionCurrentIndex = () => {
        const question = listQuestion.find(item => item.questionId === currentQuestion);
        return question ? question.numberical : null;
    };

    const checkQuestion = () => {
        const question = listQuestion.find(item => item.questionId === currentQuestion);
        if (!question) return null;

        if (question.numberical === 1) return 1;
        if (question.questionGroup) {
            if (question.numberical + question.questionGroup.length - 1 === totalQuestion) {
                return totalQuestion;
            }
        } else if (question.numberical === totalQuestion) {
            return totalQuestion;
        }
        return null;
    };

    const getNextQuestion = () => {
        const currentIndex = listQuestion.findIndex(item => item.questionId === currentQuestion);
        if (currentIndex + 1 < listQuestion.length) {
            const nextQuestion = listQuestion[currentIndex + 1];
            dispatch(setCurrentQuestion(nextQuestion.questionId));
            if (nextQuestion.partId !== currentPart) {
                dispatch(setCurrentPart(nextQuestion.partId));
            }
        }
    };

    const getPrevQuestion = () => {
        const currentIndex = listQuestion.findIndex(item => item.questionId === currentQuestion);
        if (currentIndex > 0) {
            const prevQuestion = listQuestion[currentIndex - 1];
            dispatch(setCurrentQuestion(prevQuestion.questionId));
            if (prevQuestion.partId !== currentPart) {
                dispatch(setCurrentPart(prevQuestion.partId));
            }
        }
    };

    useEffect(() => {
        if (isInitialized && !isLoading) {
            const timer = setTimeout(() => {
                setShowExam(true);
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isInitialized, isLoading]);

    useEffect(() => {
        initializeExam();
        return () => {
            controller.abort();
        };
    }, []);

    if (isLoading || !isInitialized || !showExam) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mx-auto mb-4"></div>
                </div>
            </div>
        );
    }

    const getCurrentPartNumber = () => {
        const currentPartObj = listPart.find(part => part.partId === currentPart);
        if (!currentPartObj) return null;

        return parseInt(currentPartObj.partName.split(':')[0].replace('PART', '').trim());
    };

    // const getNextQuestion = () => {
    //     const partNumber = getCurrentPartNumber();
    //     if (partNumber && partNumber >= 1 && partNumber <= 5) return;

    //     dispatch(goToNextQuestion());
    // };

    // const getPrevQuestion = () => {
    //     const partNumber = getCurrentPartNumber();
    //     if (!partNumber || partNumber === 5 || (partNumber >= 1 && partNumber <= 4)) return;

    //     const prevQuestionIndex = listQuestion.findIndex((item) => item.questionId === currentQuestion) - 1;
    //     if (prevQuestionIndex >= 0) {
    //         dispatch(setCurrentQuestion(listQuestion[prevQuestionIndex].questionId));
    //         dispatch(setCurrentPart(listQuestion[prevQuestionIndex].partId));
    //     }
    // };


    return (
        <div className={`w-full h-full flex text-center flex-col pt-10 pb-20 bg-[#f7f3ff]`}>
            <h1 className="lg:text-[40px] text-3xl font-BeVietnamSemiBold text-[#309]">{props.infor?.topicName}</h1>
                <span className="text-base mb-10">
                    {t('time')}: {props.infor?.workTime} phút
                </span>
            <div className="flex justify-between lg:px-20 px-2">
                <div className="rounded-lg flex px-2 py-1 bg w-auto h-auto cursor-pointer">
                    {listPart
                        ? listPart.map((part, index) => (
                            <p
                                key={index}
                                className={`lg:px-4 px-3 lg:py-3.5 py-1 lg:text-base text-sm font-BeVietnamMedium text-hotlink-black-262626 italic lowercase first-letter:uppercase ${currentPart === part.partId ? 'active' : ''}
        ${currentPart !== part.partId && !listQuestion.some((item) => item.partId === part.partId) ? 'pointer-events-none opacity-50' : ''}`}
                                onClick={() => getFirstQuestionIdByPart(part.partId)}
                            >
                                {part.partName.slice(0, 6)}
                            </p>

                        ))
                        : ''}
                </div>

                <div className="hidden px-6 lg:flex items-center text-center bg-[#f6ae2d]">
                    <span className="text-base">
                        {showQuestionCurrentIndex()}/{totalQuestion}
                    </span>
                </div>
            </div>
            {listPart.find((item) => item.partId === currentPart) ? (
                <ToeicPart />
            ) : (
                ''
            )}

            <div className="lg:px-20 px-10 mt-4 flex justify-end">
                {/* {(checkQuestion() === 1 || !startPart.includes(currentPart) || getCurrentPartNumber() === 5) ? (
                    <button className="bg-[#fff] p-4 mr-2 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 mr-2 rotate-180 cursor-pointer">
                        <img src={Next} alt="" onClick={getPrevQuestion} />
                    </button>
                )}

                {(checkQuestion() === totalQuestion || !startPart.includes(currentPart)) ? (
                    <button className="bg-[#fff] p-4 mr rotate-180 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 cursor-pointer">
                        <img src={Next} alt="" onClick={getNextQuestion} />
                    </button>
                )} */}

                {checkQuestion() === 1 || !startPart.includes(currentPart) ? (
                    <button className="bg-[#fff] p-4 mr-2 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 mr-2 rotate-180 cursor-pointer">
                        <img src={Next} alt="" onClick={getPrevQuestion} />
                    </button>
                )}

                {checkQuestion() === totalQuestion || !startPart.includes(currentPart) ? (
                    <button className="bg-[#fff] p-4 mr rotate-180 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 cursor-pointer">
                        <img src={Next} alt="" onClick={getNextQuestion} />
                    </button>
                )}

            </div>

        </div>
    );
}

export default ToeicExam;
