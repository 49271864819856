import Avatar from '~/components/Avatar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function InformationUser() {
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    return user && user.User ? (
        <button
            className="w-full border-[16px] p-4 rounded-xl border-white flex flex-col gap-3 font-medium text-xs shadow-[4px_4px_30px_0_rgba(0,0,0,.15)] bg-hotlink-gray-f6f6f6"
            onClick={() => navigate('/profile')}
        >
            <div className="flex flex-row gap-2 items-center">
                <Avatar
                    className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba h-12 w-12"
                    src={user.User.avatar ? user.User.avatar : ''}
                    alt="user"
                />
                <div className="flex flex-col gap-2 justify-center items-start">
                    <span className="text-base font-bold">{user.User.name}</span>
                    <span className="text-hotlink-gray-5b5b5b">{user.User.email}</span>
                </div>
            </div>
        </button>
    ) : null;
}
export default InformationUser;
