import Banner from '~/components/News/Banner';
import HotNews from '~/components/News/HotNews';
import Competitions from '~/components/News/Competitions';
import Event from '~/components/TestPage/News';
import Contact from '~/components/Home/Contact';
import LatestNewsPage from './LatestNews/LatestNewsPage';

function News() {
    return (
        <div className="">
            <Banner />
            <HotNews />
            <LatestNewsPage/>
            <Competitions />
            <Event title="events_collaborations" />
            <Contact page="news"/>
        </div>
    );
}

export default News;
