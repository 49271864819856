import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAppConfigStore = create()(
    persist(
        (set, get) => ({
            theme: 'light',
            language: 'en',
            toggleTheme: () => set({ theme: get().theme === 'light' ? 'dark' : 'light' }),
            setLanguage: (lang) => set({ language: lang }),
        }),
        {
            name: 'app-config-storage',
        },
    ),
);

export default useAppConfigStore;
