import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '~/routes';
import { DefaultLayout, AdminLayout } from '~/layouts';
import { withTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import NotFoundPage from './pages/Error/NotFoundPage/NotFoundPage';

function App() {
    const hasAccessToken = localStorage.getItem('accessToken');

    const allRoutes = [...publicRoutes, ...(hasAccessToken ? privateRoutes : [])];

    return (
        <Router>
            <div className="App">
                <Routes>
                    {allRoutes.map((route, index) => {
                        const Page = route.component;
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }

                        if (route.private && !hasAccessToken) {
                            return <Route key={index} path={route.path} element={<Navigate to="/login" />} />;
                        } else {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        }
                    })}
                    <Route path="*" element={<DefaultLayout>
                        <NotFoundPage />
                    </DefaultLayout>} />
                </Routes>
            </div>
        </Router>
    );
}

export default withTranslation('common')(App);
