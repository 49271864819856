import { useNavigate, useLocation } from 'react-router-dom';
import images from '~/assets/images';
import { Button, Popover } from 'antd';

import { BellIcon, LogoutIcon, HomeOutlined } from '~/components/Icons';
import Avatar from '~/components/Avatar';
import Language from '~/components/Language';
import InformationUser from '~/components/ForumLayout/Navigation/InformationUser';

import React, { useEffect, useRef, useState } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { logout } from '~/api/userApi';

import { useDispatch, useSelector } from 'react-redux';
import { clearUser, setUser } from '~/redux/userSlice';

function Header() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = useRef(false);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    const [open, setOpen] = useState(false);
    const role = localStorage.getItem('ROLE');

    const signOut = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            if (accessToken !== null && refreshToken !== null) {
                const response = await logout(
                    {
                        accessToken,
                        refreshToken,
                    },
                    {
                        withCredentials: true,
                    },
                );
                if (response.status === 'success') {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('ROLE');
                    dispatch(clearUser());
                    navigate('/login');
                }
            }
        } catch (err) {}
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken === null) {
                isLoggedIn.current = false;
            } else {
                try {
                    const response = await axiosPrivate.get('/information');
                    isMounted && dispatch(setUser(response?.data?.responseData));
                    if (response?.data?.responseData.Role !== 'ADMIN') {
                        navigate('/login', { state: { from: location }, replace: true });
                    }
                    isLoggedIn.current = true;
                } catch (err) {
                    if (err.response && err.response?.data.violations === '401') {
                        if (window.location.pathname === '/') {
                            isLoggedIn.current = false;
                        } else {
                            navigate('/login', { state: { from: location }, replace: true });
                        }
                    }
                }
            }
        };

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, location, navigate, dispatch]);

    return (
        <header className={`flex flex-row justify-start items-center px-20 py-2 bg-hotlink-blue-309 w-full`}>
            <div className="h-full w-full">
                <div className="h-full flex justify-between items-center ">
                    <div className="h-[72px] w-[72px] flex items-center justify-center">
                        <img className="object-contain" src={images.logo} alt="MeU" />
                    </div>

                    <div className="h-12 flex items-center flex-row gap-3">
                        {isLoggedIn.current ? (
                            <>
                                <button className="bell-icon">
                                    <BellIcon color="#ffffff" />
                                    <span className="bg-hotlink-red-ff4d4f absolute w-8 h-5 flex items-center justify-center px-1 -right-2.5 -top-3.5 rounded-[100px] text-xs font-semibold text-white">
                                        11
                                    </span>
                                </button>

                                <Popover
                                    content={
                                        <div className="flex flex-col gap-6 px-4 py-2">
                                            <InformationUser colortext="text-white" />

                                            <button
                                                className="pl-3 py-3 flex flex-row gap-4 justify-start items-center border-[1px] border-black rounded-md"
                                                onClick={() => {
                                                    signOut();
                                                    setOpen(false);
                                                }}
                                            >
                                                <LogoutIcon />
                                                <span className="font-be-vietnam text-lg">Đăng xuất</span>
                                            </button>
                                            {role === 'ADMIN' ? (
                                                <button
                                                    className="pl-3 py-3 flex flex-row gap-4 justify-start items-center border-[1px] border-black rounded-md"
                                                    onClick={() => {
                                                        navigate('/');
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <HomeOutlined width={'30px'} height={'30px'} />
                                                    <span className="font-be-vietnam text-lg">Home</span>
                                                </button>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    }
                                    trigger="click"
                                    open={open}
                                    onOpenChange={handleOpenChange}
                                >
                                    <button className="avatar-icon">
                                        <Avatar
                                            className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba mr-2 h-7 w-7 -ml-2"
                                            src=""
                                            alt="user"
                                        />
                                        {/* <span className="user-name text-sm text-white opacity-80 font-be-vietnam leading-[1.57]">
                                            {user?.User.userName.split(' ').pop()}
                                        </span> */}
                                    </button>
                                </Popover>
                            </>
                        ) : (
                            <Button type="primary" danger onClick={() => navigate('/login')}>
                                Login
                            </Button>
                        )}

                        <div className="choose-language text-sm text-black opacity-80 font-be-vietnam leading-[1.57] -ml-4">
                            <Language isAdminPage={true} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
