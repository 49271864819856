import { ClockIcon } from '~/components/Icons';
import { useNavigate } from 'react-router-dom';

function FilterExamItem(props) {
    const topic = props.topic;
    const navigate = useNavigate();

    return (
        <div className="lg:w-1/4-18px lg:max-w-1/4-32px flex flex-col justify-start items-start gap-4 p-6 rounded-xl shadow-[4px_4px_14px_0_rgba(0,0,0,0.1)] border-solid border border-hotlink-white-d7cceb bg-white">
            <img src={topic.topicImage} alt="Course Image" className="self-stretch grow"></img>
            <span className="font-BeVietNamRegular text-xl text-hotlink-black-1a1a1a">{topic.topicName}</span>
            <div className="flex flex-col justify-start items-start gap-4 font-BeVietNamRegular text-base">
                <div className="flex flex-row justify-start items-center gap-1">
                    <span className="text-hotlink-black-085">{topic.workTime} phút</span>
                    <ClockIcon />
                </div>
                <div className="flex flex-row justify-start items-start gap-2 text-hotlink-gray-b3b3b3">
                    <span>{topic.topicType.toLowerCase() === 'full' ? '7 phần thi' : '1 phần thi'}</span>
                    <span>|</span>
                    <span>{topic.topicDescription}</span>
                </div>
                <div className="text-hotlink-gray-b3b3b3">{topic.packName}</div>
                <div className="flex justify-center items-center gap-[3px] py-[1px] px-2 rounded-[40px] bg-hotlink-blue-c5d5ff">
                    <span className="font-BeVietNamRegular text-xs text-hotlink-blue-0d47a1 tracking-[0.32px]">
                        #TOEIC
                    </span>
                </div>
            </div>
            <button
                className="w-full flex justify-center items-center py-1 px-[15px] rounded-sm shadow-[0_2px_0_0_rgba(0,0,0,0.02)] border-solid border border-hotlink-blue-309 bg-white"
                onClick={() => navigate('/test/test-guide?id=' + topic.topicId)}
            >
                <span className="font-BeVietNamRegular font-semibold text-sm">Chi tiết</span>
            </button>
        </div>
    );
}
export default FilterExamItem;
