const DefinitionList = ({definitionList}) => {
  return (
    <ul className="space-y-4 list-disc">
        {
          definitionList.map((definitionItem, index) => (
            <li key={index} className="text-[13px] text-hotlink-black-333333 text-base list-[circle]">
                    {definitionItem.definition}
                </li>
          ))
        }
    </ul>
  )
}

export default DefinitionList