import React, { useState } from 'react';
import { SearchDictionaryIcon } from '~/components/Icons';
import { useDispatch } from 'react-redux';
import { setQueryParams } from '~/redux/listExamSlice';

function SearchExam() {
    const [searchValue, setSearchValue] = useState();
    const [typeActive, setTypeActive] = useState('all');
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const newQueryParams = {};
            if (searchValue !== '') {
                newQueryParams.search = searchValue;
            }
            dispatch(setQueryParams(newQueryParams));
        } catch (err) {}
    };

    const choiceType = (type) => {
        setTypeActive(type);

        const newQueryParams = {};
        if (type !== 'all') {
            newQueryParams.type = type;
        }
        dispatch(setQueryParams(newQueryParams));
    };

    return (
        <div className="flex flex-col justify-start items-start gap-4 lg:pt-[72px] pt-14 lg:px-20 px-5 bg-white">
            <div className="flex flex-row justify-start items-center text-sm">
                <span className="text-hotlink-black-045">Trang chủ</span>
                <div className="px-2">
                    <span>/</span>
                </div>
                <span className="text-hotlink-blue-309">Kho đề thi</span>
            </div>

            <span className="lg:text-[40px] text-3xl">Kho đề thi</span>

            <span className="text-xs tracking-[0.32px] text-black leading-[1.33]">
                Kho đề thi chất lượng cao từ hệ thống MeU được cập nhật liên tục
            </span>

            <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
                <div className="flex flex-col justify-center gap-[10px] py-2 w-full">
                    <div className="flex flex-row justify-start items-start gap-4 w-full">
                        <div className="h-12 lg:w-[30.9%] w-full flex flex-row justify-between items-center px-2 rounded-sm border-solid border-[1px] border-hotlink-white-d9d9d9 bg-white">
                            <input
                                className="w-full text-sm px-3 py-1"
                                type="text"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Nhập từ khoá bạn muốn tìm kiếm"
                            />

                            <button>
                                <SearchDictionaryIcon className="cursor-pointer w-[32px] h-[32px]" />
                            </button>
                        </div>
                    </div>

                    <span className="text-base text-black">
                        Được tìm kiếm nhiều:
                        <span className="text-hotlink-blue-4589ff"> #TOEIC #ETS</span>
                    </span>
                </div>

                <button className="w-[165px] h-10 flex justify-center items-center gap-2 py-1 px-[15px] rounded-sm border-solid border-[1px] border-hotlink-blue-309 bg-hotlink-blue-309">
                    <span className="text-sm text-white">Tìm kiếm</span>
                </button>
            </form>

            <div className="w-full flex flex-row justify-start items-start pt-2">
                <div
                    className={`cursor-pointer lg:w-[194px] w-1/3 h-[40px] flex items-center justify-center gap-2 px-4 border-b-2 ${
                        typeActive === 'all'
                            ? 'border-hotlink-blue-7158d7 text-hotlink-black-161616 font-bold'
                            : 'border-hotlink-gray-e0e0e0 text-hotlink-gray-5b5b5b'
                    }`}
                    onClick={() => choiceType('all')}
                >
                    <span className="text-sm text-center">Tất cả</span>
                </div>
                <div
                    className={`cursor-pointer lg:w-[194px] w-1/3 h-[40px] flex items-center justify-center gap-2 px-4 border-b-2 ${
                        typeActive === 'full'
                            ? 'border-hotlink-blue-7158d7 text-hotlink-black-161616 font-bold'
                            : 'border-hotlink-gray-e0e0e0 text-hotlink-gray-5b5b5b'
                    }`}
                    onClick={() => choiceType('full')}
                >
                    <span className="text-sm text-center">Bộ đề thi</span>
                </div>
                <div
                    className={`cursor-pointer lg:w-[194px] w-1/3 h-[40px] flex items-center justify-center gap-2 px-4 border-b-2 ${
                        typeActive === 'part'
                            ? 'border-hotlink-blue-7158d7 text-hotlink-black-161616 font-bold'
                            : 'border-hotlink-gray-e0e0e0 text-hotlink-gray-5b5b5b'
                    }`}
                    onClick={() => choiceType('part')}
                >
                    <span className="text-sm text-center ">Đề thi rút gọn</span>
                </div>
            </div>
        </div>
    );
}

export default SearchExam;
