import images from '~/assets/images';
import './Banner.scss';

function Banner() {
    return (
        <div className="banner-news w-full lg:h-[542px] h-[400px] flex items-center lg:pl-20 pl-12">
            <div className="flex flex-col justify-start items-start gap-6 lg:w-[400px]">
                <span className="font-BeVietNamBold lg:text-[50px] text-5xl text-white">Tin tức</span>
                <span className="font-BeVietNamRegular text-hotlink-white-f3f7ff text-base">
                    "With MeU English, you can improve your English proficiency through comprehensive test preparation
                    materials and achieve great results in your exams."
                </span>
            </div>
        </div>
    );
}

export default Banner;
