import images from '~/assets/images';
import './Contact.scss';
import { useTranslation } from 'react-i18next';

const Contact = (props) => {
    const { t } = useTranslation('common');

    return (
        <div
            className={`contact-page relative lg:h-[658px] h-[950px] lg:mb-[72px] mb-11 ${
                props.page !== 'home' ? 'bg-hotlink-white-f7f3ff' : ''
            }`}
        >
            <div
                className={`bg-hotlink-white-f7f3ff lg:w-[988px] w-full h-full relative`}
                // data-aos="fade-right"
                // data-aos-duration="1000"
            >
                <span className="absolute lg:w-[562px] w-4/5 lg:top-[87px] top-12 left-0 right-0 mx-auto text-[40px] text-3xl leading-normal tracking-[1.2px] text-left text-hotlink-blue-309 first-letter:capitalize">
                    {t('contact_us')}
                </span>

                <span className="absolute lg:w-[401px] w-1/2 top-[160px] lg:left-[296px] left-40 self-stretch lg:text-base text-sm leading-normal tracking-[0.48px] text-justify text-hotlink-black-4d4d4d">
                    "Listen to what our students have to say about MeU English - our e-learning platform designed to
                    improve English vocabulary and prepare for exams. Your feedback is important to us and helps us
                    improve our website to serve you better ."
                </span>
                <img
                    className="absolute lg:bottom-[-5px] bottom-[62%] lg:left-[54px] left-2 object-contain h-1/5 lg:h-2/3"
                    src={images.contactUs}
                    alt="Contact Us"
                ></img>
            </div>
            <form
                className="absolute lg:w-[626px] w-[92%] flex flex-col justify-start items-stretch gap-10 lg:py-10 py-5 lg:px-14 px-7 bg-hotlink-purple-7158d7 lg:top-[54px] top-[42%] lg:right-[80px] right-4 shadow-contact rounded-[20px]"
            >
                <div className="flex flex-col justify-start items-center gap-[24px]">
                    <span className="lg:text-4xl text-3xl leading-normal tracking-[1.08px] text-white first-letter:capitalize">
                        {t('opportunity')}
                    </span>
                    <div className="h-[56px] flex justify-between gap-[24px] self-stretch">
                        <div className="w-full py-2 px-3 rounded-[8px] border-contact bg-white flex items-center ">
                            <input
                                className="w-full text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder={t('fullname')}
                            ></input>
                        </div>
                        <div className="w-full py-2 px-3 rounded-[8px] border-contact bg-white flex items-center ">
                            <input
                                className="w-full text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder={t('phone')}
                                pattern="[0-9]{11}"
                            ></input>
                        </div>
                    </div>

                    <div className="h-[56px] flex justify-between gap-[24px] self-stretch">
                        <div className="w-full py-2 px-3 rounded-[8px] border-contact bg-white flex items-center ">
                            <input
                                className="w-full text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder="Email"
                                type="email"
                            ></input>
                        </div>
                    </div>

                    <div className="h-[56px] flex justify-between gap-[24px] self-stretch">
                        <div className="w-full py-2 px-3 rounded-[8px] border-contact bg-white flex items-center ">
                            <input
                                className="w-full text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder={t('what_role')}
                            ></input>
                        </div>
                    </div>

                    <div className="h-[56px] flex justify-between gap-[24px] self-stretch">
                        <div className="w-full py-2 px-3 rounded-[8px] border-contact bg-white flex items-center ">
                            <input
                                className="w-full text-base leading-normal text-hotlink-black-000000d9 text-left font-BeVietNamRegular"
                                placeholder={t('need_answer')}
                            ></input>
                        </div>
                    </div>
                </div>

                <div className="lg:h-16 h-14 self-stretch flex items-center py-2 px-4 rounded-lg shadow-contact bg-hotlink-yellow-f6ae2d">
                    <input
                        className="grow text-xl leading-[1.2] text-white uppercase font-bold"
                        value={t('reserve_spot')}
                        type="submit"
                    ></input>
                </div>
            </form>
        </div>
    );
};

export default Contact;
