import {
    AreaChartOutlined,
    BookOutlined,
    CommentOutlined,
    FileTextOutlined,
    SmileOutlined,
    UserOutlined,
} from '@ant-design/icons';
import paths from './paths';

export const NAVIGATION_MENU = [
    {
        name: 'home',
        path: '/',
    },
    {
        name: 'dictionary',
        path: '/dictionary',
    },
    {
        name: 'news',
        path: '/news',
        children: [
            {
                name: 'Forum',
                path: '/news/forum',
                description: '',
                icon: <CommentOutlined className="text-4xl" />,
            },
            {
                name: 'Event',
                path: '/event',
                description: '',
                icon: <SmileOutlined className="text-4xl" />,
            },
            {
                name: 'Competition',
                path: '/competition',
                description: '',
                icon: <SmileOutlined className="text-4xl" />,
            },
            {
                name: 'News',
                path: '/news',
                description: '',
                icon: <SmileOutlined className="text-4xl" />,
            },
        ],
    },
    {
        name: 'exam_repository',
        path: '/exam',
        children: [
            {
                name: 'Speaking Practice',
                path: '/examRepository/speaking',
                description: '',
                icon: <SmileOutlined className="text-4xl" />,
            },
            {
                name: 'Exam Practice',
                path: '/exam',
                description: '',
                icon: <SmileOutlined className="text-4xl" />,
            },
        ],
    },
    {
        name: 'FlashCards',
        path: '/flash-card',
    },
];

export const NAVIGATION_ADMIN_MENU = [
    {
        key: 'statistics',
        name: 'statistics',
        icon: <AreaChartOutlined className="text-xl" />,
        link: paths.admin.statistics,
    },
    {
        key: 'exam',
        name: 'exam',
        icon: <FileTextOutlined className="text-xl" />,
        link: paths.admin.exam,
        children: [
            {
                name: 'exam-topic',
                key: 'exam-topic',
                icon: null,
                link: paths.admin.exam,
            },
            {
                name: 'test',
                key: 'test',
                icon: <FileTextOutlined />,
                link: paths.admin.test,
            },
        ],
    },
    {
        name: 'user',
        key: 'user',
        icon: <UserOutlined className="text-xl" />,
        link: paths.admin.user,
    },
    {
        name: 'news',
        key: 'news',
        icon: <BookOutlined className="text-xl" />,
        link: paths.admin.news,
    },
    {
        name: 'feedback',
        key: 'feedback',
        icon: <CommentOutlined />,
        link: paths.admin.feedback,
    },
];
