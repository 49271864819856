import React from 'react';

function VolumeProgress(props) {
    return (
        <input
            type="range"
            min={0}
            step={0.05}
            max={1}
            value={props.volume}
            className="w-[85px] h-2 rounded-full accent-hotlink-purple-9999ff bg-hotlink-white-f5f5f5 cursor-pointer"
            onChange={(e) => {
                props.onVolumeChange(e.currentTarget.valueAsNumber);
            }}
        ></input>
    );
}
export default VolumeProgress;
