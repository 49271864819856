import { Fragment, Suspense, useEffect, useState, lazy } from 'react';

// App
import { getDictionaryList } from '~/api/dictionaryApi';

// Internal
import { Agreement } from './components';
import DictionaryItemSkeleton from '../../Skeleton/Dictionary/DictionaryItemSkeleton';

// Lazy-load the DictionaryItem component
const DictionaryItem = lazy(() => import('./components/DictionaryItem'));

const DictionaryList = ({ searchWord }) => {
    // States
    const [dictionaryList, setDictionaryList] = useState([]);

    // Effects
    useEffect(() => {
        const handleDictionaryList = async () => {
            try {
                const response = await getDictionaryList(searchWord);

                if (response?.resolution) {
                    setDictionaryList([]);
                    return;
                }

                setDictionaryList(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        handleDictionaryList();
    }, [searchWord]);

    // Template
    return (
        <Fragment>
            <Agreement dictionaryList={dictionaryList} />
            {dictionaryList.length !== 0 ? (
                <Suspense fallback={<DictionaryItemSkeleton />}>
                    <div>
                        {dictionaryList.map((dictionaryItem, index) => (
                            <DictionaryItem key={dictionaryItem.word + index} dictionaryItem={dictionaryItem} />
                        ))}
                    </div>
                </Suspense>
            ) : (
                <div className="w-full mt-6 lg:mt-8 mb-16">
                    <div className="flex flex-col items-center justify-center">
                        <h1 className="text-hotlink-blue-4d27b3 text-2xl lg:text-3xl text-center font-semibold mb-2">
                            Your search terms did not match any entries.
                        </h1>
                        <div className="w-2/4 text-center text-sm text-gray-300 font-normal">
                            We cannot find any entries matching{' '}
                            <span className="text-hotlink-blue-4d27b3 text-lg font-semibold">{searchWord}</span>. Please
                            check you have typed the word correctly. Or, browse words and phrases beginning with{' '}
                            <span className="text-hotlink-blue-4d27b3 text-lg font-semibold">{searchWord[0]}</span>.
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default DictionaryList;
