import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart as ChartJS } from 'chart.js/auto';
import { Doughnut, Bar } from 'react-chartjs-2';
import { DatePicker } from 'antd';
import { ChevronDown, XmarkIcon } from '~/components/Icons';
import { Select } from 'antd';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { listPack } from '~/api/packApi';
import { countMockTestOfTopic } from '~/api/adminApi';

function TopicStatistic(props) {
    const controller = new AbortController();
    const axiosPrivate = useAxiosPrivate();
    const isMounted = useRef(true);

    const [menuPack, setMenuPack] = useState([]);
    const [packOption, setPackOption] = useState();

    const [labels, setLabels] = useState();
    const [data, setData] = useState();

    const [dateFormat, setDateFormat] = useState({ day: '', month: '', year: '' });
    const [date, setDate] = useState({ day: '', month: '', year: '' });

    const getListPack = async () => {
        try {
            const response = await listPack(axiosPrivate);
            if (response?.status === 'success') {
                const list = response.responseData.map((item, i) => ({
                    value: item.packId,
                    label: item.packName,
                }));
                setMenuPack(list);
                setPackOption(list[0].value);
            }
        } catch (error) {}
    };

    const countMockTestOfTopicAdmin = async () => {
        try {
            let dateFind;
            if (date.day) {
                dateFind = date.day;
            } else if (date.month) {
                dateFind = date.month;
            } else if (date.year) {
                dateFind = date.year;
            }
            const response = await countMockTestOfTopic(axiosPrivate, { date: dateFind, pack: packOption });
            if (response?.status === 'success') {
                setLabels(response.responseData.map((item) => item.topicName));
                setData(response.responseData.map((item) => item.countMockTest));
            }
        } catch (error) {
            console.log(error);
        }
    };
    const onChangeDay = (date, dateString) => {
        setDate((prevDate) => ({ ...prevDate, day: dateString, month: undefined, year: undefined }));
        setDateFormat((prevDate) => ({ ...prevDate, day: date, month: undefined, year: undefined }));
    };

    const onChangeMonth = (date, dateString) => {
        setDate((prevDate) => ({ ...prevDate, day: undefined, month: dateString, year: undefined }));
        setDateFormat((prevDate) => ({ ...prevDate, day: undefined, month: date, year: undefined }));
    };

    const onChangeYear = (date, dateString) => {
        setDate((prevDate) => ({ ...prevDate, day: undefined, month: undefined, year: dateString }));
        setDateFormat((prevDate) => ({ ...prevDate, day: undefined, month: undefined, year: date }));
    };

    useEffect(() => {
        if (isMounted.current) {
            getListPack();
        }

        return () => {
            isMounted.current = false;
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (packOption) {
            countMockTestOfTopicAdmin();
        }
    }, [packOption]);

    return (
        <div className="w-full min-h-screen bg-white flex flex-col gap-8 p-8 justify-start rounded-2xl shadow-[4px_4px_10px_0px_rgba(0,0,0,0.1)]">
            <div className="flex flex-row gap-8 px-4 justify-between items-start">
                <span className="font-BeVietNamRegular font-semibold text-xl text-hotlink-blue-7158d7">
                    Thống kê theo lĩnh vực
                </span>
                <div className="flex flex-row gap-3">
                    <Select
                        suffixIcon={<ChevronDown />}
                        allowClear={{ clearIcon: <XmarkIcon /> }}
                        className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d font-BeVietNamRegular"
                        options={menuPack}
                        placeholder="Chọn bộ đề thi"
                        value={packOption}
                        onChange={(value) => setPackOption(value)}
                    ></Select>
                    <DatePicker value={dateFormat.day} onChange={onChangeDay} />
                    <DatePicker value={dateFormat.month} picker="month" onChange={onChangeMonth} />
                    <DatePicker value={dateFormat.year} picker="year" onChange={onChangeYear} />
                    <button className="h-full w-[100px] px-[15px] py-1 rounded-sm shadow-[0_2px_0px_0px_rgba(0,0,0,0.4)] border-solid border-[1px] border-hotlink-blue-309 bg-hotlink-blue-309 font-BeVietNamRegular">
                        <span className="text-white">Lọc</span>
                    </button>
                </div>
            </div>
            <div className="flex justify-center items-center font-BeVietNamRegular">
                {labels && data ? (
                    <Doughnut
                        className="!w-1/2 !h-1/2"
                        data={{
                            labels: labels,
                            datasets: [
                                {
                                    label: 'Số lượng luyện tập',
                                    data: data,
                                },
                            ],
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default TopicStatistic;
