import { BellOutlined } from '@ant-design/icons';
import { Avatar, Badge, Empty, List, Popover, Typography } from 'antd';
import { useAppConfigStore } from '../../../../../../stores';

const { Text } = Typography;

const notifications = [
    { id: 1, message: 'You have a new message', time: '2 mins ago' },
    { id: 2, message: 'Your order has been shipped', time: '1 hour ago' },
    { id: 3, message: 'Reminder: Meeting at 3 PM', time: 'Today' },
];

const NotificationBell = () => {
    const { theme } = useAppConfigStore();

    return (
        <Popover
            content={
                notifications.length > 0 ? (
                    <List
                        dataSource={notifications}
                        renderItem={(item) => (
                            <List.Item
                                className={`${theme === 'dark' && 'dark'} flex cursor-pointer items-center gap-3 rounded-lg p-3 px-2 font-beVietnamPro`}
                            >
                                <div className="h-2 w-2 flex-shrink-0 rounded-full bg-blue-500"></div>

                                <div className="flex-1">
                                    <Text strong className="block text-sm text-gray-900 dark:text-white">
                                        {item.message}
                                    </Text>
                                    <Text type="secondary" className="mt-1 block text-xs text-gray-500">
                                        {item.time}
                                    </Text>
                                </div>
                            </List.Item>
                        )}
                        className="w-64"
                    />
                ) : (
                    <Empty description="No new notifications" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
            }
            placement="bottomRight"
            trigger="click"
        >
            <Badge count={notifications.length} className="border-none" style={{ border: 'none' }}>
                <Avatar
                    icon={<BellOutlined />}
                    size={35}
                    className="cursor-pointer transition-transform duration-300 hover:scale-110"
                    style={{ backgroundColor: '#1677ff', color: '#fff' }}
                />
            </Badge>
        </Popover>
    );
};

export default NotificationBell;
