import React from 'react';
import UserInformation from '../../../components/UserInformation/UserInformation';
import images from '~/assets/images';
import { NotificationBell, SettingLayout } from './components';
import { LanguagePicker } from '~/components/shared';
import { Row, Col, Button, Avatar } from 'antd';
import { MenuOutlined, SettingOutlined } from '@ant-design/icons';

const Header = ({ toggleDrawer, isDesktop }) => {
    return (
        <header className="w-full">
            <div className="py-4 lg:px-8">
                <Row justify="space-between" align="middle" gutter={24}>
                    {/* Logo Section */}
                    <Col xs={12} sm={12} md={8}>
                        <div className="flex items-center">
                            {!isDesktop && (
                                <Button
                                    icon={<MenuOutlined />}
                                    onClick={toggleDrawer}
                                    type="text"
                                    size="large"
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            )}
                            {isDesktop && <img className="ml-4 h-14 w-auto" src={images.logo} alt="MeU" />}
                        </div>
                    </Col>

                    {/* Right Section: Notification, User Info, Language */}
                    <Col xs={12} sm={12} md={16} className="flex items-center justify-end gap-4 md:gap-6">
                        {/* Notification Bell */}
                        <NotificationBell />

                        {/* Setting Layout */}
                        <SettingLayout />

                        {/* User Information */}
                        <div className="relative">
                            <UserInformation />
                        </div>
                    </Col>
                </Row>
            </div>
        </header>
    );
};

export default Header;
