// Core
import React, { useState, useEffect } from 'react';
import { Button, Carousel, Drawer, Space, Tag } from 'antd';
import cn from 'classnames';

// App
import { NavigationMenu, NavigationMenuItem, NavigationMenuList } from '~/components/ui';
import ButtonLogin from '~/components/Button/ButtonLogin';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

// Internal
import GlobalSearch from '../GlobalSearch';
import UserInformation from '../UserInformation/UserInformation';
import { LanguagePicker } from '~/components/shared';
import { useAuthStore } from '~/stores/modules/auth';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { NAVIGATION_MENU } from '../../../constants/menu';
import { useTranslation } from 'react-i18next';

const newsList = [
    {
        id: 1,
        title: 'Breaking News: English Record Highs',
        imageUrl: 'https://images.pexels.com/photos/242492/pexels-photo-242492.jpeg?auto=compress&cs=tinysrgb&w=600',
        summary: 'The stock market reached unprecedented levels today, with major indices showing significant gains.',
        author: 'John Doe',
        publishDate: '2025-01-07',
    },
    {
        id: 2,
        title: 'English Advances in 2025',
        imageUrl: 'https://images.pexels.com/photos/221166/pexels-photo-221166.jpeg?auto=compress&cs=tinysrgb&w=600',
        summary:
            'This year is shaping up to be groundbreaking in terms of technological innovations across industries.',
        author: 'Jane Smith',
        publishDate: '2025-01-06',
    },
    {
        id: 3,
        title: 'Global Events and Their Impact',
        imageUrl: 'https://images.pexels.com/photos/912445/pexels-photo-912445.jpeg?auto=compress&cs=tinysrgb&w=600',
        summary: 'An in-depth look at the most significant global events and their implications.',
        author: 'Alice Johnson',
        publishDate: '2025-01-05',
    },
    {
        id: 4,
        title: 'Health Tips for the New Year',
        imageUrl: 'https://images.pexels.com/photos/5676740/pexels-photo-5676740.jpeg?auto=compress&cs=tinysrgb&w=600',
        summary: 'Start the year off right with these essential health tips.',
        author: 'Michael Lee',
        publishDate: '2025-01-04',
    },
];

// Component
function Header() {
    // Hooks
    const location = useLocation();
    const { t: translate } = useTranslation('common');
    const isDesktop = useMediaQuery('(min-width: 767px)');
    const navigate = useNavigate();

    // States
    const [openMenu, setOpenMenu] = useState(false);
    const [openGlobalSearch, setOpenGlobalSearch] = useState(false);
    const [displayHeader, setDisplayHeader] = useState(false);

    // Stores
    const { isLoggedIn } = useAuthStore();

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 300) {
                setDisplayHeader(true);
            } else {
                setDisplayHeader(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="sticky top-0 z-50 flex w-full justify-center bg-white shadow-sm">
            <NavigationMenu>
                <div
                    className={cn(
                        'mx-auto flex h-20 max-w-screen-desktop flex-row items-center justify-between px-5 md:px-20 lg:h-24',
                    )}
                >
                    <Link to={'/'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src="/logo-me-u.png"
                            alt="logo"
                            width="60"
                            height="60"
                            style={{ marginRight: '10px', borderRadius: '50%', border: '2px solid #f6ae2d' }}
                        />
                        <h1
                            style={{ margin: 0, fontSize: '30px', color: '#f6ae2d' }}
                            className="bg-gradient-to-r from-orange-400 via-yellow-400 to-yellow-300 bg-clip-text font-beVietnamPro text-lg text-transparent lg:text-xl"
                        >
                            English
                        </h1>
                    </Link>
                    <div className="hidden h-full items-center justify-center lg:flex">
                        <NavigationMenuList>
                            {NAVIGATION_MENU.map((linkItem, index) => (
                                <NavigationMenuItem
                                    key={index}
                                    id={index}
                                    triggerElement={
                                        <div className="group relative flex h-full cursor-pointer items-center justify-center px-4 after:absolute after:bottom-0 after:left-1/2 after:h-[2px] after:w-0 after:bg-gradient-to-r after:from-yellow-400 after:to-orange-400 after:transition-all after:duration-300 hover:after:left-0 hover:after:w-full">
                                            <NavLink
                                                to={linkItem.path}
                                                className={({ isActive }) =>
                                                    cn(
                                                        'flex items-center font-beVietnamPro text-base font-medium transition-colors duration-300',
                                                        isActive
                                                            ? 'text-orange-500'
                                                            : 'text-gray-700 hover:text-orange-500',
                                                    )
                                                }
                                            >
                                                <Space>
                                                    {linkItem.icon && <linkItem.icon className="mr-2 text-lg" />}
                                                    {translate(linkItem.name)}
                                                </Space>
                                            </NavLink>
                                        </div>
                                    }
                                >
                                    {linkItem.children && (
                                        <div className="p-4 font-beVietnamPro">
                                            <h2 className="relative mb-6 inline-block text-xl font-bold text-gray-900 after:absolute after:bottom-[-6px] after:left-0 after:h-1 after:w-full after:rounded-full after:bg-gradient-to-r after:from-yellow-400 after:to-orange-400">
                                                {translate(linkItem.name)}
                                            </h2>
                                            <div className="flex gap-6">
                                                <div className="h-[280px] w-[280px] overflow-hidden rounded-lg shadow-lg">
                                                    <Carousel autoplay dotPosition="bottom">
                                                        {newsList.map((newsItem) => (
                                                            <div
                                                                key={newsItem.id}
                                                                className="relative h-[280px] bg-cover bg-center"
                                                                style={{ backgroundImage: `url(${newsItem.imageUrl})` }}
                                                            >
                                                                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
                                                                <div className="absolute bottom-0 p-4 text-white">
                                                                    <div className="mb-2 flex gap-2">
                                                                        <Tag color="magenta">Hot</Tag>
                                                                        <Tag color="volcano">Trending</Tag>
                                                                    </div>
                                                                    <h3 className="text-lg font-bold">
                                                                        {newsItem.title}
                                                                    </h3>
                                                                    <p className="text-sm text-gray-300">
                                                                        {newsItem.summary}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                                <div className="grid flex-1 grid-cols-2 gap-4">
                                                    {linkItem.children.map((childrenItem, childrenIndex) => (
                                                        <div
                                                            key={childrenIndex}
                                                            className="group flex flex-1 cursor-pointer items-center gap-4 rounded-lg bg-gray-100 p-4 transition-all duration-300 hover:bg-gradient-to-r hover:from-yellow-400 hover:to-orange-400 hover:shadow-xl"
                                                            onClick={() => navigate(childrenItem.path)}
                                                        >
                                                            <div className="text-4xl text-yellow-500 transition-colors duration-300 group-hover:text-white">
                                                                {childrenItem.icon}
                                                            </div>
                                                            <div>
                                                                <p className="text-lg font-semibold text-gray-800 transition-colors duration-300 group-hover:text-white">
                                                                    {translate(childrenItem.name)}
                                                                </p>
                                                                <p className="mt-1 text-sm text-gray-500 transition-colors duration-300 group-hover:text-white">
                                                                    {childrenItem.description ||
                                                                        'Discover more about this topic.'}
                                                                </p>
                                                            </div>
                                                            <div className="ml-auto transform text-yellow-500 transition-transform duration-300 group-hover:translate-x-2 group-hover:text-white">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={2}
                                                                    stroke="currentColor"
                                                                    className="h-6 w-6"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M9 5l7 7-7 7"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </NavigationMenuItem>
                            ))}
                        </NavigationMenuList>
                    </div>
                    <div className="flex h-full items-center justify-end" style={{ backgroundColor: '#fff' }}>
                        <div className="relative z-50 flex h-full flex-row items-center space-x-4 bg-white">
                            {/* <button className="hidden search-icon -ml-1" onClick={() => setOpenGlobalSearch(true)}>
                                <SearchIcon />
                            </button> */}
                            {isLoggedIn ? <UserInformation /> : <ButtonLogin link={'/login'} />}
                            <div className="choose-language ml-8 hidden text-sm leading-[1.57] text-black opacity-80 lg:flex">
                                <LanguagePicker />
                            </div>
                            <button
                                type="button"
                                className="flex items-center justify-center rounded-md text-gray-700 lg:hidden"
                                onClick={() => setOpenMenu(true)}
                            >
                                <MenuOutlined className="text-2xl" />
                            </button>
                            <Drawer
                                title={null}
                                closeIcon={null}
                                open={isDesktop ? false : openMenu}
                                onClose={() => setOpenMenu(false)}
                                styles={{ body: { padding: '0px' } }}
                            >
                                <div className="flex h-full w-full flex-col bg-hotlink-white-f7f3ff">
                                    {/* Header */}
                                    <div className="flex items-center justify-between bg-gradient-to-r from-purple-500 to-purple-600 px-5 py-4">
                                        <Button
                                            type="text"
                                            icon={<CloseOutlined className="text-lg text-white" />}
                                            onClick={() => setOpenMenu(false)}
                                            className="transition-all hover:bg-[#5a45b2]"
                                        />
                                        <div className="text-sm text-white">
                                            <LanguagePicker />
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className="flex h-full w-full flex-col bg-hotlink-white-f7f3ff">
                                            {NAVIGATION_MENU.map((navigationItem) => (
                                                <li
                                                    key={navigationItem.path}
                                                    className="relative flex h-12 items-center px-5 lg:h-full"
                                                    onClick={() => setOpenMenu(false)}
                                                >
                                                    <Link
                                                        to={navigationItem.path}
                                                        className={`navbar-title font-BeVietnamSemiBold z-20 text-left text-base leading-[22px] ${
                                                            navigationItem.path === location.pathname
                                                                ? 'text-hotlink-blue-309 lg:text-hotlink-yellow-f6ae2d'
                                                                : 'text-hotlink-black-1a1a1a'
                                                        }`}
                                                    >
                                                        {translate(navigationItem.name)}
                                                    </Link>
                                                    {navigationItem.path === location.pathname && (
                                                        <span className="absolute inset-0 border-r-2 border-x-hotlink-blue-309 bg-hotlink-white-d7cceb lg:border-b-2 lg:border-r-0 lg:border-hotlink-yellow-f6ae2d lg:bg-white" />
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </Drawer>
                        </div>
                        <GlobalSearch open={openGlobalSearch} onClose={() => setOpenGlobalSearch(false)} />
                    </div>
                </div>
            </NavigationMenu>
        </header>
    );
}

export default Header;
