import { Radio, Space } from 'antd';

import React, { useState, useEffect } from 'react';

function ResultExamRadio(props) {
    const answerChoice = props.questionRadio.answerChoice;
    const answerCorrect = props.questionRadio.answerCorrect;

    return (
        <div className="flex flex-col gap-4 p-4 items-start">
            <div className="flex flex-row w-full justify-between gap-4 items-center">
                <span className="font-BeVietNamMedium text-base text-hotlink-black-262626">
                    Câu {props.numberical}:{' '}
                    {props.questionRadio && !props.ofGroup ? props.questionRadio.questionContent : ''}
                </span>
            </div>

            <Radio.Group disabled value={answerChoice} className="w-full">
                <Space direction="vertical" className="w-full">
                    {Array.isArray(props.questionRadio.listAnswer)
                        ? props.questionRadio.listAnswer.map((option) => (
                              <Radio
                                  key={option.answerId}
                                  value={option.answerId}
                                  className={`w-full font-BeVietNamRegular text-base text-hotlink-black-085 ${
                                      answerChoice === option.answerId && answerChoice !== answerCorrect
                                          ? 'bg-hotlink-red-ffe8e8'
                                          : answerCorrect === option.answerId
                                          ? 'bg-hotlink-green-d8f0e2'
                                          : ''
                                  }`}
                              >
                                  {option.answerContent}
                              </Radio>
                          ))
                        : ''}
                </Space>
            </Radio.Group>
        </div>
    );
}

export default ResultExamRadio;
