import { Input, Upload, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { createNews } from '~/api/newsApi';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { CloseIcon } from '~/components/Icons';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';

const { TextArea } = Input;

function AddNews(props) {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const [tempImage, setTempImage] = useState(null);

    const [newsContent, setNewsContent] = useState({ title: '', content: '', image: '' });

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, []);

    const convertImageToPng = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const newFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".png"), {
                                type: "image/png",
                                lastModified: Date.now()
                            });
                            resolve(newFile);
                        } else {
                            reject(new Error("Image conversion failed."));
                        }
                    }, "image/png");
                };
                img.onerror = () => reject(new Error("Image loading failed."));
                img.src = e.target.result;
            };
            reader.onerror = () => reject(new Error("File reading failed."));
            reader.readAsDataURL(file);
        });
    };

    function validate() {
        if (newsContent.title === '') {
            dispatch(setStatusMessage('Create news fail'));
            dispatch(setErrMessage('Please enter news title'));
            return false;
        }

        if (newsContent.content === '') {
            dispatch(setStatusMessage('Create news fail'));
            dispatch(setErrMessage('Please enter news content'));
            return false;
        }

        if (newsContent.image === '') {
            dispatch(setStatusMessage('Create news fail'));
            dispatch(setErrMessage('Please insert image of news'));
            return false;
        }

        return true;
    }

    const handleImageChange = async ({ file }) => {
        try {
            const pngFile = await convertImageToPng(file.originFileObj);
            setTempImage(pngFile);
            setNewsContent((prevState) => ({ ...prevState, image: URL.createObjectURL(pngFile) }));
        } catch (error) {
            message.error("Image conversion to PNG failed.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (validate()) {
            try {
                dispatch(startLoading());
                const formData = new FormData();
                formData.append('title', newsContent.title);
                formData.append('content', newsContent.content);
                formData.append('image', tempImage); 
    
                const response = await createNews(axiosPrivate, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
    
                if (response?.violations === 401) {
                    dispatch(setErrMessage('Unauthorized'));
                    dispatch(setStatusMessage('Create news fail'));
                } else if (response?.status === 'fail') {
                    dispatch(setStatusMessage('Create news fail'));
                    dispatch(setErrMessage(response?.message));
                } else {
                    const newData = {
                        key: response.responseData.newsId,
                        number: 1,
                        newsTitle: response.responseData.newsTitle,
                        newsContent: response.responseData.newsContent,
                        image: response.responseData.newsImage,
                        enable: response.responseData.enable,
                        create_at: response.responseData.createAt,
                    };
    
                    const updatedDataSource = [newData, ...props.dataSource].map((item, index) => ({
                        ...item,
                        number: index + 1,
                    }));
    
                    props.setState((prevState) => ({
                        ...prevState,
                        dataSource: updatedDataSource,
                    }));
                    dispatch(setStatusMessage('Successful'));
                    dispatch(setErrMessage(response.message));
                    props.setOpenAdd(false);
                }
            } catch (err) {
                dispatch(setStatusMessage('Create news fail'));
                dispatch(setErrMessage('Create news fail'));
            } finally {
                dispatch(stopLoading());
            }
        }
    };

    return (
        <div className="fixed z-[100] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center">
            <div className="w-1/2 max-h-[80%] flex flex-col bg-white pb-8 rounded-xl">
                <header className="flex flex-row justify-between items-center bg-hotlink-blue-0A426E pt-8 pb-4 px-8 rounded-t-xl">
                    <span className="text-xl font-beVietnamPro text-hotlink-white-f5f5f5 after:max-w-full after:w-1/2 after:h-[3px] after:block after:bg-hotlink-red-ff4d4f">
                        Thêm tin tức mới
                    </span>
                    <button onClick={() => props.setOpenAdd(false)}>
                        <CloseIcon width="30" height="32" className="cursor-pointer" fill="#ffffff" />
                    </button>
                </header>

                <form className="flex flex-col gap-6 px-8 overflow-y-auto pt-4" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-col gap-4 border border-hotlink-white-d9d9d9 border-solid rounded-lg p-4">
                        <div className="flex flex-row items-center justify-start gap-4">
                            <span className="font-BeVietNamRegular text-base font-bold">Ảnh đại diện: </span>

                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    const isImage = file.type.startsWith('image/');
                                    if (!isImage) {
                                        message.error("Please select an image file.");
                                    }
                                    return isImage;
                                }}
                                onChange={handleImageChange}
                            >
                                {newsContent.image ? (
                                    <img src={newsContent.image} alt="News" style={{ width: '100%', height: "100%", objectFit: "contain" }} />
                                ) : (
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                )}
                            </Upload>
                        </div>

                        <div className="flex flex-col items-start justify-start gap-4">
                            <span className="font-BeVietNamRegular text-lg font-bold">Tiêu đề</span>

                            <TextArea
                                rows={4}
                                className="h-10 font-BeVietNamRegular text-base"
                                placeholder="Nhập tiêu đề"
                                onChange={(e) => {
                                    setNewsContent((prevState) => ({
                                        ...prevState,
                                        title: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <div className="flex flex-col items-start justify-start gap-4">
                            <span className="font-BeVietNamRegular text-lg font-bold">Nội dung</span>

                            <TextArea
                                rows={4}
                                className="h-10 font-BeVietNamRegular text-base"
                                placeholder="Nhập nội dung"
                                onChange={(e) => {
                                    setNewsContent((prevState) => ({
                                        ...prevState,
                                        content: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end items-center">
                        <button
                            className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                            type="submit"
                        >
                            <span className="text-base font-BeVietNamRegular font-bold leading-normal text-center text-white">
                                Thêm tin tức mới
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddNews;
