import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { setOptionAdminList } from '~/redux/adminSlice';

import Loading from '~/components/Loading';
import FindExam from '~/components/Admin/Exam/FindExam';
import AddExam from '~/components/Admin/Exam/AddExam/AddExam';
import TableAdmin from '~/components/Admin/TableAdmin';
import DeleteExam from '~/components/Admin/Exam/DeleteExam';
import EditExam from '~/components/Admin/Exam/EditExam';

function ManageExam() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [updateTable, setUpdateTable] = useState(0);

    const openModal = () => {
        setIsOpenModal(true);
    };

    const closeModal = () => {
        setIsOpenModal(false);
        setUpdateTable(prev => prev + 1);
    };

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const errMsg = useSelector((state) => state.errMessage.errMsg);
    const statusMsg = useSelector((state) => state.errMessage.statusMsg);

    const [api, contextHolder] = notification.useNotification();

    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [state, setState] = useState({
        totalRecords: 0,
        currentPage: 1,
        sizePage: 10,
        dataSource: [],
    });
    const [changeSource, setChangeSource] = useState();

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        dispatch(setOptionAdminList({}));
    }, []);

    useEffect(() => {
        if (errMsg) {
            openNotification('topRight', statusMsg, errMsg);
        }
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [errMsg, statusMsg]);

    useEffect(() => {
        if (isOpenModal) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isOpenModal]);

    return (
        <div className="flex flex-col justify-start items-start gap-4">
            {contextHolder}
            {openDelete ? (
                <DeleteExam
                    setOpenDelete={setOpenDelete}
                    dataSource={changeSource}
                    setUpdateTable={setUpdateTable}
                    updateTable={updateTable}
                />
            ) : null}
            {!openEdit ? (
                <>
                    <FindExam />
                    <AddExam openModal={openModal} />
                    <TableAdmin
                        setChangeSource={setChangeSource}
                        isOpenModal={isOpenModal}
                        closeModal={closeModal}
                        updateTable={updateTable}
                        state={state}
                        setState={setState}
                        setOpenDelete={setOpenDelete}
                        setOpenEdit={setOpenEdit}
                    />
                </>
            ) : (
                <EditExam
                    topic={changeSource}
                    setOpenEdit={setOpenEdit}
                />
            )}
            {isLoading && (
                <div className="fixed z-[1000] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default ManageExam;