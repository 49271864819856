import axios from '~/api/axios';

export const getDictionaryList = async (searchWord) => {
    const response = await axios.get(`/dictionary/search/${searchWord.trim().replace(' ', '_')}`);

    if (!response.data) throw new Error();

    return response.data.responseData;
};

export const getDictionaryImageList = async (searchWord) => {
    const response = await axios.get(`/dictionary/image/${searchWord}`);

    if (!response.data) throw new Error();

    return response.data.responseData;
};

export const getDictionarySuggestList = async (searchWord) => {
    const response = await axios.get(`/dictionary/suggest/${searchWord}`);

    if (!response.data) throw new Error();

    return response.data.responseData;
};
