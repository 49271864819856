import { useState } from 'react';
import { EditIcon, TrashIcon } from '~/components/Icons';
import DeleteModal from '../DeleteModal';
import AddFlashCardWord from '../AddFlashCardWord';

const FlashCardContentItem = (props) => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [word, setWord] = useState(props.word);

    return (
        <div className="flex flex-col gap-10 lg:px-[126px]">
            {openDeleteModal ? (
                <DeleteModal
                    flashCardWordId={word.wordId}
                    removeWordList={props.removeWordList}
                    closeModal={() => {
                        setOpenDeleteModal(false);
                    }}
                />
            ) : null}

            {openEditModal ? (
                <AddFlashCardWord
                    word={word}
                    updateWord={(wordUpdate) => {
                        setWord(wordUpdate);
                    }}
                    closeAddWord={() => {
                        setOpenEditModal(false);
                    }}
                />
            ) : null}
            <div className="w-full lg:p-5 p-2 cursor-pointer flex flex-row justify-between rounded-xl shadow-[0_4px_40px_0_rgba(0,0,0,0.15)] border-solid border-[1px] border-hotlink-purple-c6b9ff bg-white">
                <div className="flex flex-row lg:gap-8 gap-4 lg:px-4 px-2">
                    {word.image ? (
                        <img className="self-stretch max-w-[33.3333%] max-h-24" src={word.image} alt="MeU" />
                    ) : null}
                    <div className="flex flex-col gap-2 justify-start ">
                        <span className="text-xl text-hotlink-blue-309">{word.word}</span>
                        <div className="flex lg:flex-row flex-col gap-3">
                            <span className=" font-bold text-sm text-hotlink-black-333333">Định nghĩa:</span>
                            <span className="text-sm text-hotlink-black-333333">{word.define}</span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-4">
                    <button
                        onClick={() => {
                            setOpenEditModal(true);
                        }}
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => {
                            setOpenDeleteModal(true);
                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FlashCardContentItem;
