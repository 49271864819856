// pages/news/all/index.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '~/config';
import images from '~/assets/images';
export default function AllNews() {
    // Mock data cho categories
    const categories = [
        { id: 1, name: 'Tất cả', slug: 'all', image: '', description: 'Tất cả bài báo', status: '', order: '', create_at: '14-08-2024', update_at: '' },
        { id: 2, name: 'Giải đấu', slug: 'tournaments', image: '', description: 'Tất cả giải đấu', status: '', order: '', create_at: '14-08-2024', update_at: '' },
        { id: 3, name: 'Hội nhập', slug: 'results', image: '', description: 'Tất cả hội nhập', status: '', order: '', create_at: '14-08-2024', update_at: '' },
        { id: 4, name: 'Sự kiện', slug: 'events', image: '', description: 'Tất cả sự kiện', status: '', order: '', create_at: '14-08-2024', update_at: '' },
        { id: 4, name: 'Sự kiện', slug: 'events', image: '', description: 'Tất cả sự kiện', status: '', order: '', create_at: '14-08-2024', update_at: '' },
        { id: 4, name: 'Sự kiện', slug: 'events', image: '', description: 'Tất cả sự kiện', status: '', order: '', create_at: '14-08-2024', update_at: '' }
    ];

    const navigate = useNavigate();
    // Mock data cho news
    const allNews = [
        {
            id: '1',
            category_id: 2,
            name: 'Tin tức thể thao mới nhất',
            slug: 'tin-tuc-the-thao-moi-nhat',
            image: images.image404,
            description: 'Cập nhật những tin tức thể thao mới nhất trong ngày',
            status: 1,
            order: 1,
            created_at: '2024-03-20',
            updated_at: '2024-03-20'
        },
        {
            id: '2',
            category_id: 3,
            name: 'Giải đấu sắp diễn ra',
            slug: 'giai-dau-sap-dien-ra',
            image: images.image404,
            description: 'Thông tin về các giải đấu quan trọng sắp diễn ra',
            status: 1,
            order: 2,
            created_at: '2024-03-19',
            updated_at: '2024-03-19'
        },
        {
            id: '3',
            category_id: 2,
            name: 'Tin tức thể thao mới nhất',
            slug: 'tin-tuc-the-thao-moi-nhat',
            image: images.image404,
            description: 'Cập nhật những tin tức thể thao mới nhất trong ngày',
            status: 1,
            order: 1,
            created_at: '2024-03-20',
            updated_at: '2024-03-20'
        },
        {
            id: '4',
            category_id: 3,
            name: 'Giải đấu sắp diễn ra',
            slug: 'giai-dau-sap-dien-ra',
            image: images.image404,
            description: 'Thông tin về các giải đấu quan trọng sắp diễn ra',
            status: 1,
            order: 2,
            created_at: '2024-03-19',
            updated_at: '2024-03-19'
        },
        {
            id: '5',
            category_id: 2,
            name: 'Tin tức thể thao mới nhất',
            slug: 'tin-tuc-the-thao-moi-nhat',
            image: images.image404,
            description: 'Cập nhật những tin tức thể thao mới nhất trong ngày',
            status: 1,
            order: 1,
            created_at: '2024-03-20',
            updated_at: '2024-03-20'
        },
        {
            id: '6',
            category_id: 3,
            name: 'Giải đấu sắp diễn ra',
            slug: 'giai-dau-sap-dien-ra',
            image: images.image404,
            description: 'Thông tin về các giải đấu quan trọng sắp diễn ra',
            status: 1,
            order: 2,
            created_at: '2024-03-19',
            updated_at: '2024-03-19'
        },
    ];

    const uniqueCategories = Array.from(new Set(categories.map(cat => cat.slug)))
        .map(slug => categories.find(cat => cat.slug === slug));

    const [selectedCategory, setSelectedCategory] = useState('all');

    const filteredNews = selectedCategory === 'all'
        ? allNews
        : allNews.filter(news => {
            const category = categories.find(cat => cat.id === news.category_id);
            return category?.slug === selectedCategory;
        });

    const handleReadMore = (newsId) => {
        const detailPath = config.routes.newsDetail.replace(':id', newsId);
        navigate(detailPath);
    };

    const handleGoBack = () => {
        navigate(-1);
    };
    return (

        <div className="w-full bg-hotlink-purple-f7f3ff lg:px-20 px-6 py-10">
            {/* Categories Menu */}
            <div className="mt-4">
                <button
                    onClick={handleGoBack}
                    className="flex items-center hover:bg-hotlink-blue-6a41c5 px-3 py-1 text-sm"
                >
                    ← Quay lại
                </button>
            </div>
            <div className="mb-8 mt-5">
                <ul className="flex flex-wrap gap-4">
                    {uniqueCategories.map((category) => (
                        <li key={category.id}>
                            <button
                                onClick={() => setSelectedCategory(category.slug)}
                                className={`px-6 py-2 rounded-full font-BeVietNamMedium text-base
                    ${selectedCategory === category.slug
                                        ? 'bg-hotlink-blue-4d27b3 text-white'
                                        : 'bg-white text-hotlink-blue-4d27b3 border border-hotlink-blue-4d27b3'
                                    }`}
                            >
                                {category.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                {filteredNews.map((news) => (
                    <div key={news.id} className="bg-white rounded-lg shadow-md p-4">
                        {news.image && (
                            <img
                                src={news.image}
                                alt={news.name}
                                className="w-full h-48 object-cover rounded-md mb-4"
                            />
                        )}
                        <h3 className="font-BeVietNamMedium text-lg mb-2">{news.name}</h3>
                        <p className="text-gray-600 text-sm mb-3">{news.description}</p>
                        <div className="flex justify-between items-center text-sm text-gray-500">
                            <span>{new Date(news.created_at).toLocaleDateString('vi-VN')}</span>
                            <button
                                className="text-hotlink-blue-4d27b3 hover:underline"
                                onClick={() => handleReadMore(news.id)}
                            >
                                Đọc ngay
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}