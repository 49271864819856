import { RightArrowIcon, LeftArrowIcon } from '~/components/Icons';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import './HotNews.scss';
import HotNewsItem from './HotNewsItem';
import { ChevronRight } from '~/components/Icons';

import { listNewsUser } from '~/api/newsApi';

function HotNews() {
    const controller = new AbortController();

    const [data, setData] = useState([]);

    const getListNews = async () => {
        try {
            const response = await listNewsUser(
                { size: '5' },
                {
                    signal: controller.signal,
                },
            );

            if (response?.status === 'OK') {
                const newData = response.responseData.map((item, i) => ({
                    key: item.newsId,
                    hotNewPicture: item.newsImage,
                    title: item.newsTitle,
                    description: item.newsContent,
                    day: item.updateAt.split(' ')[0],
                }));

                setData(newData);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        getListNews();

        return () => {
            controller.abort();
        };
    }, []);


    const settings = {
        customPaging: (i) => <div className="w-4 h-[3px] rounded-[1px] bg-hotlink-gray-a7a7a7 mt-5"></div>,
        dots: true,
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]

        
    };

    return (
        <div className="space-y-8 lg:px-20 px-6 py-10 bg-hotlink-purple-f7f3ff relative">
            <h2 className="lg:text-[40px] text-3xl font-BeVietNamBold font-semibold text-hotlink-blue-309 text-center">
                Tin nổi bật
            </h2>

            <Slider {...settings} className="relative">
                {data?.map((item) => (
                    <div key={item.id} className="px-2">
                        <HotNewsItem
                            hotNewPicture={item.hotNewPicture}
                            title={item.title}
                            day={item.day}
                            description={item.description}
                        />
                    </div>
                ))}
            </Slider>

            <div className="flex justify-center mt-6">
                <button className="flex flex-row mt-[32px] justify-center items-center gap-3 py-2 px-4 border-solid border border-hotlink-blue-4d27b3 rounded-xl bg-hotlink-blue-4d27b3">
                    <span className="font-BeVietNamMedium text-white text-base leading-normal">Xem tất cả</span>
                    <ChevronRight />
                </button>
            </div>
        </div>
    );


}

export default HotNews;
