import './Banner.scss';
import { useTranslation } from 'react-i18next';

function Banner() {
    const currentURL = window.location.pathname;

    const { t } = useTranslation('common');

    return (
        <div className="banner w-full lg:px-[106px] px-7 lg:py-[160px] py-[100px] h-[550px] flex items-center">
            {currentURL === '/test' ? (
                <div className="w-[490px] h-[250px] lg:ml-20 ml-2">
                    <h1 className="text-left font-BeVietnamSemiBold not-italic lg:text-[50px] text-3xl text-white leading-tight">
                        {t('online_exam')}
                    </h1>
                    <p className="py-[25px] font-normal leading-snug text-base text-white ml-2">
                        "{t('exam_describe')}"
                    </p>
                    <button className="w-[305px] h-[56px] flex justify-center items-center rounded-full shadow bg-[#4d27b3] mt-8">
                        <span className="text-[16px] text-white font-BeVietnamSemiBold">{t('register_now')}</span>
                    </button>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default Banner;
