import './LoginPage.scss';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import images from '~/assets/images';
import LoginImage from '~/assets/svg/Group64.svg';
import ShowPasswordImg from '~/assets/svg/ShowPassword.svg';
import { validate } from '~/api/userApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { notification, Spin } from 'antd';

import { useNavigate, useLocation } from 'react-router-dom';

import axios from '~/api/axios';
const LOGIN_URL = '/login';

function Login() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassWord, setShowPassWord] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const showPass = () => {
        setShowPassWord((prev) => !prev);
    };

    const from = location.state?.from?.pathname || '/';

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    useEffect(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [email, password]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
    }, [errMsg, statusMsg]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            const response = await axios.post(LOGIN_URL, JSON.stringify({ email, password }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });

            if (response?.data?.violations === 401) {
                setErrMsg('Unauthorized');
                setStatusMsg('Đăng nhập thất bại');
            } else if (response?.data?.status === 'fail') {
                setErrMsg('Sai email hoặc mật khẩu');
                setStatusMsg('Đăng nhập thất bại');
            } else {
                const accessToken = response?.data?.responseData?.accessToken;
                const refreshToken = response?.data?.responseData?.refreshToken;

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                setEmail('');
                setPassword('');

                setErrMsg('Đăng nhập thành công');
                setStatusMsg('Thông báo');

                const responseInfor = await validate(axiosPrivate, null, {
                    withCredentials: true,
                });
                if (responseInfor && responseInfor.status === 'success') {
                    localStorage.setItem('ROLE', responseInfor.responseData.Role)
                    setTimeout(() => {
                        if (responseInfor.responseData.Role === 'ADMIN') {
                            navigate('/admin/manager/manager-exam');
                        } else {
                            navigate(from, { replace: true });
                        }
                    }, 1000);
                }
            }
        } catch (err) {
            setErrMsg('Đăng nhập thất bại');
            setStatusMsg('Thông báo');
            errRef.current.focus();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full h-screen bg-white flex relative">
            {contextHolder}
            <div className="w-[50%] tablet:w-full mobile:w-full flex flex-col justify-between">
                <h1 className="flex justify-center items-center	tablet:items-start tablet:mt-20 mobile:items-start mobile:mt-5 text-center flex-[1] text-[50px] leading-[44px] font-semibold not-italic tracking-normal text-[#309]">
                    {t('login.welcome')}
                </h1>
                <img src={LoginImage} alt="" className="shrink-0 object-contain" />
            </div>
            <div className="w-[50%] tablet:w-full mobile:w-full tablet:absolute mobile:absolute tablet:top-0 mobile:top-0 tablet:bottom-0 mobile:bottom-0 h-auto flex flex-col justify-center items-center">
                <div className="w-[80%] flex flex-col items-center rounded-[40px] gap-6 bg-white shadow-2xl">
                    <h1 className="font-BeVietNamRegular pt-6 text-[30px] leading-[44px] font-semibold not-italic tracking-normal text-left text-[#309]">
                        {t('login.sign_in')}
                    </h1>
                    <div className="w-[80%] flex items-center font-BeVietNamRegular font-normal text-[14px]">
                        <p className="mr-2 leading-[1.57] text-[#333]">{t('login.do_not_account')}</p>
                        <div
                            className="leading-[1.57] text-[#309] cursor-pointer "
                            onClick={() => navigate('/register')}
                        >
                            {t('register_now')}
                        </div>
                    </div>
                    <div className="w-[80%]">
                        <form className="flex flex-col" onSubmit={handleSubmit}>
                            <label className="py-2 text-sm">{t('login.email')}</label>
                            <div className="border-gray flex items-center mb-3">
                                <input
                                    className="w-[95%] text-base font-BeVietNamRegular"
                                    placeholder={t('login.input_email')}
                                    ref={userRef}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                    type="text"
                                />
                            </div>
                            <label className="py-2 text-sm">{t('login.password')}</label>
                            <div className="border-gray flex justify-between items-center mb-3">
                                <input
                                    type={showPassWord ? 'text' : 'password'}
                                    className="w-[95%] text-base font-BeVietNamRegular"
                                    placeholder={t('login.input_password')}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                                <img className="cursor-pointer" onClick={showPass} src={ShowPasswordImg} alt="" />
                            </div>
                            {/* <p className="my-2 grow-0 font-poppinsRegular text-[14px] font-medium leading-[1.57] text-right text-[#309]">
                                {t('login.forgot_password')}
                            </p> */}
                            <div
                                className="my-2 grow-0 font-BeVietNamRegular text-[14px] font-medium leading-[1.57] text-right text-[#309] cursor-pointer"
                                onClick={() => navigate('/forgetPassword')}
                            >
                                {t('login.forgot_password')}
                            </div>
                            <button className="my-2 px-4 py-3 bg-[#309] text-base text-white mb-4">
                                {t('login.sign_in')}
                            </button>
                        </form>
                    </div>
                    {/* <div className="w-[80%] flex flex-col justify-center items-center">
                        <p className="text-sm font-medium">{t('login.or')}</p>
                        <div className="w-[40%] mobile:w-[60%] pt-6 pb-8 flex justify-between items-center">
                            <img src={images.google} alt="social" />
                            <img src={images.facebook} alt="social" />
                            <img src={images.zalo} alt="social" />
                        </div>
                    </div> */}
                </div>
            </div>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading..." size="large"></Spin>
                </div>
            )}
        </div>
    );
}

export default Login;
