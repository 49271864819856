import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import LoginImage from '~/assets/svg/Group64.svg';
import { notification, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '~/api/axios';

const VERIFY_OTP_URL = '/verifyOtp';
const FORGOTPASSWORD_URL = '/forgetPassword';
function VerifyOTP() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [user, setUser] = useState({ email: '', otp: ['', '', '', '', '', ''], err: {} });
    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');
    const [isOTPSent, setIsOTPSent] = useState(true);
    const [timeLeft, setTimeLeft] = useState(59);
    const [ResentOtp, setResentOtp] = useState(false);


    const timerRef = useRef(null);

    useEffect(() => {
        if (location.state && location.state.email) {
            setUser(prev => ({ ...prev, email: location.state.email }));
        }

        if (isOTPSent) {
            timerRef.current = setInterval(() => {
                setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
            }, 1000);
        }

        return () => clearInterval(timerRef.current);
    }, [isOTPSent, location.state]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
    }, [errMsg, statusMsg]);

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
            const newOTP = [...user.otp];
            newOTP[index] = value;
            setUser((prevUser) => ({
                ...prevUser,
                otp: newOTP,
            }));

            if (value && index < 5) {
                document.getElementById(`otp-input-${index + 1}`)?.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !user.otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`)?.focus();
        }
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        const otpCode = user.otp.join('');
        if (otpCode.length < 6) {
            setErrMsg('Vui lòng nhập đầy đủ mã OTP');
            setStatusMsg('Xác thực thất bại');
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(
                `${VERIFY_OTP_URL}?otp=${encodeURIComponent(otpCode)}`,
                null,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            if (response?.data?.status === "success") {
                setErrMsg('Xác thực thành công');
                setStatusMsg('Xác thực thành công');
                navigate('/changePassword', { state: { email: user.email, otp: otpCode } });
            } else {
                setErrMsg('OTP không chính xác');
                setStatusMsg('Xác thực thất bại');
            }
        } catch (err) {
            setErrMsg('Xác thực thất bại');
            setStatusMsg('Xác thực thất bại');
        } finally {
            setIsLoading(false);
        }
    };

    const getMaskedEmail = (email) => {
        if (!email) return '';
        const [localPart, domain] = email.split('@');
        const maskedLocalPart = localPart.slice(0, -4).replace(/./g, '*') + localPart.slice(-4);
        return `${maskedLocalPart}@${domain}`;
    };

    const formattedTime = `${Math.floor(timeLeft / 60).toString().padStart(2, '0')}:${(timeLeft % 60).toString().padStart(2, '0')}`;

    const handleResendOTP = async () => {
        try {
            setResentOtp(true);
            setTimeLeft(59);
            const response = await axios.post(`${FORGOTPASSWORD_URL}?email=${encodeURIComponent(user.email)}`,
                null,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            if (response?.data?.status === 'OK') {
                setErrMsg('Mã OTP đã được gửi lại thành công');
                setStatusMsg('Thành công');
            } else {
                setErrMsg('Gửi lại OTP thất bại');
                setStatusMsg('Thất bại');
            }
        } catch (err) {
            setErrMsg('Gửi lại OTP thất bại');
            setStatusMsg('Thất bại');
        }
    };

    return (
        <div className="w-full h-screen bg-white flex relative">
            {contextHolder}
            <div className="w-[50%] tablet:w-full mobile:w-full flex flex-col justify-between">
                <h1 className="flex justify-center items-center tablet:items-start tablet:mt-20 mobile:items-start mobile:mt-10 text-center flex-[1] text-[50px] leading-[44px] font-semibold not-italic tracking-normal text-[#309]">
                    {t('forgetPassword.welcome')}
                </h1>
                <img src={LoginImage} alt="" className="shrink-0 object-contain" />
            </div>
            <div className="w-[50%] h-auto flex flex-col justify-center items-center tablet:w-full mobile:w-full tablet:absolute mobile:absolute tablet:top-0 mobile:top-0 tablet:bottom-0 mobile:bottom-0">
                <div className="w-[80%] flex flex-col items-center rounded-[40px] gap-6 bg-white shadow-2xl">
                    <h1 className="font-BeVietNamRegular pt-6 text-[30px] leading-[44px] font-BeVietNambold not-italic tracking-normal text-left text-[#309]">
                        {t('forgetPassword.sign_in')}
                    </h1>
                    <div className="w-[80%]">
                        <p className="text-lg text-center font-BeVietNambold">
                            Một mã xác thực vừa gửi về email {getMaskedEmail(user.email)}
                        </p>
                        <p className="text-sm text-center">
                            Vui lòng nhập mã xác thực:
                        </p>
                        <form className="flex justify-center gap-2 my-4" onSubmit={handleVerifyOTP}>
                            {user.otp.map((digit, index) => (
                                <input
                                    key={index}
                                    id={`otp-input-${index}`}
                                    type="text"
                                    maxLength="1"
                                    className="w-12 h-12 text-center text-lg border border-gray-300 rounded"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                            ))}
                        </form>
                        <p className="text-sm text-center">
                            Mã có hiệu lực trong {formattedTime}
                            {
                                timeLeft === 0 && (
                                    <span onClick={handleResendOTP} className="ml-1 text-blue-500 hover:text-blue-700 cursor-pointer">Gửi lại</span>
                                )}
                        </p>
                        <button className="w-full my-2 px-4 py-2 bg-[#309] text-base text-white mb-4"
                            onClick={handleVerifyOTP}
                            disabled={timeLeft === 0}
                        >
                            Xác nhận
                        </button>
                        <div className="my-2 text-center px-4 mb-4">
                            <span
                                className="text-base text-sky-500 cursor-pointer"
                                onClick={() => navigate('/forgetPassword')}
                            >
                                Trở về
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading" size="large" />
                </div>
            )}
        </div>
    );
}

export default VerifyOTP;
