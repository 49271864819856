import { useSelector } from 'react-redux';

import Avatar from '../../../Avatar';
import './CreatePost.scss';

function CreatePost(props) {
    const user = useSelector((state) => state.user.user);

    return user && user.User ? (
        <div className="w-full bg-white p-4 flex flex-col gap-6 font-medium text-xs rounded-xl shadow-[4px_4px_30px_0_rgba(0,0,0,.15)]">
            <div className="w-full flex flex-row gap-4">
                <Avatar className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba h-12 w-12" src={user.User.avatar ? user.User.avatar : ''} alt="user" />
                <button
                    onClick={() => {
                        props.openModal();
                    }}
                    className="w-[70%] flex items-center px-3 py-2 cursor-pointer rounded-xl bg-hotlink-gray-f4f4f4"
                >
                    <span className="font-BeVietNamRegular text-base">Vấn đề bạn cần trao đổi</span>
                </button>
                <button
                    className="create-post-button hidden lg:flex"
                    onClick={() => {
                        props.openModal();
                    }}
                >
                    Đăng bài
                    <div className="icon">
                        <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    ) : null;
}
export default CreatePost;
