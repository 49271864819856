import React, { useEffect } from 'react';
import { Table, Space, Switch, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EditIcon, TrashIcon } from '~/components/Icons';
import { setOptionAdminList } from '~/redux/adminSlice';

import Avatar from '~/components/Avatar';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';

import { listMockTest } from '~/api/mockTestApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

const TableMockTest = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const controller = new AbortController();

    const optionAdminList = useSelector((state) => state.admin.optionAdminList);
    // const optionAdminList = useSelector((state) => state.option.option);

    const columns = [
        {
            title: 'STT',
            dataIndex: 'number',
            align: 'right',
        },
        {
            title: 'Người thi',
            dataIndex: 'user_name',
        },
        {
            title: 'Điểm',
            dataIndex: 'score',
        },
        {
            title: 'Thời gian thi',
            dataIndex: 'time',
        },
        {
            title: 'Ngày thi',
            dataIndex: 'create_at',
            align: 'right',
        },
    ];

    const getListMockTest = async (page, pageSize) => {
        try {
            dispatch(startLoading());

            const setNewOptionAdmin = { ...optionAdminList, size: props.state.sizePage };
            const response = await listMockTest(axiosPrivate, setNewOptionAdmin, {
                signal: controller.signal,
            });

            if (response?.status === 'OK') {
                props.setState((prevState) => ({ ...prevState, totalRecords: response.responseData.totalRecords }));
                const mockTestData = response.responseData.listMockTest.map((item, i) => ({
                    key: item.mockTestID,
                    number: (page - 1) * pageSize + i + 1,
                    score: item.score,
                    time: item.time,
                    topicId: item.topicId,
                    create_at: item.createAt,
                    update_at: item.updateAt,
                    user_name: item.user.user_name,
                    user_id: item.user.user_id,
                }));

                props.setState((prevState) => ({ ...prevState, dataSource: mockTestData }));
            }
        } catch (error) {
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (optionAdminList.page === undefined) {
            props.setState((prevState) => ({ ...prevState, currentPage: 1 }));
            getListMockTest(1, props.state.sizePage);
        } else {
            getListMockTest(optionAdminList.page + 1, props.state.sizePage);
        }

        return () => { };
    }, [optionAdminList, props.updateTable]);

    const handlePageChange = (page, pageSize) => {
        props.setState((prevState) => ({ ...prevState, sizePage: pageSize }));
        props.setState((prevState) => ({ ...prevState, currentPage: page }));
        dispatch(setOptionAdminList({ ...optionAdminList, page: page - 1 }));
    };

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [props.state.dataSource]);

    return (
        <div className="w-full">
            <Table
                className="format-table"
                columns={columns}
                dataSource={props.state.dataSource}
                pagination={{
                    defaultPageSize: 10,
                    current: props.state.currentPage,
                    showSizeChanger: true,
                    position: ['bottomRight'],
                    pageSizeOptions: ['5', '10', '20'],
                    total: props.state.totalRecords,
                    locale: { items_per_page: '' },
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
};
export default TableMockTest;
