const DictionaryItemSkeleton = () => {
    return (
        <div className="flex flex-col justify-center items-start w-full lg:mb-16 mb-8 gap-4 bg-white shadow-shadow-meanings rounded-xl lg:p-8 py-8 px-4 pb-12">
            <div className="flex flex-col justify-start items-start gap-2 w-full">
                <div className="w-full flex justify-between items-center">
                    <div className="h-6 bg-gray-200 rounded-md w-1/3 animate-pulse"></div>
                    <div className="h-6 bg-gray-200 rounded-md w-8 animate-pulse"></div>
                </div>
                <div className="h-4 bg-gray-200 rounded-md w-2/3 my-2 animate-pulse"></div>
                <div className="flex flex-row justify-start items-center gap-10 w-full">
                    <div className="h-4 bg-gray-200 rounded-md w-1/4 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded-md w-1/4 animate-pulse"></div>
                </div>
                <div className="flex lg:flex-row flex-col gap-4 rounded-2xl lg:p-6 lg:pl-2 py-6 px-4 w-full">
                    <div className="w-full lg:w-1/3 h-48 bg-gray-200 rounded-md animate-pulse"></div>
                    <div className="w-full lg:w-2/3 flex flex-col justify-center gap-2 lg:px-4">
                        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                        <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse"></div>
                        <div className="h-4 bg-gray-200 rounded w-4/6 animate-pulse"></div>
                    </div>
                </div>
                <div className="space-y-8 w-full">
                    <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded w-4/6 animate-pulse"></div>
                </div>
            </div>
        </div>
    );
};

export default DictionaryItemSkeleton;
