import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';

import { listFlashCardWord } from '~/api/flashCardApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import FlashCardReviewItem from './FlashCardReviewItem/FlashCardReviewItem';
import { LeftArrowIcon, RightArrowIcon } from '~/components/Icons';
import './FlashCardReviewContent.scss';

import { setListWord } from '~/redux/flashCardSlice';

const FlashCardReviewContent = () => {
    const slider = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current word index
    const location = useLocation();
    const controller = new AbortController();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const listWord = useSelector((state) => state.flashCard.listWord);
    const flashCardId = new URLSearchParams(location.search).get('card');

    const getWordToFlashCard = async (flashCardId) => {
        try {
            const response = await listFlashCardWord(axiosPrivate, flashCardId);
            if (response.status === 'OK') {
                // Randomize the list of words
                const randomizedWords = randomizeArray(response.responseData.flashCardWord);
                dispatch(setListWord({ flashCardWord: randomizedWords })); // Update state with randomized words
            }
        } catch (err) {
            console.error(err); // Log any errors
        }
    };

    // Function to randomize an array
    const randomizeArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    const settings = {
        infinite: false, // Disable infinite scrolling to enable next/prev disabling
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (index) => setCurrentIndex(index), // Update the current index after slide change
    };

    const listWordSlider = listWord
        ? listWord.flashCardWord.map((element) => {
            return <FlashCardReviewItem word={element} key={element.wordId} />;
        })
        : null;

    useEffect(() => {
        getWordToFlashCard(flashCardId);

        return () => {
            controller.abort();
        };
    }, [flashCardId]);

    // Disable next/prev buttons if there's only one word or if at the first/last word
    const isPrevDisabled = currentIndex === 0 || (listWord && listWord.flashCardWord.length === 1);
    const isNextDisabled =
        currentIndex === listWord?.flashCardWord.length - 1 || (listWord && listWord.flashCardWord.length === 1);

    return listWord ? (
        <div className="w-full h-screen flex flex-col justify-start gap-8 py-10 px-6 rounded-xl bg-white">
            <div className="flex flex-row justify-end">
                <button
                    className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                    onClick={() => navigate(`/flash-card/list?card=${flashCardId}`)}
                >
                    <span className="text-base font-BeVietNamBold leading-normal text-center font-medium text-white">
                        Kết thúc Luyện tập
                    </span>
                </button>
            </div>

            <div className="flex flex-col gap-10 lg:px-[126px] px-5 h-full">
                <Slider className="flash-card-slider h-full" ref={slider} {...settings}>
                    {listWordSlider}
                </Slider>

                <div className="flex flex-row gap-10 justify-center items-center">
                    <button
                        className={`flex items-center justify-center rounded-[100%] p-3 bg-hotlink-white-e6e6e6 ${isPrevDisabled ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        onClick={() => slider?.current?.slickPrev()}
                        disabled={isPrevDisabled}
                    >
                        <LeftArrowIcon width="30px" height="30px" />
                    </button>

                    {/* Display current index / total count */}
                    <span className="text-lg font-medium">{`${currentIndex + 1}/${listWord.flashCardWord.length}`}</span>

                    <button
                        className={`flex items-center justify-center rounded-[100%] p-3 bg-hotlink-white-e6e6e6 ${isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        onClick={() => slider?.current?.slickNext()}
                        disabled={isNextDisabled}
                    >
                        <RightArrowIcon width="30px" height="30px" />
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default FlashCardReviewContent;
