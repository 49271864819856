import React from 'react';
import LatestNewsItem from './LatestNewsItem';

const LatestNews = ({ news }) => {
  return (
    <div className="space-y-8">
      <h2 className="lg:text-[40px] text-3xl font-BeVietNamBold font-semibold text-hotlink-blue-309 text-center">Tin mới nhất</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {news?.map((item) => (
          <LatestNewsItem 
            key={item.id} 
            news={item}
          />
        ))}
      </div>
    </div>
  );
};

export default LatestNews;
