import { useNavigate } from 'react-router-dom';
import images from '~/assets/images';

function SpeakingPractice() {

  const navigate = useNavigate(); 
    return (
      <div className="container mx-auto py-12 min-h-screen flex flex-col justify-center">
        <h1 className="text-3xl font-bold mb-12 text-center text-gray-800">
          MeU English là công cụ học tiếng Anh tối ưu của bạn!
        </h1>
        <div className="space-y-8 flex-grow">
          {/* Item 1 */}
          <div className="flex justify-between items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out border-l-4 border-blue-500">
            <div className="flex items-center space-x-6">
              <img
                src={images.phongvanimg}
                alt="Thực tập phòng vấn"
                className="w-24 h-24 rounded-full object-cover"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Thực tập phòng vấn</h2>
                <p className="text-gray-600 mt-2 text-sm">
                  Có công mài sắt có ngày nên kim! Hãy luyện tập những gì bạn dự định nói nhé!
                </p>
              </div>
            </div>
            <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-600 text-white px-5 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out text-xl"
            onClick={() => navigate('/speaking-exam')}>
              Bắt đầu
            </button>
          </div>
  
          {/* Item 2 */}
          <div className="flex justify-between items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out border-l-4 border-blue-500">
            <div className="flex items-center space-x-6">
              <img
                src={images.luyenthi}
                alt="Luyện thi nói tiếng Anh"
                className="w-24 h-24 rounded-full object-cover"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Luyện thi nói tiếng Anh</h2>
                <p className="text-gray-600 mt-2 text-sm">
                  Dù bạn đang chuẩn bị cho bài thi IELTS, TOEIC hay TOEFL, chúng tôi luôn đồng hành cùng bạn!
                </p>
              </div>
            </div>
            <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-600 text-white px-5 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out text-xl" 
            onClick={() => navigate('/speaking-exam')}>
              Bắt đầu
            </button>
          </div>
  
          {/* Item 3 */}
          <div className="flex justify-between items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out border-l-4 border-blue-500">
            <div className="flex items-center space-x-6">
              <img
                src={images.avatarpr}
                alt="Luyện giao tiếp tiếng Anh"
                className="w-24 h-24 rounded-full object-cover"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Luyện giao tiếp tiếng Anh</h2>
                <p className="text-gray-600 mt-2 text-sm">
                  Luyện tập tiếng Anh hàng ngày và nhận phản hồi chi tiết về cách phát âm và ngữ điệu của bạn.
                </p>
              </div>
            </div>
            <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-600 text-white px-5 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out text-xl" 
            onClick={() => navigate('/speaking-exam')}>
              Bắt đầu
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  export default SpeakingPractice;
  