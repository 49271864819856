import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { ThreeDots, TrashIcon, EditIcon } from '~/components/Icons';
import DeleteFlashCard from '../DeleteFlashCard';
import AddFlashCard from '../AddFlashCard';

const FlashCardItem = (props) => {
    const navigate = useNavigate();

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    return (
        <div className="lg:w-1/3-18px w-full pr-2 cursor-pointer flex flex-row items-start justify-between rounded-xl shadow-[0_4px_40px_0_rgba(0,0,0,0.15)] border-solid border-[1px] border-hotlink-purple-c6b9ff bg-white">
            {openDelete ? (
                <DeleteFlashCard
                    flashCardId={props.flashCard.flashCardId}
                    removeFlashCard={props.removeFlashCard}
                    closeModal={() => {
                        setOpenDelete(false);
                    }}
                />
            ) : null}

            {openEdit ? (
                <AddFlashCard
                    flashCard={props.flashCard}
                    updateFlashCard={props.updateFlashCard}
                    closeAdd={() => {
                        setOpenEdit(false);
                    }}
                />
            ) : null}
            <button
                className="w-[90%] flex flex-row items-center"
                onClick={() => navigate(`/flash-card/list?card=${props.flashCard.flashCardId}`)}
            >
                <img
                    className="self-stretch min-h-[136px] w-[125px] rounded-l-xl border-solid border-[1px] border-hotlink-purple-c6b9ff"
                    src={props.flashCard.flashCardImage}
                    alt="MeU"
                />
                <div className="flex flex-col gap-2 justify-center px-4">
                    <span className="text-xl text-hotlink-blue-309">
                        {props.flashCard.flashCardTitle}
                    </span>
                    <span className="text-xs text-hotlink-black-333333">
                        {props.flashCard.flashCardDescription}
                    </span>
                </div>
            </button>

            <Popover
                content={
                    <div className="flex flex-col gap-4 px-4 py-2">
                        <div className="flex flex-col gap-4">
                            <button
                                className="flex flex-row gap-2 justify-start items-center"
                                onClick={() => {
                                    setOpenEdit(true);
                                    setOpen(false);
                                }}
                            >
                                <EditIcon />
                                <span className="text-sm">Chỉnh sửa</span>
                            </button>
                            <button
                                className="flex flex-row gap-2 justify-start items-center"
                                onClick={() => {
                                    setOpenDelete(true);
                                    setOpen(false);
                                }}
                            >
                                <TrashIcon />
                                <span className="text-sm">Xóa</span>
                            </button>
                        </div>
                    </div>
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <button>
                    <ThreeDots />
                </button>
            </Popover>
        </div>
    );
};

export default FlashCardItem;
