import './Info.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import infoTest from '~/assets/images/info-test.png';
import Left from '~/assets/svg/left.svg';

function Information() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    return (
        <div
            className="w-full h-full lg:py-20 py-10 flex gap-4 lg:flex-row flex-col items-center bg-[#fff2ec]"
            data-aos="fade-right"
            data-aos-duration="1000"
        >
            <div className="lg:w-1/2 w-3/4">
                <img className="w-full" src={infoTest} alt="" />
            </div>
            <div className="lg:w-1/2 w-3/4 lg:pl-[40px] lg:pr-[190px] text-left justify-between">
                <h1 className="font-BeVietnamSemiBold text-[#309] lg:text-[40px] text-3xl  leading-tight">{t('guide_exam')}</h1>
                <p className="flex text-justify text-[#333] text-base p-[8px] font-normal">"{t('exam_describe')}"</p>
                <button
                    onClick={() => navigate('/test/test-guide')}
                    className="static flex flex-row text-base lg:mt-8 mt-6 text-[#309] font-BeVietnamSemiBold items-center"
                >
                    <span>{t('find_out_now')}</span>
                    <img className="ml-[10px]" src={Left} alt="" />
                </button>
            </div>
        </div>
    );
}

export default Information;
