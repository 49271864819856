import React from 'react'

const RelatedWordList = ({partOfSpeech, synonymList, antonymList}) => {

    const synonymText = synonymList.join(', ');
    const antonymText = antonymList.join(', ');

    return (
        <div
                className="flex flex-col gap-2 text-base font-BeVietNamRegular font-medium text-hotlink-black-3d3d3d first-letter:uppercase"
        >
                {partOfSpeech && <div className="first-letter:uppercase font-bold text-xl">{partOfSpeech}</div>}
                <div className="flex flex-col lg:flex-row items-center lg:space-x-8">
                    {synonymList.length !== 0 && <div className="flex flex-row gap-2 text-base font-medium text-hotlink-black-3d3d3d">
                        <span className='text-hotlink-yellow-f6ae2d text-md font-medium'>Synonyms:</span>
                        {synonymText}
                    </div>}
                    {antonymList.length !== 0 && <div className="flex flex-row gap-2 text-base font-medium text-hotlink-black-3d3d3d">
                        <span className='text-hotlink-yellow-f6ae2d text-md font-medium'>Antonyms:</span>
                        {antonymText}
                    </div>}
                </div>

        </div>
    )
}

export default RelatedWordList;