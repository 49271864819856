import Slider from 'react-slick';
import React, { useRef, useState, useEffect } from 'react';

import { CustomRectangle2, RightArrowIcon, LeftArrowIcon } from '~/components/Icons';
import SingleComment from '~/components/Home/SingleComment/SingleComment';
import { listFeedbackUser } from '~/api/feedbackApi';

const Comment = () => {
    const controller = new AbortController();
    const [activeSlide, setActiveSlide] = useState(0);

    const [data, setData] = useState([]);

    const getListFeedback = async () => {
        try {
            const response = await listFeedbackUser(
                { size: '5' },
                {
                    signal: controller.signal,
                },
            );

            if (response?.status === 'OK') {
                const newData = response.responseData.map((item, i) => ({
                    key: item.feebackId,
                    avatar: item.avatar,
                    name: item.name,
                    description: item.description,
                    content: item.content,
                }));

                setData(newData);
            }
        } catch (error) { }
    };

    useEffect(() => {
        getListFeedback();

        return () => {
            controller.abort();
        };
    }, []);
    const listComment = data.map((element, index) => {
        return (
            <SingleComment key={index} choice={activeSlide === index ? '0' : '1'} item={element} />
        );
    });

    const slider = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    speed: 500,
                },
            },
        ],
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
    };

    return (
        <div className="w-full mb-10 relative" data-aos="fade-left" data-aos-duration="1000">
            <div className="lg:h-[658px] h-[1150px] bg-hotlink-orange-fff2ec lg:ml-[337px] relative">
                <CustomRectangle2 className="float-right lg:mt-[50px] mt-6" />
                <div className="flex h-full lg:w-[571px] w-full absolute lg:right-[49px] lg:items-center lg:mt-0 mt-[130px]">
                    <div className="px-5 flex flex-col justify-start items-start gap-4">
                        <span className="w-[141px] font-BeVietNamRegular lg:text-lg text-base leading-normal tracking-[0.54px] text-hotlink-black-333333">
                            MeU English
                        </span>
                        <span className="w-[324px] font-BeVietNamBold lg:text-[40px] text-3xl font-BeVietnamSemiBold leading-normal tracking-[1.2px] text-hotlink-blue-309">
                            Học viên nói gì về chúng tôi?
                        </span>
                        <span className="lg:w-[524px] w-full font-BeVietNamRegular lg:text-base text-sm leading-normal tracking-[0.48px] text-justify text-hotlink-black-4d4d4d">
                            "Hãy lắng nghe những gì học viên của chúng tôi nói về MeU English - nền tảng học trực tuyến
                            được thiết kế để nâng cao vốn từ vựng tiếng Anh và chuẩn bị cho các kỳ thi. Ý kiến phản hồi
                            của bạn là rất quan trọng với chúng tôi và giúp chúng tôi cải thiện trang web của mình để
                            phục vụ bạn tốt hơn."
                        </span>
                    </div>
                </div>

                <div className="absolute lg:left-[133px] left-64 lg:bottom-[20px] bottom-4 flex flex-row gap-2 z-[2]">
                    <button
                        onClick={() => slider?.current?.slickPrev()}
                        className="w-8 h-8 self-stretch flex justify-center items-center rounded-1/2 cursor-pointer bg-[#e6e6e6]"
                    >
                        <LeftArrowIcon />
                    </button>

                    <button
                        onClick={() => slider?.current?.slickNext()}
                        className="w-8 h-8 self-stretch flex justify-center items-center rounded-1/2 cursor-pointer bg-[#e6e6e6]"
                    >
                        <RightArrowIcon />
                    </button>
                </div>
            </div>

            <div className="lg:w-[1052px] w-full absolute lg:top-[80px] top-[600px] lg:left-[-304px] left-5">
                <Slider ref={slider} {...settings}>
                    {listComment}
                </Slider>
            </div>
        </div>
    );
};

export default Comment;
