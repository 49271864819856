// Core
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

// App
import images from '~/assets/images';

// Component
const Contact = (props) => {
    const { t } = useTranslation('common');
    const handleSubmit = (values) => {
        console.log(values);
    };

    return (
        <div
            data-aos="fade-left"
            data-aos-duration="500"
            className={`relative h-[950px] w-full font-beVietnamPro lg:h-[658px] ${
                props.page !== 'home' ? 'bg-hotlink-white-f7f3ff' : ''
            }`}
        >
            <div
                className={`relative h-full w-full bg-hotlink-white-f7f3ff`}
                // data-aos="fade-right"
                // data-aos-duration="1000"
            >
                <span className="absolute left-1/2 top-12 w-4/5 -translate-x-1/2 text-center text-3xl text-[30px] leading-normal tracking-[1.2px] text-hotlink-blue-309 first-letter:capitalize md:left-[80px] md:translate-x-0 md:text-left md:text-[35px] lg:top-[87px] lg:w-[562px] lg:text-[40px]">
                    {t('contact_us')}
                </span>

                <span className="absolute left-40 top-[170px] w-1/2 self-stretch text-justify text-sm leading-normal tracking-[0.48px] text-hotlink-black-4d4d4d lg:left-[200px] lg:w-[420px] lg:text-base">
                    "Listen to what our students have to say about MeU English - our e-learning platform designed to
                    improve English vocabulary and prepare for exams. Your feedback is important to us and helps us
                    improve our website to serve you better ."
                </span>
                <img
                    className="absolute bottom-[62%] left-2 h-1/5 object-contain lg:bottom-[-5px] lg:left-[54px] lg:h-2/3"
                    src={images.contactUs}
                    alt="Contact Us"
                ></img>
            </div>

            <Form
                onFinish={handleSubmit}
                className="absolute top-[45%] flex w-full flex-col items-stretch justify-start gap-2 rounded-[20px] bg-hotlink-purple-7158d7 px-7 py-5 lg:right-[80px] lg:top-1/2 lg:w-[550px] lg:-translate-y-1/2 lg:px-14 lg:py-10"
            >
                <div className="flex flex-col items-center justify-start gap-3 sm:mx-2">
                    <span className="text-3xl leading-normal tracking-[1.08px] text-white first-letter:capitalize lg:text-4xl">
                        {t('opportunity')}
                    </span>

                    <div className="flex justify-between gap-[24px] self-stretch">
                        <Form.Item
                            name="fullname"
                            rules={[
                                { required: true, message: t('fullname_required') },
                                {
                                    pattern: /^[A-Za-zÀ-ỹ\s]+$/,
                                    message: 'Họ và Tên không được chứa số và ký tự đặc biệt',
                                },
                            ]}
                            className="w-full"
                        >
                            <Input
                                className="font-BeVietNamRegular h-[56px] w-full rounded-[8px] bg-white px-3 py-2 text-left text-base leading-normal text-hotlink-black-000000d9"
                                placeholder={t('fullname')}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[
                                { required: true, message: t('phone_required') },
                                { pattern: /^[0-9]{10,11}$/, message: 'Vui lòng khớp với định dạng được yêu cầu.' },
                            ]}
                            className="w-full"
                        >
                            <Input
                                className="border-contact font-BeVietNamRegular h-[56px] w-full rounded-[8px] bg-white px-3 py-2 text-left text-base leading-normal text-hotlink-black-000000d9"
                                placeholder={t('phone')}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: t('email_required') },
                            { type: 'email', message: 'Định dạng Email không đúng.' },
                        ]}
                        className="w-full"
                    >
                        <Input
                            className="border-contact font-BeVietNamRegular h-[56px] w-full rounded-[8px] bg-white px-3 py-2 text-left text-base leading-normal text-hotlink-black-000000d9"
                            placeholder="Email"
                        />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        rules={[{ required: true, message: 'Vui lòng nhập vai trò của bạn.' }]}
                        className="w-full"
                    >
                        <Input
                            className="border-contact font-BeVietNamRegular h-[56px] w-full rounded-[8px] bg-white px-3 py-2 text-left text-base leading-normal text-hotlink-black-000000d9"
                            placeholder={t('what_role')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="question"
                        rules={[{ required: true, message: 'Vui lòng nhập câu hỏi của bạn.' }]}
                        className="w-full"
                    >
                        <Input
                            className="border-contact font-BeVietNamRegular h-[56px] w-full rounded-[8px] bg-white px-3 py-2 text-left text-base leading-normal text-hotlink-black-000000d9"
                            placeholder={t('need_answer')}
                        />
                    </Form.Item>
                </div>

                <Button
                    htmlType="submit"
                    className="shadow-contact h-14 w-full rounded-lg bg-hotlink-yellow-f6ae2d px-4 py-2 text-xl font-bold uppercase leading-[1.2] text-white lg:h-16"
                >
                    {t('reserve_spot')}
                </Button>
            </Form>
        </div>
    );
};

export default Contact;
