// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-dots .slick-active div {
  background-color: #7158d7;
  width: 24px;
}

.slick-dots .slick-active {
  transition: width 0.5s ease-in-out;
}

.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/News/HotNews/HotNews.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,kCAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ","sourcesContent":[".slick-dots .slick-active div{\n    // animation: loading 5s ease-in;\n    background-color: #7158d7;\n    width: 24px;\n}\n\n.slick-dots .slick-active {\n    transition: width 0.5s ease-in-out;\n  }\n\n.hidden{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
