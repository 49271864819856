//Core
import React, { useState } from 'react';
import { message, Upload, Button, Input } from "antd";
import { PlusOutlined, EyeOutlined, DeleteOutlined, CloseOutlined, ImportOutlined } from '@ant-design/icons';
import './UploadPartImage.scss';
//App
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';

//Internal
import { uploadImage } from '../../../../../../../api/uploadApi/uploadApi';

//Component
const UploadPartImage = ({ topicId }) => {
    //State
    const axiosPrivate = useAxiosPrivate();
    const [partImages, setPartImages] = useState([]);
    const [tempPartImage, setTempPartImage] = useState(null);

    //Method
    const beforeUploadFile = (file, allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']) => {
        const isAllowedType = allowedTypes.includes(file.type);
        if (!isAllowedType) {
            message.error('Chỉ được upload file hình ảnh');
        }
        return isAllowedType;
    };

    const convertImageToPng = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const newFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".png"), {
                                type: "image/png",
                                lastModified: Date.now()
                            });
                            resolve(newFile);
                        } else {
                            reject(new Error("Image conversion failed."));
                        }
                    }, "image/png");
                };
                img.onerror = () => reject(new Error("Image loading failed."));
                img.src = e.target.result;
            };
            reader.onerror = () => reject(new Error("File reading failed."));
            reader.readAsDataURL(file);
        });
    };

    const handlePartImageChange = async (file, index) => {
        try {
            const pngFile = await convertImageToPng(file.originFileObj);
            const tempUrl = URL.createObjectURL(pngFile);

            setPartImages(prev => {
                const updatedParts = [...prev];
                updatedParts[index].url = tempUrl;
                return updatedParts;
            });

            setTempPartImage(pngFile);
            return false;
        } catch (error) {
            console.error('Lỗi khi chuyển đổi ảnh:', error);
            message.error('Không thể chuyển đổi ảnh sang PNG');
        }
    };

    const handleUploadPartImage = async (index) => {
        if (!topicId) {
            message.warning('Topic ID chưa được cập nhật!');
            return;
        }
        if (!partImages[index]?.code) {
            message.warning('Vui lòng nhập mã code cho phần này!');
            return;
        }

        if (!tempPartImage) {
            message.warning('Vui lòng chọn ảnh để upload!');
            return;
        }

        try {
            const response = await uploadImage(
                axiosPrivate,
                tempPartImage,
                '/',
                false,
                topicId,
                partImages[index].code
            );

            const imageLink = response.responseData;
            setPartImages(prev => {
                const updatedParts = [...prev];
                updatedParts[index] = {
                    ...updatedParts[index],
                    url: imageLink,
                    uploaded: true
                };
                return updatedParts;
            });

            message.success('Upload ảnh thành công!');
            setTempPartImage(null);
        } catch (error) {
            message.error('Upload ảnh thất bại');
        }
    };

    const addPartImageField = () => {
        setPartImages(prev => [...prev, { url: null, code: '', uploaded: false }]);
    };

    const handleDeletePartImage = (index) => {
        setPartImages(prev => {
            const updatedParts = [...prev];
            // Xóa phần tử ở vị trí 'index'
            updatedParts.splice(index, 1);
            return updatedParts;
        });
    };

    const handleDeletePartImageTemp = (index) => {
        setPartImages(prev => {
            const updatedParts = [...prev];
            updatedParts[index].url = null;
            return updatedParts;
        });
    };

    return (
        <div className="flex flex-col space-y-2 ml-6 mt-8">
            <h3 className="text-Black-600 font-BeVietNamBold font-bold mb-2">
                Bước 3: Upload ảnh cho từng part:
            </h3>
            <p className="text-gray-600 mb-4">
                Vui lòng upload ảnh cho từng part tương ứng. Bạn có thể thêm nhiều ảnh nếu cần.
            </p>
            <div className="grid grid-cols-4 gap-4 mt-4 overflow-hidden">
                {partImages.map((partImage, index) => (
                    <div className="flex flex-col items-start border p-4 rounded-md relative w-full" key={index}>
                        {/* Nút xóa khung */}
                        <Button
                            icon={<CloseOutlined style={{ fontSize: '12px' }} />}
                            shape="circle"
                            className="absolute top-0 right-0"
                            onClick={() => handleDeletePartImage(index)}
                            style={{
                                border: 'none', 
                                color: '#ff4d4f',
                            }}
                        />
                        <Upload
                            className='w-full mt-3'
                            listType="picture-card"
                            onChange={({ file }) => handlePartImageChange(file, index)}
                            beforeUpload={(file) => beforeUploadFile(file, ['image/png', 'image/jpeg'])}
                            showUploadList={false}
                            style={{ width: '100%' }}
                        >
                            {partImage.url ? (
                                <div className="relative">
                                    <img src={partImage.url} alt="Part Image" style={{ width: '100%', height: 'auto' }} />
                                    <div className="absolute top-0 right-0 flex items-center justify-center w-full h-full opacity-0 hover:opacity-100 transition-opacity">
                                        <Button icon={<EyeOutlined />} className="bg-transparent border-none" onClick={(e) => { e.stopPropagation(); window.open(partImage.url, '_blank'); }} />
                                        <Button icon={<DeleteOutlined />} className="bg-transparent border-none" onClick={(e) => { e.stopPropagation(); handleDeletePartImageTemp(index); }} />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <PlusOutlined />
                                </div>
                            )}
                        </Upload>
                        <Input
                            className="mt-2 w-full"
                            placeholder="Mã code"
                            value={partImage.code}
                            onChange={(e) => {
                                const value = e.target.value;
                                const isValid = /^[a-zA-Z0-9]*$/.test(value);
                                if (isValid) {
                                    setPartImages(prev => {
                                        const updatedParts = [...prev];
                                        updatedParts[index].code = value;
                                        return updatedParts;
                                    });
                                }
                            }}
                        />
                        {!partImage.uploaded && (
                            <Button onClick={() => handleUploadPartImage(index)} className="mt-2 w-full btn bg-blue-500 text-white rounded">
                                Upload
                            </Button>
                        )}
                    </div>
                ))}
            </div>
            <div className="flex justify-center mt-4">
                <Button onClick={addPartImageField} type="dashed" className="btn bg-blue-500 text-white rounded w-64">
                    Add Image
                </Button>
            </div>
        </div>
    );
}

export default UploadPartImage;


