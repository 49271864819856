import { Radio, Space } from 'antd';
import React, { useEffect, useState, useContext } from 'react';

import Flag from '~/assets/svg/Flag.svg';
import FlagChoice from '~/assets/svg/FlagChoice.svg';

import { useSelector, useDispatch } from 'react-redux';
import { setListChoice, setListFlag } from '~/redux/topicSlice';

function ToeicAnswerRadio(props) {
    const dispatch = useDispatch();
    const listChoice = useSelector((state) => state.topic.listChoice);
    const listFlag = useSelector((state) => state.topic.listFlag);

    const [value, setValue] = useState();

    const [flag, setFlag] = useState();

    const onChange = (e) => {
        const selectedAnswerId = e.target.value;

        setValue(selectedAnswerId);

        const updatedListChoice = listChoice.map((choice) => ({ ...choice }));

        const existingChoiceIndex = updatedListChoice.findIndex(
            (item) => item.questionId === props.questionRadio.questionId,
        );

        if (existingChoiceIndex !== -1) {
            updatedListChoice[existingChoiceIndex].answerId = selectedAnswerId;
        } else {
            updatedListChoice.push({
                questionId: props.questionRadio.questionId,
                answerId: selectedAnswerId,
            });
        }

        dispatch(setListChoice(updatedListChoice));
    };

    const onChangeFlag = () => {
        setFlag(!flag);
        const updatedListFlag = [...listFlag];

        if (!flag) {
            updatedListFlag.push(props.questionRadio.questionId);
        } else {
            const indexToRemove = updatedListFlag.indexOf(props.questionRadio.questionId);
            if (indexToRemove !== -1) {
                updatedListFlag.splice(indexToRemove, 1);
            }
        }

        dispatch(setListFlag(updatedListFlag));
    };

    useEffect(() => {
        setFlag(listFlag.includes(props.questionRadio.questionId));
        setValue(listChoice.find((item) => item.questionId === props.questionRadio.questionId)?.answerId || 0);
    }, [props.questionRadio.questionId]);

    return (
        <div className="flex flex-col gap-4 p-4 items-start">
            <div
                className={`flex flex-row w-full justify-between gap-4 items-center`}
            >
                <span className="font-BeVietnamMedium text-base text-hotlink-black-262626">
                    Câu {props.numberical}:{' '}
                    {props.questionRadio && !props.ofGroup ? props.questionRadio.questionContent : ''}
                </span>
                <button className="" onClick={onChangeFlag}>
                    <img src={flag ? FlagChoice : Flag} alt="flag"></img>
                </button>
            </div>

            <Radio.Group className="" onChange={onChange} value={value}>
                <Space direction="vertical">
                    {Array.isArray(props.questionRadio.listAnswer)
                        ? props.questionRadio.listAnswer.map((option) => (
                              <Radio
                                  key={option.answerId}
                                  value={option.answerId}
                                  className="font-BeVietnamRegular text-base text-hotlink-black-085"
                              >
                                  {option.answerContent}
                              </Radio>
                          ))
                        : ''}
                </Space>
            </Radio.Group>
        </div>
    );
}

export default ToeicAnswerRadio;
