// Core
import { RouterProvider } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// App
import routers from './routes';
import { queryClient } from './config';
import { initTokenConfig } from './constants/antd';
import { ConfigProvider } from 'antd';

// Component
function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider
                theme={{
                    token: initTokenConfig,
                }}
            >
                <RouterProvider router={routers} />
            </ConfigProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default withTranslation('common')(App);
