// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-1adbn6x).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 110px;
  margin-inline-end: 0 !important;
  margin-bottom: 0 !important;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Exam/AddExam/AddExamModal/components/UploadPartImage/UploadPartImage.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,aAAA;EACA,+BAAA;EACA,2BAAA;EACA,kBAAA;EACA,mBAAA;EACA,qCAAA;EACA,0BAAA;EACA,kBAAA;EACA,eAAA;EACA,6BAAA;AACJ","sourcesContent":[":where(.css-dev-only-do-not-override-1adbn6x).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {\r\n    width: 100% !important;\r\n    height: 110px;\r\n    margin-inline-end: 0 !important;\r\n    margin-bottom: 0 !important;\r\n    text-align: center;\r\n    vertical-align: top;\r\n    background-color: rgba(0, 0, 0, 0.02);\r\n    border: 1px dashed #d9d9d9;\r\n    border-radius: 8px;\r\n    cursor: pointer;\r\n    transition: border-color 0.3s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
