// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-post-button {
  background: #a370f0;
  color: white;
  font-family: var(--font-be-vietnam);
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.create-post-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
}

.create-post-button:hover .icon {
  width: calc(100% - 0.6em);
}

.create-post-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.create-post-button:hover .icon svg {
  transform: translateX(0.1em);
}

.create-post-button:active .icon {
  transform: scale(0.95);
}`, "",{"version":3,"sources":["webpack://./src/components/ForumLayout/Post/CreatePost/CreatePost.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,mCAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,0CAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,oBAAA;EACA,2CAAA;EACA,YAAA;EACA,oBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,YAAA;EACA,0BAAA;EACA,cAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":[".create-post-button {\n    background: #a370f0;\n    color: white;\n    font-family: var(--font-be-vietnam);\n    padding: 0.35em;\n    padding-left: 1.2em;\n    font-size: 16px;\n    font-weight: 500;\n    border-radius: 0.9em;\n    border: none;\n    letter-spacing: 0.05em;\n    display: flex;\n    align-items: center;\n    box-shadow: inset 0 0 1.6em -0.6em #714da6;\n    overflow: hidden;\n    position: relative;\n    height: 2.8em;\n    padding-right: 3.3em;\n    cursor: pointer;\n}\n\n.create-post-button .icon {\n    background: white;\n    margin-left: 1em;\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 2.2em;\n    width: 2.2em;\n    border-radius: 0.7em;\n    box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;\n    right: 0.3em;\n    transition: all 0.3s;\n}\n\n.create-post-button:hover .icon {\n    width: calc(100% - 0.6em);\n}\n\n.create-post-button .icon svg {\n    width: 1.1em;\n    transition: transform 0.3s;\n    color: #7b52b9;\n}\n\n.create-post-button:hover .icon svg {\n    transform: translateX(0.1em);\n}\n\n.create-post-button:active .icon {\n    transform: scale(0.95);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
