
function ButtonCustom({ content, onClick, className}) {
    return (
        <button
            onClick={onClick}
            className={`${className} bg-hotlink-yellow-FBCA1F font-beVietnamPro font-semibold py-[0.6em] px-[1.3em] font-black text-base border-[3px] border-solid border-black rounded-[0.4em] shadow-[0.1em_0.1em]
                         hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                         active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]`}
        >
            {content}
        </button>
    );
}

export default ButtonCustom;
