import React from 'react';
import LatestNews from '../../../components/News/LatestNews';
import { ChevronRight } from '~/components/Icons';
import { useNavigate } from 'react-router-dom';
import config from '~/config';

export default function LatestNewsPage() {
    const navigate = useNavigate();
    const mockNews = [
        {
            id: '1',
            name: 'Tin tức thể thao mới nhất',
            slug: 'tin-tuc-the-thao-moi-nhat',
            image: '',
            description: 'Cập nhật những tin tức thể thao mới nhất trong ngày',
            status: 1,
            order: 1,
            created_at: '2024-03-20',
            updated_at: '2024-03-20'
        },
        {
            id: '2',
            name: 'Giải đấu sắp diễn ra',
            slug: 'giai-dau-sap-dien-ra',
            image: '',
            description: 'Thông tin về các giải đấu quan trọng sắp diễn ra',
            status: 1,
            order: 2,
            created_at: '2024-03-19',
            updated_at: '2024-03-19'
        },
        {
            id: '3',
            name: 'Kết quả thi đấu',
            slug: 'ket-qua-thi-dau',
            image: '',
            description: 'Tổng hợp kết quả các trận đấu mới nhất',
            status: 1,
            order: 3,
            created_at: '2024-03-18',
            updated_at: '2024-03-18'
        }, 
        {
            id: '4',
            name: 'Kết quả thi đấu',
            slug: 'ket-qua-thi-dau',
            image: '',
            description: 'Tổng hợp kết quả các trận đấu mới nhất',
            status: 1,
            order: 3,
            created_at: '2024-03-18',
            updated_at: '2024-03-18'
        }
        
    ];

    return (
        <div className="w-full flex flex-col justify-center items-center lg:gap-8 gap-6 bg-hotlink-purple-f7f3ff lg:px-20 px-6 py-10 relative">
            <LatestNews news={mockNews} />
            <button className="flex flex-row mt-[32px] justify-center items-center gap-3 py-2 px-4 border-solid border border-hotlink-blue-4d27b3 rounded-xl bg-hotlink-blue-4d27b3"
                onClick={() => navigate('/all-news')}>
                <span className="font-BeVietNamMedium text-white text-base leading-normal">Xem tất cả</span>
                <ChevronRight />
            </button>
        </div>

    );
}
