import SunEditor from 'suneditor-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CloseIcon } from '~/components/Icons';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';

import { createPost } from '~/api/postApi';

function PostModal(props) {
    const dispatch = useDispatch();

    const axiosPrivate = useAxiosPrivate();

    const [value, setValue] = useState('');

    const [contentPost, setContentPost] = useState({
        content: '',
        err: {},
    });

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [contentPost]);

    function validate() {
        if (contentPost.content === '<p><br></p>' || contentPost.content === '') {
            dispatch(setStatusMessage('Post fail'));
            dispatch(setErrMessage('Please enter content'));
            setContentPost((prevContentPost) => ({
                ...prevContentPost,
                err: {
                    ...prevContentPost.err,
                    content: `Please enter content`,
                },
            }));
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                dispatch(startLoading());
                const response = await createPost(
                    axiosPrivate,
                    JSON.stringify({
                        content: contentPost.content,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    },
                );

                if (response?.violations === 401) {
                    dispatch(setStatusMessage('Post fail'));
                    dispatch(setErrMessage('Unauthorized'));
                } else if (response?.status === 'fail') {
                    dispatch(setStatusMessage('Post fail'));
                    dispatch(setErrMessage(response?.message));
                } else {
                    props.setListPostUser((prevList) => [response.responseData, ...prevList]);
                    dispatch(setStatusMessage('Post successful'));
                    dispatch(setErrMessage('Successful'));
                    props.closeModal();
                }
            } catch (err) {
                dispatch(setStatusMessage('Post fail'));
                dispatch(setErrMessage('Post Failed'));
            } finally {
                dispatch(stopLoading());
            }
        }
    };

    return (
        <div className="fixed z-[100] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center">
            <div className="lg:w-1/2 w-4/5 h-4/5 flex flex-col bg-white p-8 gap-8 rounded-xl overflow-y-auto">
                <header className="flex flex-row justify-between items-center">
                    <span className="text-xl font-BeVietNamMedium text-hotlink-black-161616 after:max-w-full after:w-1/2 after:h-[3px] after:block after:bg-hotlink-red-ff4d4f">
                        Tạo bài viết mới
                    </span>
                    <button
                        onClick={() => {
                            props.closeModal();
                        }}
                    >
                        <CloseIcon className="cursor-pointer" />
                    </button>
                </header>

                <form className="flex flex-col gap-8" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-col items-start justify-start gap-2">
                        <span className="font-BeVietNamRegular text-base font-bold">Nội dung (*)</span>
                        <SunEditor
                            height="300px"
                            setContents={value}
                            onChange={(e) => {
                                setContentPost((prevContentPost) => ({
                                    ...prevContentPost,
                                    content: e,
                                    err: {
                                        ...prevContentPost.err,
                                        content: e === '<p><br></p>' ? 'Please enter your content' : null,
                                    },
                                }));
                                setValue(e);
                            }}
                            setOptions={{
                                buttonList: [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    [
                                        'bold',
                                        'underline',
                                        'italic',
                                        'strike',
                                        'subscript',
                                        'superscript',
                                        'removeFormat',
                                    ],
                                    ['fontColor', 'hiliteColor', 'list'],
                                    ['link', 'image', 'fullScreen'],
                                ],
                            }}
                        />
                        {contentPost.err.content && (
                            <div className="text-red-500 text-sm ml-8">{contentPost.err.content}</div>
                        )}
                    </div>

                    <div className="flex justify-end items-center">
                        <button className="create-post-button">
                            Đăng bài
                            <div className="icon">
                                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default PostModal;
