import { useState, useEffect } from 'react';
import { Popover, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

import Avatar from '~/components/Avatar';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { getAllCommentChildToComment, updateComment } from '~/api/commentApi';
import AddComment from '../AddComment';
import DeleteModal from '../DeleteModal';

import { ThreeDots, TrashIcon, EditIcon } from '~/components/Icons';

const BASE_URL = process.env.REACT_APP_API_URL;
var stompClient = null;

const { TextArea } = Input;

function TopicCommentItem(props) {
    const controller = new AbortController();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const isMobile = window.innerWidth <= 767;

    const user = useSelector((state) => state.user.user);

    const [openAddComment, setOpenAddComment] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openChildComment, setOpenChildComment] = useState(false);
    const [listCommentChild, setListCommentChild] = useState([]);
    const [value, setValue] = useState(props.comment.contentComment);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const getListCommentChild = async () => {
        if (props.comment.commentParent) {
            try {
                const response = await getAllCommentChildToComment(props.comment.commentId, null, {
                    signal: controller.signal,
                });

                if (response.status === 'success') {
                    setListCommentChild(response.responseData);
                }
            } catch (err) {}
        }
    };

    useEffect(() => {
        const fetchDataAndSubscribe = async () => {
            await getListCommentChild();

            const socket = new SockJS(BASE_URL + '/ws');
            stompClient = Stomp.over(socket);
            stompClient.debug = () => {};
            try {
                stompClient.connect(
                    {},
                    () => {
                        stompClient.subscribe(`/comment/commentParent/${props.comment.commentId}`, (message) => {
                            const newComment = JSON.parse(message.body);
                            setListCommentChild((prevComments) => [newComment, ...prevComments]);
                        });
                        stompClient.subscribe(`/comment/updateComment/${props.comment.commentId}`, (message) => {
                            const updateComment = JSON.parse(message.body);
                            props.setListComment((prevComments) => {
                                return prevComments.map((comment) => {
                                    if (comment.commentId === updateComment.commentId) {
                                        return updateComment;
                                    } else {
                                        return comment;
                                    }
                                });
                            });
                        });
                        stompClient.subscribe(`/comment/deleteComment/${props.comment.commentId}`, (message) => {
                            const deleteCommentId = JSON.parse(message.body);
                            props.setListComment(
                                props.listComment.filter((comment) => comment.commentId !== deleteCommentId),
                            );
                        });
                    },
                    (err) => {},
                );
            } catch (error) {
                // console.error("Error connecting to STOMP:", error);
            }
        };

        fetchDataAndSubscribe();

        return () => {
            if (stompClient && stompClient.connected) {
                stompClient.disconnect();
            }
            controller.abort();
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let response = undefined;
        if (value !== '') {
            try {
                dispatch(startLoading());
                response = await updateComment(
                    axiosPrivate,
                    props.comment.commentId,
                    JSON.stringify({
                        commentContent: value,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    },
                );

                if (response?.status === 'success') {
                    dispatch(setStatusMessage('Update Comment successful'));
                    dispatch(setErrMessage('Successful'));
                    setOpenEdit(false);
                }
            } catch (err) {
            } finally {
                dispatch(stopLoading());
            }
        } else {
            dispatch(setStatusMessage('Please enter comment'));
            dispatch(setErrMessage('Update fail'));
        }
    };

    return (
        <div className={`w-full flex flex-col gap-3 ${!props.commentChild ? 'lg:px-4 px-2' : null}`}>
            {openDeleteModal ? (
                <DeleteModal
                    commentId={props.comment.commentId}
                    closeModal={() => {
                        setOpenDeleteModal(false);
                    }}
                />
            ) : null}
            <div className="w-full flex flex-row gap-3 items-start">
                <Avatar
                    className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba h-8 w-8 min-w-[32px]"
                    src={props.comment.avatar ? props.comment.avatar : ''}
                    alt="user"
                />
                <div className="w-[95%] flex flex-col gap-2">
                    <div className="w-full flex flex-col gap-1 bg-hotlink-gray-f6f6f6 px-3 py-2 rounded-xl">
                        <div className="w-full flex flex-row justify-between">
                            <div className="flex flex-row gap-4 justify-between items-center">
                                <span className="font-BeVietnamBold text-sm font-bold">{props.comment.userName}</span>
                                {!isMobile ? (
                                    <span className="text-hotlink-gray-5b5b5b font-BeVietnamSemiBold text-xs">
                                        ({props.comment.updateAt})
                                    </span>
                                ) : null}
                            </div>
                            {user && user.User.userId && user.User.userId === props.comment.userId ? (
                                <Popover
                                    content={
                                        <div className="flex flex-col gap-4 px-4 py-2">
                                            <button
                                                className="flex flex-row gap-2 justify-start items-center"
                                                onClick={() => {
                                                    setOpenEdit(true);
                                                    setOpen(false);
                                                }}
                                            >
                                                <EditIcon />
                                                <span className="font-BeVietnamSemiBold text-sm">Chỉnh sửa</span>
                                            </button>

                                            <button
                                                className="flex flex-row gap-2 justify-start items-center"
                                                onClick={() => {
                                                    setOpenDeleteModal(true);
                                                    setOpen(false);
                                                }}
                                            >
                                                <TrashIcon />
                                                <span className="font-BeVietnamSemiBold text-sm">Xóa</span>
                                            </button>
                                        </div>
                                    }
                                    trigger="click"
                                    open={open}
                                    onOpenChange={handleOpenChange}
                                >
                                    <button>
                                        <ThreeDots />
                                    </button>
                                </Popover>
                            ) : null}
                        </div>

                        {!openEdit ? (
                            <span className="w-full pt-2 break-words">{props.comment.contentComment}</span>
                        ) : (
                            <form
                                className="flex flex-col gap-1 bg-hotlink-gray-f6f6f6 px-3 py-2 rounded-xl items-end"
                                onSubmit={(e) => handleSubmit(e)}
                            >
                                <TextArea
                                    rows={2}
                                    placeholder="Enter your comment"
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                                <div className="flex flex-row gap-3">
                                    <button
                                        className="w-24 text-sm bg-hotlink-purple-714da6 font-BeVietnamSemiBold py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                         hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                         active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                                        type="submit"
                                    >
                                        Bình luận
                                    </button>

                                    <button
                                        className="w-16 text-sm bg-hotlink-red-d73d32 font-BeVietnamSemiBold py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                         hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                         active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                                        type="button"
                                        onClick={() => {
                                            setValue(props.comment.contentComment);
                                            setOpenEdit(false);
                                        }}
                                    >
                                        Hủy
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                    <div className="flex flex-row gap-5">
                        {listCommentChild.length !== 0 ? (
                            <span
                                className="pl-3 cursor-pointer font-BeVietnamSemiBold"
                                onClick={() => {
                                    setOpenChildComment(!openChildComment);
                                }}
                            >
                                Xem thêm <span>({listCommentChild.length})</span>
                            </span>
                        ) : null}
                        <span
                            className="pl-3 cursor-pointer font-BeVietnamSemiBold"
                            onClick={() => {
                                setOpenAddComment(!openAddComment);
                            }}
                        >
                            Phản hồi
                        </span>
                    </div>
                </div>
            </div>
            {openChildComment ? (
                <div className="ml-6 flex flex-col gap-3">
                    {listCommentChild
                        ? listCommentChild.map((comment) => (
                              <TopicCommentItem
                                  key={comment.commentId}
                                  comment={comment}
                                  listComment={listCommentChild}
                                  setListComment={setListCommentChild}
                                  commentChild={true}
                              />
                          ))
                        : null}
                </div>
            ) : null}

            {openAddComment ? (
                <div className="ml-4">
                    <AddComment commentId={props.comment.commentId} closeAddComment={() => setOpenAddComment(false)} />
                </div>
            ) : null}
        </div>
    );
}
export default TopicCommentItem;
