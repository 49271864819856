// Core
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// Component
const Agreement = ({ dictionaryList }) => {
    // Hooks
    const navigate = useNavigate();

    const synonymList = useMemo(
        () => dictionaryList.flatMap((entry) => entry.meanings.flatMap((meaning) => meaning.synonyms)),
        [dictionaryList],
    );

    const antonymList = useMemo(
        () => dictionaryList.flatMap((entry) => entry.meanings.flatMap((meaning) => meaning.antonyms)),
        [dictionaryList],
    );

    return (
        <div className="flex flex-col justify-start items-start px-4 space-y-8">
            {synonymList.length !== 0 && (
                <div className="flex">
                    <h3 className="mr-4 text-hotlink-yellow-f6ae2d text-xl font-semibold">Synonyms: </h3>
                    <div className="flex-1 flex flex-wrap gap-4">
                        {synonymList.map((synonymItem, index) => (
                            <button
                                key={index}
                                className="lg:text-xl text-sm text-hotlink-gray-5b5b5b leading-[1.57] transition-colors duration-200 hover:text-hotlink-yellow-f6ae2d cursor-pointer"
                                onClick={() => navigate(`/dictionary?search=${synonymItem}`)}
                            >
                                {synonymItem}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {antonymList.length !== 0 && (
                <div className="flex">
                    <h3 className="mr-4 text-hotlink-yellow-f6ae2d text-xl font-semibold">Antonyms: </h3>
                    <div className="flex-1 flex flex-wrap gap-4">
                        {antonymList.map((antonymItem, index) => (
                            <button
                                key={index}
                                className="lg:text-xl text-sm text-hotlink-gray-5b5b5b leading-[1.57] transition-colors duration-200 hover:text-hotlink-yellow-f6ae2d cursor-pointer"
                                onClick={() => navigate(`/dictionary?search=${antonymItem}`)}
                            >
                                {antonymItem}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Agreement;
