import './ToeicTestItem.scss'
import LeftArrow from '~/assets/svg/left-arrow.svg';

function ToeicTestItem(props) {
    const { t, test, icon } = props;

    return (
        <div className={`w-[49%] rounded-xl my-4 bg-white box-shadow flex items-center`}>
            <div>
                <img src={icon} alt='' />
            </div>
            <div className='px-4 py-[22px]'>
                <div className='flex justify-between'>
                    <p className='text-xs'>{test.quantity} CÂU HỎI</p>
                    <span className='px-2 py-px text-xs rounded-sm bg-[#f6ae2d]'>{test.time} Phút</span>
                </div>
                <span className='flex text-left text-lg font-BeVietnamMedium'>
                    {test.title}
                </span>
                <span className='flex text-left text-base text-[#da1e37]'>
                    {t('make_a_test')}
                    <img className='ml-2.5' src={LeftArrow} alt='' />
                </span>
            </div>
        </div>
    )
}

export default ToeicTestItem