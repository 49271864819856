import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const allNews = [
    {
        id: '1',
        category_id: 2,
        name: 'Tin tức thể thao mới nhất',
        slug: 'tin-tuc-the-thao-moi-nhat',
        image: '',
        description: 'Cập nhật những tin tức thể thao mới nhất trong ngày',
        content: `
            <p>Thể thao luôn là một phần không thể thiếu trong cuộc sống hàng ngày của chúng ta. Với sự phát triển của công nghệ, những tin tức mới nhất về các môn thể thao từ bóng đá, bóng rổ đến các môn thể thao điện tử đều có thể được cập nhật chỉ trong vài phút.</p>
            <p>Ngày hôm nay, chúng ta sẽ điểm qua những sự kiện nổi bật như giải đấu Champions League với các trận đấu nảy lửa, hay những thông tin mới nhất về phong độ của các vận động viên nổi tiếng.</p>
            <p>Hãy theo dõi bài viết để không bỏ lỡ bất kỳ thông tin hấp dẫn nào về thể thao trong và ngoài nước.</p>
        `,
        shortContent: 'Tóm tắt ngắn về bài viết thể thao...',
        status: 1,
        order: 1,
        created_at: '2024-03-20',
        updated_at: '2024-03-20',
    },
    {
        id: '2',
        category_id: 3,
        name: 'Giải đấu sắp diễn ra',
        slug: 'giai-dau-sap-dien-ra',
        image: '',
        description: 'Thông tin về các giải đấu quan trọng sắp diễn ra',
        content: `
            <p>Tháng này hứa hẹn sẽ mang đến những sự kiện thể thao đầy kịch tính với hàng loạt giải đấu lớn. Từ giải bóng đá quốc tế đến các giải đấu trong nước, người hâm mộ sẽ được chứng kiến những màn trình diễn mãn nhãn.</p>
            <p>Trong số đó, giải vô địch quốc gia sẽ là nơi các câu lạc bộ hàng đầu cạnh tranh để giành chiếc cúp danh giá. Ngoài ra, giải đấu eSports cũng đang thu hút sự quan tâm với giải thưởng lên đến hàng triệu đô la.</p>
            <p>Hãy sẵn sàng cho một mùa giải đầy phấn khích và đừng quên theo dõi lịch thi đấu để không bỏ lỡ những khoảnh khắc đỉnh cao.</p>
        `,
        shortContent: 'Tóm tắt ngắn về các giải đấu...',
        status: 1,
        order: 2,
        created_at: '2024-03-19',
        updated_at: '2024-03-19',
    },
];

const recentNews = [
    {
        id: '3',
        name: 'Kết quả bóng đá Champions League mới nhất',
        image: '/api/placeholder/100/100',
        created_at: '2024-03-21',
    },
    {
        id: '4',
        name: 'Top 10 cầu thủ xuất sắc nhất tháng',
        image: '/api/placeholder/100/100',
        created_at: '2024-03-20',
    },
    {
        id: '5',
        name: 'Lịch thi đấu Ngoại hạng Anh tuần này',
        image: '/api/placeholder/100/100',
        created_at: '2024-03-19',
    },
    {
        id: '6',
        name: 'Chuyển nhượng bóng đá mới nhất',
        image: '/api/placeholder/100/100',
        created_at: '2024-03-18',
    }
];

export default function NewsDetail() {
    const navigate = useNavigate();
    const { id } = useParams();

    if (!id) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    const newsDetail = allNews.find(news => news.id === id);

    if (!newsDetail) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <h2 className="text-2xl font-medium text-gray-700">Không tìm thấy bài viết</h2>
                <button
                    onClick={() => navigate('/all-news')}
                    className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                    Quay lại trang tin tức
                </button>
            </div>
        );
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('vi-VN', {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).format(date);
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <div className="container mx-auto px-4">
                <div className="max-w-7xl mx-auto mt-5">
                    <div className="mb-6">
                        <button
                            onClick={() => navigate('/all-news')}
                            className="inline-flex items-center text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors"
                        >
                            <svg
                                className="w-4 h-4 mr-2"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                />
                            </svg>
                            Quay lại danh sách tin tức
                        </button>
                    </div>

                    <div className="flex flex-col lg:flex-row gap-8">
                        <div className="lg:w-2/3 flex flex-col">
                            <article className="bg-white rounded-xl shadow-sm overflow-hidden flex-1">
                                {newsDetail.image && (
                                    <div className="relative w-full h-96">
                                        <img
                                            src={newsDetail.image}
                                            alt={newsDetail.name}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                )}

                                <div className="p-6 md:p-8 h-full">
                                    <div className="flex flex-wrap items-center justify-between gap-4 mb-6 text-sm">
                                        <div className="flex flex-wrap gap-4 text-gray-500">
                                            <div className="flex items-center">
                                                <span>{formatDate(newsDetail.created_at)}</span>
                                            </div>
                                            <div className="flex items-center">
                                                <span className="text-gray-400">Cập nhật: {formatDate(newsDetail.updated_at)}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 className="text-2xl md:text-3xl font-BeVietnamMedium text-gray-900 mb-4 leading-tight">
                                        {newsDetail.name}
                                    </h1>
                                    <div className="text-lg text-gray-600 mb-8 font-BeVietnamRegular leading-relaxed">
                                        {newsDetail.description}
                                    </div>

                                    <div className="prose prose-lg max-w-none">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: newsDetail.content }}
                                            className="font-BeVietnamRegular text-gray-800 leading-relaxed text-justify"
                                        />
                                    </div>
                                </div>
                            </article>
                        </div>

                        <div className="lg:w-1/3 flex flex-col gap-6">
                            <div className="bg-white rounded-xl shadow-sm p-6 flex-1">
                                <h2 className="text-xl font-BeVietnamMedium text-gray-900 mb-6">
                                    Tin tức mới nhất
                                </h2>
                                <div className="space-y-6">
                                    {recentNews.map((news) => (
                                        <div
                                            key={news.id}
                                            className="flex items-start space-x-4 group cursor-pointer"
                                            onClick={() => navigate(`/news-detail/${news.id}`)}
                                        >
                                            <div className="flex-1 min-w-0">
                                                <h3 className="text-base font-medium text-gray-900 group-hover:text-blue-600 transition-colors line-clamp-2">
                                                    {news.name}
                                                </h3>
                                                <p className="mt-2 text-sm text-gray-500">
                                                    {formatDate(news.created_at)}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="bg-white rounded-xl shadow-sm p-6">
                                <h2 className="text-xl font-BeVietnamMedium text-gray-900 mb-4">
                                    Tags phổ biến
                                </h2>
                                <div className="flex flex-wrap gap-2">
                                    {['Thể thao', 'Bóng đá', 'Champions League', 'Ngoại hạng Anh', 'La Liga', 'Chuyển nhượng'].map((tag, index) => {
                                        const colors = [
                                            'bg-red-100 text-red-800',
                                            'bg-blue-100 text-blue-800',
                                            'bg-green-100 text-green-800',
                                            'bg-yellow-100 text-yellow-800',
                                            'bg-purple-100 text-purple-800',
                                            'bg-pink-100 text-pink-800',
                                        ];
                                        const colorClass = colors[index % colors.length]; // Lặp lại màu nếu số tag > số màu

                                        return (
                                            <span
                                                key={tag}
                                                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${colorClass} hover:opacity-80 cursor-pointer transition-colors`}
                                            >
                                                {tag}
                                            </span>
                                        );
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}