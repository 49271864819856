function CompetitionsItem(props) {
    return (
        <div className="pb-8 h-full">
            <div className="h-full flex flex-col gap-6 justify-start items-stretch rounded-2xl shadow-[0_8px_24px_0_rgba(0,0,0,0.1)] border border-solid border-hotlink-white-fafaff bg-white grow pb-6">
                <img src={props.picture} alt="hot-new" className="rounded-t-2xl" />
                <div className="flex flex-col justify-start items-stretch gap-2 px-6">
                    <div className="flex flex-row self-stretch justify-between items-start leading-loose">
                        <span className="font-BeVietNamRegular lg:text-xs text-[10px] text-hotlink-black-085">
                            CÁC CUỘC THI
                        </span>
                        <span className="font-BeVietNamRegular lg:text-xs text-[10px] text-hotlink-black-085">
                            {props.day}
                        </span>
                    </div>
                    <span className="font-BeVietNamMedium lg:text-xl text-lg leading-tight text-hotlink-blue-309">
                        {props.title}
                    </span>
                    <span className="font-BeVietNamRegular lg:text-[13px] text-[10px] text-justify">
                        {props.description}
                    </span>
                </div>
            </div>
        </div>
    );
}
export default CompetitionsItem;
