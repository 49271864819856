import React, { useState, useEffect } from 'react';
// import { ChevronDown, XmarkIcon, CloseIcon } from '~/components/Icons';

import { message, Steps } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';

import { format } from 'date-fns';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import DescriptionExam from './DescriptionExam';

import { listPartToTopic, listQuestionToPart, updateTopic } from '~/api/topicApi';
import ButtonCustom from '~/components/Button/ButtonCustom';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';

import QuestionPart1 from '~/components/Admin/Exam/AddExam/AddQuestion/QuestionPart1';
import QuestionPart2 from '~/components/Admin/Exam/AddExam/AddQuestion/QuestionPart2';
import QuestionPart34 from '~/components/Admin/Exam/AddExam/AddQuestion/QuestionPart34';
import QuestionPart5 from '~/components/Admin/Exam/AddExam/AddQuestion/QuestionPart5';
import QuestionPart67 from '~/components/Admin/Exam/AddExam/AddQuestion/QuestionPart67';

const EditExam = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();
    const dispatch = useDispatch();

    const [menuPart, setMenuPart] = useState([]);

    const topic = props.topic;

    const [contentTopic, setContentTopic] = useState({
        linkImage: topic.topicImage,
        topicName: topic.topicName,
        topicImage: '',
        topicDescription: topic.topicDescription,
        topicPack: topic.packId,
        topicType: topic.topicType,
        workTime: topic.workTime,
        numberQuestion: topic.numberQuestion,
        startTime: format(new Date(topic.startTime), `yyyy-MM-dd'T'HH:mm:ss'`),
        endTime: format(new Date(topic.startTime), `yyyy-MM-dd'T'HH:mm:ss'`),
        listPart: topic.listPart,
        err: {},
    });

    const [defaultTopic, setDefaultTopic] = useState({
        linkImage: topic.topicImage,
        topicName: topic.topicName,
        topicImage: '',
        topicDescription: topic.topicDescription,
        topicPack: topic.packId,
        topicType: topic.topicType,
        workTime: topic.workTime,
        numberQuestion: topic.numberQuestion,
        startTime: format(new Date(topic.startTime), `yyyy-MM-dd'T'HH:mm:ss'`),
        endTime: format(new Date(topic.startTime), `yyyy-MM-dd'T'HH:mm:ss'`),
        listPart: topic.listPart,
        err: {},
    });

    const [steps, setSteps] = useState([
        {
            title: 'Mô tả',
            content: (
                <DescriptionExam topic={props.topic} contentTopic={contentTopic} setContentTopic={setContentTopic} />
            ),
        },
    ]);

    const [current, setCurrent] = useState(0);
    const next = () => {
        setStepContent(null);
        setCurrent(current + 1);
    };
    const prev = () => {
        setStepContent(null);
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const [stepContent, setStepContent] = useState();
    const [listQuestion, setListQuestion] = useState({});

    const getListQuestionOfPart = async (partId) => {
        try {
            const responseQuestion = await listQuestionToPart(axiosPrivate, topic.topicId, {
                params: { partId },
                signal: controller.signal,
            });
            if (responseQuestion?.status === 'OK') {
                return responseQuestion.responseData;
            }
        } catch (error) { }
    };

    const addQuestion = (contentQuestion, item) => {
        setListQuestion((prevListQuestion) => {
            const updatedPart = [...(prevListQuestion[item.value] || [])];

            updatedPart.push(contentQuestion);

            return {
                ...prevListQuestion,
                [item.value]: updatedPart,
            };
        });
    };
    const removeQuestion = (questionId, item) => {
        setListQuestion((prevListQuestion) => {
            const updatedPart = [...(prevListQuestion[item.value] || [])];

            const indexToRemove = updatedPart.findIndex((question) => question.questionId === questionId);

            if (indexToRemove !== -1) {
                updatedPart.splice(indexToRemove, 1);
            }

            setStepContent(null);

            return {
                ...prevListQuestion,
                [item.value]: updatedPart,
            };
        });
    };
    const showContentStepPart = async (item) => {
        let listQuestionEdit;
        if (!listQuestion[item.value]) {
            listQuestionEdit = await getListQuestionOfPart(item.value);

            setListQuestion((prevListQuestion) => ({
                ...prevListQuestion,
                [item.value]: listQuestionEdit,
            }));
        } else {
            listQuestionEdit = listQuestion[item.value];
        }

        console.log(listQuestionEdit);

        switch (item.label) {
            case 'PART 1':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart1
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );
            case 'PART 2':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart2
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );
            case 'PART 3':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart34
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );
            case 'PART 4':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart34
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );

            case 'PART 5':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart5
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );

            case 'PART 6':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart67
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );
            case 'PART 7':
                return (
                    <div className="flex flex-col gap-4 w-full">
                        {listQuestionEdit.map((question, i) => (
                            <QuestionPart67
                                key={i}
                                number={i}
                                partId={item.value}
                                questionEdit={question}
                                addQuestionEdit={question.isAdd ? true : false}
                                topicId={topic.topicId}
                                addQuestion={(contentQuestion) => addQuestion(contentQuestion, item)}
                                removeQuestion={(questionId) => removeQuestion(questionId, item)}
                            />
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };

    const editTopicFunc = async () => {
        try {
            dispatch(startLoading());

            const formData = new FormData();
            formData.append('topicName', contentTopic.topicName);
            formData.append('topicDescription', contentTopic.topicDescription);
            formData.append('topicType', contentTopic.topicType);
            formData.append('topicPack', contentTopic.topicPack);
            formData.append('workTime', contentTopic.workTime);
            formData.append('numberQuestion', contentTopic.numberQuestion);
            formData.append('startTime', contentTopic.startTime);
            formData.append('endTime', contentTopic.endTime);
            if (contentTopic.topicImage !== '') {
                formData.append('topicImage', contentTopic.topicImage);
            }
            formData.append('listPart', contentTopic.listPart);

            const response = await updateTopic(axiosPrivate, topic.topicId, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response?.status === 'OK') {
                dispatch(setErrMessage(response.message));
                dispatch(setStatusMessage('Success'));
                setDefaultTopic(contentTopic);
                getListPart();
            } else {
                dispatch(setErrMessage('Edit question fail'));
                dispatch(setStatusMessage('Fail'));
            }
        } catch (error) {
        } finally {
            dispatch(stopLoading());
        }
    };
    const getListPart = async () => {
        try {
            const responsePart = await listPartToTopic(axiosPrivate, topic.topicId);
            if (responsePart?.status === 'OK') {
                const items = responsePart.responseData.map((item, i) => ({
                    value: item.partId,
                    label: item.partName.slice(0, 6),
                    totalQuestion: item.totalQuestion,
                }));
                setMenuPart(items);

                const partSteps = items.map((item) => ({
                    partId: item.value,
                    title: item.label,
                }));

                setSteps((prevSteps) => [prevSteps[0], ...partSteps]);
            }
        } catch (error) { }
    };

    useEffect(() => {
        getListPart();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        const fetchContent = async () => {
            if (current !== 0) {
                const content = await showContentStepPart(
                    menuPart.find((item) => item.value === steps[current].partId),
                );
                setStepContent(content);
            } else {
                setStepContent(steps[current].content);
            }
        };

        fetchContent();

        return () => {
            controller.abort();
        };
    }, [current, listQuestion]);

    return (
        <div className="w-full flex flex-col gap-7 mb-7">
            <div className="w-full flex justify-end">
                <ButtonCustom content={`Quay lại`} onClick={() => props.setOpenEdit(false)} />
            </div>
            <Steps
                current={current}
                items={items}
                onChange={(value) => {
                    setStepContent(null);
                    setCurrent(value);
                }}
            />
            <div className="w-full flex flex-col gap-4">{stepContent}</div>

            {current !== 0 ? (
                <ButtonCustom
                    content={`Thêm câu hỏi`}
                    onClick={() => {
                        setListQuestion((prevListQuestion) => {
                            const updatedPart = [...(prevListQuestion[steps[current].partId] || [])];

                            updatedPart.push({ questionId: uuidv4(), isAdd: true });

                            return {
                                ...prevListQuestion,
                                [steps[current].partId]: updatedPart,
                            };
                        });
                    }}
                />
            ) : null}

            <div className="w-full flex gap-4 justify-end">
                {JSON.stringify(defaultTopic) !== JSON.stringify(contentTopic) && (
                    <ButtonCustom content={`Save`} onClick={() => editTopicFunc()} />
                )}
                {current > 0 && <ButtonCustom content={`Previous`} onClick={() => prev()} />}
                {current < steps.length - 1 && <ButtonCustom content={`Next`} onClick={() => next()} />}
                {current === steps.length - 1 && (
                    <ButtonCustom content={`Thoát`} onClick={() => props.setOpenEdit(false)} />
                )}
            </div>
        </div>
    );
};

export default EditExam;
