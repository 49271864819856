// Core
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { AutoComplete } from 'antd';

// App
import { SearchDictionaryIcon } from '~/components/Icons';
import { getDictionarySuggestList } from '../../../api/dictionaryApi';

// Internal
import './Search.scss';
import { HighlightedText } from './components/HighlightedText';

// Component
const Search = ({ defaultKeyword }) => {
    const [optionList, setOptionList] = useState([]);
    const [nowValue, setNowValue] = useState(defaultKeyword);
    const navigate = useNavigate();

    const handleSearch = async (value) => {
        if (!value) {
            setOptionList([]);
            return;
        }

        const standardValue = value.trim().toLowerCase();
        const suggestList = await getDictionarySuggestList(standardValue);
        setOptionList(suggestList);
    };

    const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, [debouncedChangeHandler]);

    // Lấy từ khóa từ URL
    useEffect(() => {
        setNowValue(defaultKeyword);
        handleSearch(defaultKeyword);
    }, [defaultKeyword]);

    const handleChange = (value) => {
        if (/^[a-zA-Z]*$/.test(value)) {
            setNowValue(value);
        }
        debouncedChangeHandler(value);
    };

    const onSelect = (data) => {
        navigate(`/dictionary?search=${data}`);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            navigate(`/dictionary?search=${e.target.value}`);
        }
    };

    const handleClick = () => {
        navigate(`/dictionary?search=${nowValue}`);
    };

    const highlightRenderOption = useCallback((option, value) => {
        return <HighlightedText text={option.key} searchValue={value} />;
    }, []);

    return (
        <div className="search-container flex flex-row items-center w-full mx-[0.7%] lg:h-24 h-16 bg-white rounded-xl shadow-[0_8px_40px_0px_rgba(0,0,0,0.1)]">
            <button
                className="h-full flex items-center justify-center bg-hotlink-white-fafafa w-[5.825%] rounded-xl"
                onClick={handleClick}
            >
                <SearchDictionaryIcon className="cursor-pointer" />
            </button>
            <AutoComplete
                className="w-full h-full"
                value={nowValue}
                options={
                    optionList?.newSet
                        ? optionList.newSet.map((option) => ({
                              value: option,
                              label: highlightRenderOption({ key: option }, nowValue),
                          }))
                        : undefined
                }
                onSearch={handleChange}
                onSelect={onSelect}
                placeholder={'Search'}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

export default Search;
