import DOMPurify from 'dompurify';
import { useState } from 'react';
import { Popover } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import SunEditor from 'suneditor-react';

import Avatar from '~/components/Avatar';
import { CommentPostIcon, ThreeDots, TrashIcon, EditIcon } from '~/components/Icons';
import PostComment from '../PostComment';
import AddComment from '~/components/CommentTopic/AddComment';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import DeleteModal from '~/components/CommentTopic/DeleteModal';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';

import { updatePost } from '~/api/postApi';

function ShowPostItem(props) {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();

    const [showComment, setShowComment] = useState(false);
    const [listComment, setListComment] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [value, setValue] = useState(props.post.content);
    const [contentPost, setContentPost] = useState(props.post.content);

    const user = useSelector((state) => state.user.user);

    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (value !== '<p><br></p>' && value !== '') {
            try {
                dispatch(startLoading());
                const response = await updatePost(
                    axiosPrivate,
                    props.post.postId,
                    JSON.stringify({
                        content: value,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    },
                );

                if (response?.status === 'OK') {
                    setContentPost(response.responseData.content);
                    dispatch(setStatusMessage('Update Post successful'));
                    dispatch(setErrMessage('Successful'));
                    setOpenEdit(false);
                }
            } catch (err) {
            } finally {
                dispatch(stopLoading());
            }
        } else {
            dispatch(setStatusMessage('Please enter post'));
            dispatch(setErrMessage('Update fail'));
        }
    };

    const removePost = (postId) => {
        const newListPost = props.listPost.filter((item) => item.postId !== postId);

        props.setListPost(newListPost);
    };

    return (
        <div className=" bg-white p-4 flex flex-col gap-4 font-medium text-xs rounded-xl shadow-[0_4px_10px_0_rgba(0, 0, 0, 0.1)] mb-4">
            {openDeleteModal ? (
                <DeleteModal
                    postId={props.post.postId}
                    removePost={removePost}
                    closeModal={() => {
                        setOpenDeleteModal(false);
                    }}
                />
            ) : null}

            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-3 items-center">
                    <Avatar
                        className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba h-12 w-12"
                        src={props.post.userAvatar ? props.post.userAvatar : ''}
                        alt="user"
                    />
                    <div className="flex flex-col gap-2 ">
                        <div className="flex flex-row gap-6 items-center">
                            <span className="font-BeVietNamBold text-base font-bold">{props.post.userName}</span>
                        </div>
                        <span className="text-hotlink-gray-5b5b5b">{props.post.createAt}</span>
                    </div>
                </div>

                <div className="flex flex-col justify-end gap-2 text-hotlink-gray-5b5b5b text-xs">
                    {user && user.User.userId && user.User.userId === props.post.userId ? (
                        <div className="flex flex-col items-end">
                            <Popover
                                content={
                                    <div className="flex flex-col gap-4 px-4 py-2">
                                        {user && user.User.userId && user.User.userId === props.post.userId ? (
                                            <div className="flex flex-col gap-4">
                                                <button
                                                    className="flex flex-row gap-2 justify-start items-center"
                                                    onClick={() => {
                                                        setOpenEdit(true);
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <EditIcon />
                                                    <span className="text-sm">Chỉnh sửa</span>
                                                </button>
                                                <button
                                                    className="flex flex-row gap-2 justify-start items-center"
                                                    onClick={() => {
                                                        setOpenDeleteModal(true);
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <TrashIcon />
                                                    <span className="text-sm">Xóa</span>
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>
                                }
                                trigger="click"
                                open={open}
                                onOpenChange={handleOpenChange}
                            >
                                <button>
                                    <ThreeDots />
                                </button>
                            </Popover>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="text-base pb-3 border-b-[1px] border-hotlink-gray-dadbdd pt-4">
                {!openEdit ? (
                    <div
                        className="break-words"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(contentPost),
                        }}
                    ></div>
                ) : (
                    <form
                        className="flex flex-col gap-1 bg-hotlink-gray-f6f6f6 px-3 py-2 rounded-xl items-end"
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <SunEditor
                            width="100%"
                            height="150px"
                            setContents={value}
                            onChange={(e) => {
                                setValue(e);
                            }}
                            setOptions={{
                                buttonList: [['link', 'image']],
                            }}
                        />
                        <div className="flex flex-row gap-3">
                            <button
                                className="w-24 text-sm bg-hotlink-purple-714da6 py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                                type="submit"
                            >
                                Đăng
                            </button>

                            <button
                                className="w-16 text-sm bg-hotlink-red-d73d32 py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                                type="button"
                                onClick={() => {
                                    setValue(props.post.content);
                                    setOpenEdit(false);
                                }}
                            >
                                Hủy
                            </button>
                        </div>
                    </form>
                )}
            </div>

            <div className="flex flex-row gap-6 text-base">
                <button
                    className="flex flex-row gap-2 items-center cursor-pointer"
                    onClick={() => {
                        setShowComment(!showComment);
                    }}
                >
                    <CommentPostIcon />
                    <span className="">Comment </span>
                </button>
            </div>

            {showComment ? (
                <div>
                    <PostComment
                        postId={props.post.postId}
                        listComment={listComment}
                        setListComment={setListComment}
                    />
                    <AddComment postId={props.post.postId} />
                </div>
            ) : null}
        </div>
    );
}
export default ShowPostItem;
