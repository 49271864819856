// Core
import React, { useState,useEffect } from 'react';
import { message, Button, Select } from "antd";
import { PlusOutlined } from '@ant-design/icons';

// App
import useAxiosPrivate from "../../../../../../../hooks/useAxiosPrivate";

// Internal
import { addListQuestionPart12, addListQuestionPart34, addListQuestionPart5, addListQuestionPart67, addAllParts } from "../../../../../../../api/addexamApi/addexamApi";
import { Option, Dragger } from './UploadExam.constant';
//Component
const UploadExam = ({ topicId, initialExamData = {}, onUpdateExamData }) => {
    //States
    const axiosPrivate = useAxiosPrivate();
    const [fileBlob, setFileBlob] = useState(initialExamData.fileBlob || null);
    const [uploadedFileName, setUploadedFileName] = useState(initialExamData.uploadedFileName || '');
    const [selectedPart, setSelectedPart] = useState(initialExamData.selectedPart || null);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        onUpdateExamData({
            fileBlob,
            uploadedFileName,
            selectedPart,
        });
    }, [fileBlob, uploadedFileName, selectedPart, onUpdateExamData]);

    // Methods
    const beforeUploadFile = (file, allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']) => {
        const isAllowedType = allowedTypes.includes(file.type);
        if (!isAllowedType) {
            message.error('Chỉ được upload file excel');
        }
        return isAllowedType;
    };

    const handleChangeExcel = ({ file }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            setUploadedFileName(file.name);
            setFileBlob(file.originFileObj);
        }
    };

    const handlePartChange = (value) => {
        setSelectedPart(value);
    };

    const handleUpload = async () => {
        if (!fileBlob) {
            message.error('Vui lòng upload file Excel.');
            return;
        }
        if (!selectedPart) {
            message.error('Vui lòng chọn part.');
            return;
        }

        setUploading(true);
        const formDataexcel = new FormData();
        formDataexcel.append('file', fileBlob);

        try {
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            if (selectedPart === 'fullpart') {
                await addAllParts(axiosPrivate, formDataexcel, topicId, config);
            } else if (selectedPart === '1' || selectedPart === '2') {
                await addListQuestionPart12(axiosPrivate, formDataexcel, topicId, selectedPart, config);
            } else if (selectedPart === '3' || selectedPart === '4') {
                await addListQuestionPart34(axiosPrivate, formDataexcel, topicId, selectedPart, config);
            } else if (selectedPart === '5') {
                await addListQuestionPart5(axiosPrivate, formDataexcel, topicId, selectedPart, config);
            } else if (selectedPart === '6' || selectedPart === '7') {
                await addListQuestionPart67(axiosPrivate, formDataexcel, topicId, selectedPart, config);
            }

            message.success('Upload đề thi thành công!');
        } catch (error) {
            message.error('Upload thất bại, vui lòng thử lại.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mt-12">
            <h3 className="text-black font-bold mb-4 text-xl text-center">Bước 5: Upload câu hỏi của đề thi</h3>

            {/* Upload excel */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg text-center">
                <Dragger
                    className="mb-4"
                    listType="text"
                    onChange={handleChangeExcel}
                    beforeUpload={(file) => beforeUploadFile(file, [
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel'
                    ])}
                    showUploadList={false}
                >
                    {uploadedFileName ? (
                        <p className="ant-upload-text">{uploadedFileName}</p>
                    ) : (
                        <>
                            <p className="ant-upload-drag-icon">
                                <PlusOutlined />
                            </p>
                            <p className="ant-upload-text">Kéo và thả file Excel vào đây</p>
                            <p className="ant-upload-hint">Hỗ trợ định dạng: .xlsx, .xls</p>
                        </>
                    )}
                </Dragger>
            </div>

            {/* combobox */}
            <div className="flex items-center justify-center mt-4 space-x-4">
                <Select
                    placeholder="Chọn part"
                    className="border border-black text-sm"
                    onChange={handlePartChange}
                    style={{ width: 150 }}
                >
                    <Option value="1">Part 1</Option>
                    <Option value="2">Part 2</Option>
                    <Option value="3">Part 3</Option>
                    <Option value="4">Part 4</Option>
                    <Option value="5">Part 5</Option>
                    <Option value="6">Part 6</Option>
                    <Option value="7">Part 7</Option>
                    <Option value="fullpart">Full Part</Option>
                </Select>

                {/* button upload */}
                <Button
                    type="primary"
                    onClick={handleUpload}
                    className="bg-blue-500 text-white w-32"
                    loading={uploading}
                >
                    Upload
                </Button>
            </div>
        </div>
    );
};

export default UploadExam;
