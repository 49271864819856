// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-select-selector {
  align-items: center;
  font-size: 24px !important;
  height: 100% !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 12px !important;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}

.ant-select-selection-search-input {
  font-family: Be Vietnam Pro, sans-serif;
  color: rgba(0, 0, 0, 0.85) !important;
}

.search-container {
  font-family: "Be Vietnam Pro", sans-serif;
}

.ant-select-selector,
.ant-select-selection-item,
.ant-select-item-option-content,
.ant-select-item-option {
  font-family: "Be Vietnam Pro", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/Dictionary/Search/Search.scss"],"names":[],"mappings":"AACI;EAAA,mBAAA;EACA,0BAAA;EACA,uBAAA;EACA,uBAAA;EACA,2BAAA;EACA;AALA;;AASA;EAAA,aAAA;EAAA;AAAA;;AAIA;EAAA,uCAAA;EACA;AADA;;AAGJ;EACI,yCAAA;AAEJ;;AACA;;;;EAII,yCAAA;AAEJ","sourcesContent":[".ant-select-selector {\n    @apply items-center;\n    font-size: 24px !important;\n    height: 100% !important;\n    border: none !important;\n    box-shadow: none !important;\n    border-radius: 12px !important;\n}\n\n.ant-select-selection-search {\n    @apply flex items-center;\n}\n\n.ant-select-selection-search-input {\n    @apply font-BeVietNamRegular;\n    color: rgba(0, 0, 0, 0.85) !important;\n}\n.search-container {\n    font-family: 'Be Vietnam Pro', sans-serif;\n}\n\n.ant-select-selector,\n.ant-select-selection-item,\n.ant-select-item-option-content,\n.ant-select-item-option {\n    font-family: 'Be Vietnam Pro', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
