import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { listComment } from '~/api/topicApi';
import AddComment from './AddComment';
import TopicCommentItem from './TopicCommentItem';

const BASE_URL = process.env.REACT_APP_API_URL;
var stompClient = null;

function CommentTopic(props) {
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const [comments, setComments] = useState([]);

    const getListComment = async () => {
        try {
            const response = await listComment(axiosPrivate, props.state.inforTopic.id);

            if (response.status === 'OK') {
                setComments(response.responseData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const fetchDataAndSubscribe = async () => {
            if (props.state.inforTopic.id) {
                await getListComment();

                const socket = new SockJS(BASE_URL + '/ws');
                stompClient = Stomp.over(socket);
                stompClient.debug = () => { };
                try {
                    stompClient.connect(
                        {},
                        () => {
                            stompClient.subscribe(`/comment/topic/${props.state.inforTopic.id}`, (message) => {
                                const newComment = JSON.parse(message.body);
                                setComments((prevComments) => [newComment, ...prevComments]);
                            });
                        },
                        (err) => { },
                    );
                } catch (error) {
                    // console.error("Error connecting to STOMP:", error);
                }
            }
        };

        fetchDataAndSubscribe();

        return () => {
            if (stompClient && stompClient.connected) {
                stompClient.disconnect();
            }

            controller.abort();
        };
    }, [props.state.inforTopic]);

    return (
        <div className="lg:w-1/2 w-full bg-white lg:mx-20 lg:p-4 py-4 px-2 flex flex-col gap-4 font-BeVietnamMedium text-xs rounded-xl shadow-[0_4px_10px_0_rgba(0, 0, 0, 0.1)]">
            <AddComment topicId={props.state.inforTopic.id} />
            {comments
                ? comments.map((comment) => (
                    <TopicCommentItem
                        key={comment.commentId}
                        comment={comment}
                        listComment={comments}
                        setListComment={setComments}
                    />
                ))
                : null}
        </div>
    );
}

export default CommentTopic;
