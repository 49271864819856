import { useLocation } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useSelector, useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import DOMPurify from 'dompurify';

import { listQuestionToPart } from '~/api/mockTestApi';
import AudioPlay from '~/components/TestPage/ToeicExamPage/AudioPlay/AudioPlay';
import ResultExamRadio from '../ResultExamRadio';
import { setListQuestion } from '~/redux/topicSlice';
import images from '~/assets/images';

function ResultExamQuestion(props) {
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const dispatch = useDispatch();

    const listQuestion = useSelector((state) => state.topic.listQuestion);

    const mockTestId = new URLSearchParams(location.search).get('result');
    const getListQuestionToPart = async () => {
        try {
            dispatch(startLoading());
            const response = await listQuestionToPart(axiosPrivate, mockTestId, {
                params: { partId: props.state.currentPart.partId },
                signal: controller.signal,
            });

            if (response.status === 'OK') {
                let updatedListQuestion = [...listQuestion];
                let number = 0;
                for (const part of props.state.listPart) {
                    if (part.partId === props.state.currentPart.partId) {
                        break;
                    }
                    number += part.totalQuestion;
                }
                response.responseData.forEach((questionData) => {
                    const questionExists = updatedListQuestion.some(
                        (item) => item.questionId === questionData.questionId,
                    );
                    if (!questionExists) {
                        questionData.numberical = number + 1;

                        let numberIndex = questionData.questionGroup
                            ? number + questionData.questionGroup.length
                            : number + 1;
                        number = numberIndex;

                        updatedListQuestion.push(questionData);
                    }
                });
                dispatch(setListQuestion(updatedListQuestion));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const showQuestionRadio = (question) => {
        if (question.questionGroup) {
            return question.questionGroup.map((questionToGroup, index) => (
                <ResultExamRadio
                    key={index}
                    questionRadio={questionToGroup}
                    numberical={listQuestion.find((item) => item.questionId === question.questionId).numberical + index}
                />
            ));
        } else {
            return (
                <ResultExamRadio
                    questionRadio={question}
                    ofGroup={true}
                    numberical={listQuestion.find((item) => item.questionId === question.questionId).numberical}
                />
            );
        }
    };

    const showIndexQuestion = (currentQuestion) => {
        if (listQuestion.find((item) => item.questionId === currentQuestion)) {
            const question = listQuestion.find((item) => item.questionId === currentQuestion);
            if (question.questionGroup) {
                return `${question.numberical} - ${question.numberical + question.questionGroup.length - 1}`;
            }
            return `${question.numberical}`;
        }
        return '';
    };

    useEffect(() => {
        if (
            props.state.currentPart &&
            props.state.currentPart.partId &&
            !listQuestion.some((item) => item.partId === props.state.currentPart.partId)
        ) {
            getListQuestionToPart();
        }

        return () => {
            controller.abort();
        };
    }, [props.state.currentPart]);

    const renderContent = (contentQuestion) => {
        const imageContentList = contentQuestion?.contentList?.filter(item => item['Content Type'] === 'IMAGE');

        if (imageContentList && imageContentList.length > 0) {
            return imageContentList.map((imageContent, index) => (
                <img key={index}
                    src={imageContent['Content Data']}
                    alt=""
                    onError={(e) => {
                        e.target.src = images.image404;
                    }}
                />
            ));
        }

        if (contentQuestion?.questionGroup?.length > 0) {
            const imagesInGroup = contentQuestion.questionGroup
                .map((question) => question.contentList?.filter(item => item['Content Type'] === 'IMAGE'))
                .flat();

            if (imagesInGroup.length > 0) {
                return imagesInGroup.map((imageContent, index) => (
                    <img
                        key={index}
                        src={imageContent['Content Data']}
                        alt=""
                        onError={(e) => {
                            e.target.src = images.image404;
                        }}
                    />
                ));
            }
        }

        return null;
    };
    return (
        <div className="flex flex-col gap-6 lg:px-20 px-5 my-6">
            {listQuestion
                ? listQuestion.map((item) =>
                      props.state.currentPart && props.state.currentPart.partId === item.partId ? (
                          <div className="w-full flex lg:flex-row flex-col gap-4 justify-between" key={item.questionId}>
                              <div className="lg:w-[43%] w-full flex flex-col justify-start items-start gap-4 rounded-xl bg-[#fff] p-6 text-left text-[#262626] font-medium">
                                  <h5 className="font-BeVietnamMedium text-[22px]">
                                      Câu {showIndexQuestion(item.questionId)}:
                                  </h5>
                                  {renderContent(item)}
                                  {/* {item.contentList &&
                                  item.contentList.some((itemContent) => itemContent['Content Type'] === 'IMAGE') ? (
                                      <img
                                          src={
                                              item.contentList.find(
                                                  (itemContent) => itemContent['Content Type'] === 'IMAGE',
                                              )['Content Data']
                                          }
                                          alt="test"
                                      ></img>
                                  ) : null} */}

                                  {item.contentList &&
                                  item.contentList.some((itemContent) => itemContent['Content Type'] === 'AUDIO') ? (
                                      <AudioPlay
                                          audioQuestion={
                                              item.contentList.find(
                                                  (itemContent) => itemContent['Content Type'] === 'AUDIO',
                                              )['Content Data']
                                          }
                                      />
                                  ) : null}

                                  <div
                                      className="font-BeVietnamRegular text-[18px] text-hotlink-black-262626"
                                      dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(item.questionContent),
                                      }}
                                  ></div>
                              </div>
                              <div className="lg:w-[55%] w-full flex flex-col gap-4 rounded-lg bg-white p-6 text-left text-[#262626] font-medium overflow-y-auto">
                                  {showQuestionRadio(item)}
                              </div>
                          </div>
                      ) : null,
                  )
                : null}
        </div>
    );
}

export default ResultExamQuestion;
