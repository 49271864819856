//Core
import React, { useState, useEffect } from 'react';
import { message, Button, Input } from "antd";
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';

//App
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';

//Internal
import { uploadImage } from '../../../../../../../api/uploadApi/uploadApi';

//Component
const UploadPartAudio = ({ topicId, initialPartAudios = [], onUpdatePartAudios }) => {

    //States
    const axiosPrivate = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [partAudios, setPartAudios] = useState(initialPartAudios);
    const [tempPartAudio, setTempPartAudio] = useState(null);
    const [errorShown, setErrorShown] = useState(false);

    useEffect(() => {
        onUpdatePartAudios(partAudios);
    }, [partAudios, onUpdatePartAudios]);

    // Methods
    const handleUploadAudio = async (file, index) => {
        if (uploading || !topicId || !partAudios[index].code) {
            return;
        }

        setUploading(true);

        try {
            const response = await uploadImage(
                axiosPrivate,
                file,
                '/',
                false,
                topicId,
                partAudios[index].code
            );

            const audioLink = response.responseData;
            setPartAudios(prev => {
                const updatedParts = [...prev];
                updatedParts[index].url = audioLink;
                updatedParts[index].uploaded = true;
                return updatedParts;
            });

            message.success('Upload audio thành công!');
        } catch (error) {
            message.error('Upload audio thất bại');
        } finally {
            setUploading(false);
        }
    };

    const handleChange = (event, index) => {
        const file = event.target.files[0];
        const isAudio = file && (file.type === 'audio/mpeg' || file.type === 'audio/wav');

        handleDeleteAudio(index);
        if (isAudio) {
            const tempUrl = URL.createObjectURL(file);
            setPartAudios(prev => {
                const updatedParts = [...prev];
                updatedParts[index].url = tempUrl;
                updatedParts[index].fileObject = file;
                return updatedParts;
            });
            setTempPartAudio(file);
            setErrorShown(false);
        } else if (!errorShown) {
            message.error('Bạn chỉ có thể tải lên file audio (MP3 hoặc WAV)');
            setErrorShown(true);
        }
    };

    const handleUpload = async (index) => {
        if (!partAudios[index].code) {
            message.warning('Vui lòng nhập mã code cho phần này!');
            return;
        }

        const currentAudio = partAudios[index];
        const currentAudioUrl = currentAudio.url;
        const currentFileObject = currentAudio.fileObject;

        if (!currentFileObject && !currentAudioUrl) {
            message.warning('Vui lòng chọn audio để upload!');
            return;
        }

        try {
            const fileToUpload = currentFileObject || (await fetch(currentAudioUrl).then(r => r.blob()));
            await handleUploadAudio(fileToUpload, index);
            if (currentAudioUrl && currentAudioUrl.startsWith('blob:')) {
                URL.revokeObjectURL(currentAudioUrl);
            }
            setTempPartAudio(null);
        } catch (error) {
            message.error('Upload audio thất bại: ' + error);
        }
    };

    const handleDeleteAudio = (index) => {
        setPartAudios(prev => {
            const updatedParts = [...prev];
            updatedParts[index].url = null;
            updatedParts[index].uploaded = false;
            return updatedParts;
        });
    };

    const handleDeletePartAudio = (index) => {
        setPartAudios(prev => {
            const updatedParts = [...prev];
            updatedParts.splice(index, 1); // Xóa phần tử ở vị trí 'index'
            return updatedParts;
        });
    };

    const addPartAudioField = () => {
        setPartAudios(prev => [...prev, { url: null, code: '', uploaded: false }]);
    };

    return (
        <div className="flex flex-col items-start justify-center mt-8 ml-6">
            <h3 className="text-black-600 font-bold mb-4 text-lg">
                Bước 4: Upload audio cho từng part:
            </h3>
            <div className="flex flex-col gap-4 mt-4 w-full max-w-md mx-auto">
                {partAudios.map((partAudio, index) => (
                    <div className="relative flex flex-col items-start mt-4 p-4 bg-white rounded-lg shadow-md border border-gray-200" key={index}>
                        {/* Remove image upload box */}
                        {!partAudio.uploaded && (
                            <Button
                                icon={<CloseOutlined />}
                                shape="circle"
                                className="absolute top-1 right-1 bg-white"
                                onClick={() => handleDeletePartAudio(index)}
                                style={{
                                    border: 'none',
                                    color: '#ff4d4f',
                                    fontSize: '14px',
                                }}
                            />
                        )}

                        <input
                            type="file"
                            accept="audio/*"
                            onChange={(event) => handleChange(event, index)}
                            className="w-full mb-2 border border-dashed border-gray-300 rounded-lg p-2 text-sm cursor-pointer mt-6"
                        />
                        {partAudio.url ? (
                            <div className="relative w-full">
                                <audio controls className="w-full mb-2">
                                    <source src={partAudio.url} />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        ) : (
                            <div className="flex items-center justify-center w-full h-10 border border-dashed border-gray-300 rounded-lg text-gray-500 text-sm">
                                <span>Chọn file audio</span>
                            </div>
                        )}
                        <Input
                            className="mt-2 w-full text-sm h-10"
                            placeholder="Mã code"
                            value={partAudio.code}
                            onChange={(e) => {
                                const value = e.target.value;
                                const isValid = /^[a-zA-Z0-9]*$/.test(value);
                                if (isValid) {
                                    setPartAudios(prev => {
                                        const updatedParts = [...prev];
                                        updatedParts[index].code = value;
                                        return updatedParts;
                                    });
                                }
                            }}
                        />
                        {!partAudio.uploaded && (
                            <Button
                                onClick={() => handleUpload(index)}
                                className="mt-2 w-full h-10 bg-blue-500 text-white hover:bg-blue-600 transition duration-200 flex items-center justify-center text-sm"
                                disabled={uploading}
                            >
                                {uploading ? <LoadingOutlined spin /> : 'Upload'}
                            </Button>
                        )}
                    </div>
                ))}
            </div>
            <div className="flex justify-center mt-4 w-full">
                <Button
                    onClick={addPartAudioField}
                    type="primary"
                    className="w-64 h-10 bg-blue-500 text-white border-none rounded-lg text-base flex items-center justify-center hover:bg-blue-600 shadow-lg transition duration-200"
                >
                    Add Audio
                </Button>
            </div>
        </div>
    );
}

export default UploadPartAudio;
