import './Footer.scss';

import { WebsiteIcon, PhoneIcon, AddressIcon } from '~/components/Icons';
import momoLogo from '~/assets/svg/momo.svg';
import mastercardLogo from '~/assets/svg/mastercard.svg';
import napasLogo from '~/assets/svg/napas.svg';
import visaLogo from '~/assets/svg/visa.svg';
import vnpayLogo from '~/assets/svg/vnpay.svg';

import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation('common');
    return (
        <footer className="flex flex-wrap justify-between py-14 lg:px-20 px-8 bg-hotlink-blue-309 gap-8">
            <div className="communications">
                <label className="font-be-vietnam font-bold leading-[1.1] lg:text-xl text-lg">{t('communications')}</label>
                <div className="flex flex-col gap-2 lg:text-base text-sm">
                    <div className="flex flex-row gap-1 items-center">
                        <div className="icon">
                            <WebsiteIcon />
                        </div>
                        <label className="content-child flex items-center font-BeVietNamRegular leading-[1.38]">
                            meuenglish.com.vn
                        </label>
                    </div>
                    <div className="flex flex-row gap-1 items-center">
                        <div className="icon">
                            <PhoneIcon />
                        </div>
                        <label className="flex items-center font-BeVietNamRegular leading-[1.38]">02.345.678.90</label>
                    </div>
                    <div className="flex flex-row gap-1 items-center">
                        <div className="icon">
                            <AddressIcon />
                        </div>
                        <label className="flex items-center font-BeVietNamRegular leading-[1.38]">{t('address')}</label>
                    </div>
                </div>
            </div>

            <div className="description">
                <label className="lg:text-xl text-lg font-be-vietnam font-bold leading-[1.1]">{t('about_MeU')}</label>
                <div className="flex flex-col gap-2 lg:text-base text-sm">
                    <div className="flex flex-row gap-1">
                        <label className="flex items-center font-BeVietNamRegular leading-[1.38]">
                            {t('information_MeU')}
                        </label>
                    </div>
                </div>
            </div>

            <div className="customer-care">
                <label className="lg:text-xl text-lg font-be-vietnam font-bold leading-[1.1]">{t('customer_care')}</label>
                <div className="flex lg:flex-col flex-row flex-wrap justify-between lg:gap-2 gap-2 lg:text-base text-sm">
                    <div className="flex flex-row gap-1">
                        <label className="flex items-center font-BeVietNamRegular leading-[1.38]">
                            {t('help_center')}
                        </label>
                    </div>
                    <div className="flex flex-row gap-1">
                        <label className="flex items-center font-BeVietNamRegular leading-[1.38]">{t('payment')}</label>
                    </div>
                    <div className="flex flex-row gap-1">
                        <label className="flex items-center font-BeVietNamRegular leading-[1.38]">{t('warranty')}</label>
                    </div>
                </div>
            </div>

            <div className="payments">
                <label className="lg:text-xl text-lg font-be-vietnam font-bold leading-[1.1]">{t('payment_method')}</label>
                <div className="flex gap-4">
                    <img className="lg:h-[60px] lg:w-[60px] h-12 w-12 rounded-lg bg-white" src={napasLogo} alt="Napas" />
                    <img className="lg:h-[60px] lg:w-[60px] h-12 w-12 rounded-lg bg-white" src={visaLogo} alt="Visa" />
                    <img className="lg:h-[60px] lg:w-[60px] h-12 w-12 rounded-lg bg-white" src={mastercardLogo} alt="Master" />
                    <img className="lg:h-[60px] lg:w-[60px] h-12 w-12 rounded-lg bg-white" src={momoLogo} alt="MoMo" />
                    <img className="lg:h-[60px] lg:w-[60px] h-12 w-12 rounded-lg bg-white" src={vnpayLogo} alt="VNPay" />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
