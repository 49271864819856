import './ToeicExam.scss';
import { useTranslation } from 'react-i18next';
import Next from '~/assets/svg/exam-next.svg';
import Prev from '~/assets/svg/exam-prev.svg';
import ToeicPart from '../ToeicPart/ToeicPart';
import { useLocation } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import { useSelector, useDispatch } from 'react-redux';
import { setListPart, setCurrentPart, setCurrentQuestion, setTotalQuestion } from '~/redux/topicSlice';
import { goToNextQuestion } from '~/redux/topicSlice';

function ToeicExam(props) {
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const dispatch = useDispatch();
    const listPart = useSelector((state) => state.topic.listPart);
    const startPart = useSelector((state) => state.topic.startPart);
    const currentPart = useSelector((state) => state.topic.currentPart);
    const listQuestion = useSelector((state) => state.topic.listQuestion);
    const currentQuestion = useSelector((state) => state.topic.currentQuestion);
    const totalQuestion = useSelector((state) => state.topic.totalQuestion);

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation('common');

    const topicId = new URLSearchParams(location.search).get('id');
    console.log(topicId);

    const getListPartTopic = async () => {
        try {
            setIsLoading(true);
            const response = await axiosPrivate.get('/topic/' + topicId + '/listPart', {
                signal: controller.signal,
            });

            if (response.data.status === 'success') {
                let total = 0;
                dispatch(setListPart(response.data.responseData));
                dispatch(setCurrentPart(response.data.responseData[0].partId));
                response.data.responseData.map((part) => {
                    total = total + part.totalQuestion;
                });
                dispatch(setTotalQuestion(total));
            }
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const getFirstQuestionIdByPart = (partId) => {
        const listQuestionToPart = listQuestion.filter((item) => item.partId === partId);

        if (listQuestionToPart && listQuestionToPart[0]) {
            dispatch(setCurrentQuestion(listQuestionToPart[0].questionId));
        }
        dispatch(setCurrentPart(partId));
    };

    const showQuestionCurrentIndex = () => {
        const question = listQuestion.find((item) => item.questionId === currentQuestion);

        if (question) {
            return question.numberical;
        }
        return null;
    };

    const checkQuestion = () => {
        const question = listQuestion.find((item) => item.questionId === currentQuestion);
        if (question?.numberical === 1) {
            return 1;
        }
        if (question?.questionGroup?.length + question?.numberical - 1 === totalQuestion) {
            return totalQuestion;
        }
        return null;
    };


    const getCurrentPartNumber = () => {
        const currentPartObj = listPart.find(part => part.partId === currentPart);
        if (!currentPartObj) return null;

        return parseInt(currentPartObj.partName.split(':')[0].replace('PART', '').trim());
    };

    // const getNextQuestion = () => {
    //     const partNumber = getCurrentPartNumber();
    //     if (partNumber && partNumber >= 1 && partNumber <= 5) return;

    //     dispatch(goToNextQuestion());
    // };

    // const getPrevQuestion = () => {
    //     const partNumber = getCurrentPartNumber();
    //     if (!partNumber || partNumber === 5 || (partNumber >= 1 && partNumber <= 4)) return;

    //     const prevQuestionIndex = listQuestion.findIndex((item) => item.questionId === currentQuestion) - 1;
    //     if (prevQuestionIndex >= 0) {
    //         dispatch(setCurrentQuestion(listQuestion[prevQuestionIndex].questionId));
    //         dispatch(setCurrentPart(listQuestion[prevQuestionIndex].partId));
    //     }
    // };

    const getNextQuestion = () => {
        const currentQuestionIndex = listQuestion.findIndex((item) => item.questionId === currentQuestion);
        if (currentQuestionIndex + 1 < listQuestion.length && listQuestion[currentQuestionIndex + 1]?.partId === currentPart) {
            dispatch(setCurrentQuestion(listQuestion[currentQuestionIndex + 1].questionId));
        } else {
            const nextPartIndex = listPart.findIndex((item) => item.partId === currentPart) + 1;
            if (nextPartIndex < listPart.length) {
                dispatch(setCurrentPart(listPart[nextPartIndex].partId));
                const firstQuestionInNextPart = listQuestion.find((item) => item.partId === listPart[nextPartIndex].partId);
                if (firstQuestionInNextPart) {
                    dispatch(setCurrentQuestion(firstQuestionInNextPart.questionId));
                }
            }
        }
    };

    const getPrevQuestion = () => {
        const currentQuestionIndex = listQuestion.findIndex((item) => item.questionId === currentQuestion);
        if (currentQuestionIndex - 1 >= 0 && listQuestion[currentQuestionIndex - 1]?.partId === currentPart) {
            dispatch(setCurrentQuestion(listQuestion[currentQuestionIndex - 1].questionId));
        } else {

            const prevPartIndex = listPart.findIndex((item) => item.partId === currentPart) - 1;
            if (prevPartIndex >= 0) {
                dispatch(setCurrentPart(listPart[prevPartIndex].partId));
                const lastQuestionInPrevPart = listQuestion.filter((item) => item.partId === listPart[prevPartIndex].partId).pop();
                if (lastQuestionInPrevPart) {
                    dispatch(setCurrentQuestion(lastQuestionInPrevPart.questionId));
                }
            }
        }
    };

    useEffect(() => {
        console.log("Current Part ID:", currentPart);
        console.log("List Part:", listPart);
    }, [currentPart, listPart]);

    useEffect(() => {
        getListPartTopic();
        return () => {
            controller.abort();
        };
    }, []);

    return !isLoading ? (
        <div className={`w-full h-full flex text-center flex-col pt-10 pb-20 bg-[#f7f3ff]`}>
            <h1 className="lg:text-[40px] text-3xl font-BeVietnamSemiBold text-[#309]">{props.infor?.topicName}</h1>
            <span className="text-base mb-10">
                {t('time')}: {props.infor?.workTime} phút
            </span>
            <div className="flex justify-between lg:px-20 px-2">
                <div className="rounded-lg flex px-2 py-1 bg w-auto h-auto cursor-pointer">
                    {listPart
                        ? listPart.map((part, index) => (
                            <p
                                key={index}
                                className={`lg:px-4 px-3 lg:py-3.5 py-1 lg:text-base text-sm font-BeVietnamMedium text-hotlink-black-262626 italic lowercase first-letter:uppercase ${currentPart === part.partId ? 'active' : ''}
        ${currentPart !== part.partId && !listQuestion.some((item) => item.partId === part.partId) ? 'pointer-events-none opacity-50' : ''}`}
                                onClick={() => getFirstQuestionIdByPart(part.partId)}
                            >
                                {part.partName.slice(0, 6)}
                            </p>

                        ))
                        : ''}
                </div>

                <div className="hidden px-6 lg:flex items-center text-center bg-[#f6ae2d]">
                    <span className="text-base">
                        {showQuestionCurrentIndex()}/{totalQuestion}
                    </span>
                </div>
            </div>
            {listPart.find((item) => item.partId === currentPart) ? (
                <ToeicPart />
            ) : (
                ''
            )}

            <div className="lg:px-20 px-10 mt-4 flex justify-end">
                {/* {(checkQuestion() === 1 || !startPart.includes(currentPart) || getCurrentPartNumber() === 5) ? (
                    <button className="bg-[#fff] p-4 mr-2 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 mr-2 rotate-180 cursor-pointer">
                        <img src={Next} alt="" onClick={getPrevQuestion} />
                    </button>
                )}

                {(checkQuestion() === totalQuestion || !startPart.includes(currentPart)) ? (
                    <button className="bg-[#fff] p-4 mr rotate-180 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 cursor-pointer">
                        <img src={Next} alt="" onClick={getNextQuestion} />
                    </button>
                )} */}

                {checkQuestion() === 1 || !startPart.includes(currentPart) ? (
                    <button className="bg-[#fff] p-4 mr-2 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 mr-2 rotate-180 cursor-pointer">
                        <img src={Next} alt="" onClick={getPrevQuestion} />
                    </button>
                )}

                {checkQuestion() === totalQuestion || !startPart.includes(currentPart) ? (
                    <button className="bg-[#fff] p-4 mr rotate-180 cursor-pointer" disabled>
                        <img src={Prev} alt="" />
                    </button>
                ) : (
                    <button className="bg-[#fff] p-4 cursor-pointer">
                        <img src={Next} alt="" onClick={getNextQuestion} />
                    </button>
                )}

            </div>

        </div>
    ) : (
        <div className="h-[1000px]"></div>
    );
}

export default ToeicExam;
