import { useNavigate } from 'react-router-dom';

function ButtonLogin(props) {
    const navigate = useNavigate();

    return (
        <button
            onClick={() => navigate(props.link)}
            className="bg-hotlink-yellow-FBCA1F font-beVietnamPro py-[0.6em] px-[1.3em] font-black text-base border-[3px] border-solid border-black rounded-[0.4em] shadow-[0.1em_0.1em]
                         hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                         active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
        >
            Login
        </button>
    );
}

export default ButtonLogin;
