import { InputNumber, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';

import UploadAudio from '~/components/Upload/UploadAudio';
import UploadImage from '~/components/Upload/UploadImage';
import { addQuestionToTopic } from '../../../../../../api/topicApi';
import DeleteQuestion from '../../../DeleteQuestion';
import EditQuestion from '../../../EditQuestion';

const QuestionPart1 = (props) => {
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [requestEdit, setRequestEdit] = useState(false);
    const [addQuestionEdit, setAddQuestionEdit] = useState(props.addQuestionEdit);

    const [contentQuestion, setContentQuestion] = useState({
        questionId: props.questionEdit ? props.questionEdit.questionId : '',
        linkImage: props.questionEdit?.contentList?.some((itemContent) => itemContent['Content Type'] === 'IMAGE')
            ? props.questionEdit.contentList.find((itemContent) => itemContent['Content Type'] === 'IMAGE')[
            'Content Data'
            ]
            : '',
        linkAudio: props.questionEdit?.contentList?.some((itemContent) => itemContent['Content Type'] === 'AUDIO')
            ? props.questionEdit.contentList.find((itemContent) => itemContent['Content Type'] === 'AUDIO')[
            'Content Data'
            ]
            : '',
        contentImage: '',
        contentAudio: '',
        questionScore: props.questionEdit ? props.questionEdit.questionScore : 1,
        partId: props.partId,
        listAnswer: props.questionEdit?.listAnswer
            ? props.questionEdit.listAnswer.map((answer) => ({
                idAnswer: answer.answerId,
                contentAnswer: answer.answerContent,
                correctAnswer: answer.answerId === props.questionEdit.answerCorrect,
            }))
            : [
                { contentAnswer: 'A', correctAnswer: true },
                { contentAnswer: 'B', correctAnswer: false },
                { contentAnswer: 'C', correctAnswer: false },
                { contentAnswer: 'D', correctAnswer: false },
            ],
        err: {},
    });

    const [value, setValue] = useState(
        contentQuestion.listAnswer.findIndex((answer) => answer.correctAnswer === true) + 1,
    );

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;

        // Update the correctAnswer property in the listAnswer array
        const updatedListAnswer = contentQuestion.listAnswer.map((answer, index) => ({
            ...answer,
            correctAnswer: index + 1 === selectedValue,
        }));

        setContentQuestion((prevContent) => ({
            ...prevContent,
            listAnswer: updatedListAnswer,
        }));
        setValue(selectedValue);
    };

    const addQuestionToTopicFunc = async () => {
        try {
            dispatch(startLoading());
            const formData = new FormData();

            if (contentQuestion.contentImage !== '' && contentQuestion.contentImage) {
                formData.append(`contentImage`, contentQuestion.contentImage);
            }
            if (contentQuestion.contentAudio !== '' && contentQuestion.contentAudio) {
                formData.append(`contentAudio`, contentQuestion.contentAudio);
            }
            formData.append(`partId`, contentQuestion.partId);
            formData.append(`questionScore`, contentQuestion.questionScore ? contentQuestion.questionScore : 1);

            if (contentQuestion.listAnswer) {
                contentQuestion.listAnswer.forEach((listAnswerItem, answerIndex) => {
                    formData.append(`listAnswer[${answerIndex}].contentAnswer`, listAnswerItem.contentAnswer);
                    formData.append(`listAnswer[${answerIndex}].correctAnswer`, listAnswerItem.correctAnswer);
                });
            }
            const response = await addQuestionToTopic(axiosPrivate, props.topicId, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 'OK') {
                dispatch(setErrMessage(response.message));
                dispatch(setStatusMessage('Success'));
                setContentQuestion((prevState) => ({
                    ...prevState,
                    questionId: response.responseData.questionId,
                }));

                setAddQuestionEdit(false);
                props.removeQuestion(contentQuestion.questionId);
                props.addQuestion({
                    ...response.responseData,
                    questionId: response.responseData.questionId,
                });
            } else {
                dispatch(setErrMessage('Add question to topic fail'));
                dispatch(setStatusMessage('Fail'));
            }
        } catch (err) {
            dispatch(setErrMessage(err));
            dispatch(setStatusMessage('Fail'));
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (props.setListQuestion) {
            props.setListQuestion(contentQuestion);
        }
    }, [contentQuestion]);


    return (
        <div className="flex flex-col gap-4">
            {openDelete ? (
                <DeleteQuestion
                    questionId={contentQuestion.questionId}
                    removeQuestion={props.removeQuestion}
                    setOpenDelete={setOpenDelete}
                />
            ) : null}

            {openEdit ? (
                <EditQuestion
                    questionEdit={contentQuestion}
                    setOpenEdit={setOpenEdit}
                    setRequestEdit={setRequestEdit}
                />
            ) : null}

            <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Câu hỏi {props.number + 1}:</span>
            <div className="flex flex-row items-center justify-center gap-4 w-full">
                {/* Upload image of question */}
                <div className="flex flex-row items-center justify-center gap-2 w-1/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Hình ảnh</span>

                    <UploadImage
                        disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                        imageLink={
                            contentQuestion.contentImage !== ''
                                ? contentQuestion.contentImage
                                : contentQuestion.linkImage
                        }
                        setContent={(value) =>
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                contentImage: value,
                            }))
                        }
                    />
                </div>
                {/* Upload audio of question */}
                <div className="flex flex-row items-center justify-center gap-2 w-1/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Âm thanh</span>

                    <UploadAudio
                        disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                        inforFile={
                            contentQuestion.contentAudio !== ''
                                ? contentQuestion.contentAudio
                                : contentQuestion.linkAudio
                        }
                        setContent={(value) =>
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                contentAudio: value,
                            }))
                        }
                    />
                </div>

                {/* Inser point of question */}
                <div className="flex flex-row items-center justify-center gap-2 w-1/3">
                    <span className="font-BeVietNamRegular text-sm font-bold w-1/4">Số điểm</span>
                    <InputNumber
                        disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                        className="w-1/2 font-BeVietNamRegular text-sm"
                        placeholder="Số điểm"
                        defaultValue={contentQuestion.questionScore ?? 1}
                        onChange={(value) => {
                            setContentQuestion((prevContentQuestion) => ({
                                ...prevContentQuestion,
                                questionScore: value,
                            }));
                        }}
                    />
                </div>
            </div>

            {/* Insert Answer of question */}
            <div className="flex flex-row items-center gap-2 w-full">
                <span className="font-BeVietNamRegular text-sm font-bold w-1/4 pl-10">Câu trả lời đúng:</span>
                <Radio.Group
                    className="flex flex-row gap-6"
                    disabled={props.questionEdit && !requestEdit && !addQuestionEdit ? true : false}
                    onChange={handleRadioChange}
                    value={value}
                >
                    {contentQuestion.listAnswer.map((answer, index) => (
                        <Radio key={index + 1} value={index + 1}>
                            {answer.contentAnswer}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>

            {props.questionEdit ? (
                <div className="w-full flex justify-end gap-4 pb-4">
                    {addQuestionEdit ? (
                        <button
                            className="w-24 text-sm bg-hotlink-purple-714da6 py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                            onClick={() => addQuestionToTopicFunc()}
                        >
                            Lưu
                        </button>
                    ) : !requestEdit ? (
                        <button
                            className="w-24 text-sm bg-hotlink-purple-714da6 py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                            onClick={() => setRequestEdit(true)}
                        >
                            Chỉnh sửa
                        </button>
                    ) : (
                        <button
                            className="w-24 text-sm bg-hotlink-purple-714da6 py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
                 hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
                 active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                            onClick={() => setOpenEdit(true)}
                        >
                            Lưu
                        </button>
                    )}
                    <button
                        className="w-24 text-sm bg-hotlink-red-d73d32 py-[0.4em] px-[0.4em] text-white border-[3px] border-solid border-white rounded-[0.4em] shadow-[0.1em_0.1em]
             hover:translate-x-[-0.05em] hover:translate-y-[-0.05em] hover:shadow-[0.15em_0.15em]
             active:translate-x-[0.05em] active:translate-y-[0.05em] active:shadow-[0.05em_0.05em]"
                        onClick={() =>
                            addQuestionEdit ? props.removeQuestion(props.questionEdit.questionId) : setOpenDelete(true)
                        }
                    >
                        Xóa
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default QuestionPart1;
