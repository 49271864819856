import { useState, useEffect } from 'react';

import { RightIcon, LeftIcon } from '~/components/Icons';
import FilterExamItem from './FilterExamItem';

import { useSelector, useDispatch } from 'react-redux';
import { setQueryParams } from '~/redux/listExamSlice';

import { listTopic } from '../../../api/topicApi/topicApi';
import axiosPrivate from '../../../api/axios'

function FilterExam() {
    const [isLoading, setIsLoading] = useState(false);
    const controller = new AbortController();
    const [listTopicData, setListTopicData] = useState([]);
    const [totalPage, setTotalPage] = useState();

    const dispatch = useDispatch();
    const queryParams = useSelector((state) => state.listExam.queryParams);

    const [currentPage, setCurrentPage] = useState(1);

    const getListTopic = async () => {
        try {
            setIsLoading(true);

            const isMobile = window.innerWidth <= 767;
            let modifiedParams = {
                ...queryParams,
                page: currentPage - 1, 
            };

            if (isMobile) {
                modifiedParams.size = 6;
            }

            console.log(modifiedParams);

            const responseData = await listTopic(axiosPrivate, modifiedParams, {
                signal: controller.signal,
            });

            setListTopicData(responseData.responseData.listTopic);
            setTotalPage(responseData.responseData.totalPage);
            console.log(responseData.responseData.listTopic);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getListTopic();
        const page = queryParams.page ? parseInt(queryParams.page, 10) + 1 : 1;
        setCurrentPage(page);

        return () => {
            controller.abort();
        };
    }, [queryParams]);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(setQueryParams({ ...queryParams, page: pageNumber - 1 }));
    };

    const nextPage = () => {
        if (currentPage < totalPage) {
            setCurrentPage(currentPage + 1);
            dispatch(setQueryParams({ ...queryParams, page: currentPage }));
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            dispatch(setQueryParams({ ...queryParams, page: currentPage - 2 }));
        }
    };

    return (
        <div className="flex flex-col justify-start items-stretch gap-6 lg:px-20 px-5">
            <div className="w-full flex flex-row flex-wrap justify-start items-stretch gap-6">
                {listTopicData && totalPage !== 0 && totalPage
                    ? listTopicData.map((topic) => <FilterExamItem key={topic.topicId} topic={topic} />)
                    : null}
            </div>

            {totalPage && totalPage !== 0 ? (
                <div className="flex flex-row justify-start items-center gap-[10px] p-2">
                    {currentPage !== 1 ? (
                        <button
                            className=" flex items-center justify-center h-8 w-8 p-[10px] rounded-sm border-solid border border-hotlink-white-d9d9d9 bg-white"
                            onClick={() => prevPage()}
                        >
                            <LeftIcon />
                        </button>
                    ) : null}

                    {totalPage &&
                        Array.from({ length: totalPage }).map((_, index) => {
                            if (
                                index === 0 ||
                                index === totalPage - 1 ||
                                (index >= currentPage - 2 && index <= currentPage + 2)
                            ) {
                                return (
                                    <button
                                        key={index}
                                        className={`flex items-center justify-center h-8 w-8 p-[10px] rounded-sm border-solid border bg-white  ${
                                            index + 1 === currentPage
                                                ? 'border-hotlink-blue-309'
                                                : ' border-hotlink-white-d9d9d9'
                                        }`}
                                        onClick={() => changePage(index + 1)}
                                    >
                                        <span
                                            className={`text-sm ${
                                                index + 1 === currentPage
                                                    ? 'text-hotlink-blue-309'
                                                    : 'text-hotlink-black-085'
                                            }`}
                                        >
                                            {index + 1}
                                        </span>
                                    </button>
                                );
                            }
                            if (
                                index === 1 ||
                                index === totalPage - 2 ||
                                (index >= currentPage - 2 && index <= currentPage + 2)
                            ) {
                                return (
                                    <span
                                        key={index}
                                        className="flex items-center justify-center h-8 w-8 p-[7px] text-sm text-hotlink-black-025 text-center tracking-[2px] leading-[2.29]"
                                    >
                                        ...
                                    </span>
                                );
                            }
                            return null;
                        })}

                    {totalPage && currentPage !== totalPage ? (
                        <button
                            className=" flex items-center justify-center h-8 w-8 p-[10px] rounded-sm border-solid border border-hotlink-white-d9d9d9 bg-white"
                            onClick={() => nextPage()}
                        >
                            <RightIcon />
                        </button>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

export default FilterExam;
