import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Layout, Menu, ConfigProvider, Drawer, Tooltip, theme } from 'antd';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { NAVIGATION_ADMIN_MENU } from '../../constants/menu';

import { Header as HeaderContent } from './components';
import { CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useAppConfigStore } from '../../stores';

const { Header, Content, Sider, Footer } = Layout;
const { darkAlgorithm, defaultAlgorithm } = theme;

function AdminLayout() {
    const { t: translate } = useTranslation('common');
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const { theme: appTheme } = useAppConfigStore();

    // States
    const [collapsed, setCollapsed] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('');

    // Methods
    const handleMenuClick = (item) => {
        setActiveKey(item.key);
        const selectedParentItem = NAVIGATION_ADMIN_MENU.find((menuItem) =>
            menuItem.children
                ? menuItem.children.find((itemChild) => itemChild.key === item.key)
                : menuItem.key === item.key,
        );

        if (selectedParentItem?.children) {
            navigate(selectedParentItem.children.find((menuItem) => menuItem.key === item.key)?.link);
        } else navigate(selectedParentItem?.link || '/');
    };

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    // Dark mode settings
    const isDarkMode = appTheme === 'dark';

    return (
        <ConfigProvider
            theme={{
                algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                token: {
                    colorPrimary: isDarkMode ? '#1890ff' : '#5b21b6',
                    colorText: isDarkMode ? '#d1d5db' : '#4b5563',
                    colorBgContainer: isDarkMode ? '#1f2937' : '#ffffff',
                    colorBgLayout: isDarkMode ? '#111827' : '#f4f4f4',
                    colorBgMenuItemSelected: isDarkMode ? '#374151' : '#c7d2fe',
                    colorTextMenuItemSelected: isDarkMode ? '#ffffff' : '#3730a3',
                    colorBorder: isDarkMode ? '#374151' : '#d1d5db',
                    borderRadius: 8,
                },
                components: {
                    Menu: {
                        fontWeightStrong: 600,
                        fontSize: 16,
                        itemPaddingInline: 20,
                        itemHoverBg: isDarkMode ? '#4b5563' : '#c7d2fe',
                        itemHoverColor: isDarkMode ? '#ffffff' : '#3730a3',
                    },
                },
            }}
        >
            <Layout className={`${isDarkMode && 'dark'} min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
                {/* HEADER */}
                <Header
                    className={`flex items-center justify-between px-6 py-4 shadow-md transition-all ${
                        isDarkMode
                            ? 'bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700'
                            : 'bg-gradient-to-r from-blue-600 to-purple-700'
                    }`}
                >
                    <HeaderContent toggleDrawer={toggleDrawer} isDesktop={isDesktop} />
                </Header>

                {/* LAYOUT */}
                <Layout>
                    {/* SIDEBAR */}
                    {isDesktop ? (
                        <Sider
                            width={250}
                            collapsed={collapsed}
                            className={`relative shadow-md transition-all duration-300 ${
                                isDarkMode ? 'bg-gray-800' : 'bg-white'
                            }`}
                        >
                            <Menu
                                mode="inline"
                                selectedKeys={[activeKey]}
                                onClick={handleMenuClick}
                                className="h-full py-4 font-roboto"
                                style={{ borderInlineEnd: 0 }}
                                theme={isDarkMode ? 'dark' : 'light'}
                            >
                                {NAVIGATION_ADMIN_MENU.map((item) => {
                                    if (item.children) {
                                        return (
                                            <Menu.SubMenu
                                                key={item.key}
                                                title={translate(`admin.${item.name}`)}
                                                icon={item.icon}
                                                className="rounded-md"
                                            >
                                                {item.children.map((childItem) => (
                                                    <Menu.Item key={childItem.key} className="rounded-md">
                                                        {translate(`admin.${childItem.name}`)}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.SubMenu>
                                        );
                                    }
                                    return (
                                        <Menu.Item key={item.key} icon={item.icon} className="rounded-md">
                                            {translate(`admin.${item.name}`)}
                                        </Menu.Item>
                                    );
                                })}
                            </Menu>

                            {/* Toggle Sidebar Button */}
                            <div
                                className={`sticky bottom-0 left-0 flex items-center justify-center p-3 shadow-md transition-all ${
                                    isDarkMode
                                        ? 'bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700'
                                        : 'bg-gradient-to-r from-blue-600 to-purple-700 hover:bg-gray-200'
                                }`}
                            >
                                <Tooltip title={collapsed ? 'Mở menu' : 'Thu nhỏ menu'}>
                                    <div onClick={() => setCollapsed(!collapsed)} className="cursor-pointer">
                                        {collapsed ? (
                                            <MenuUnfoldOutlined className="text-xl text-white" />
                                        ) : (
                                            <MenuFoldOutlined className="text-xl text-white" />
                                        )}
                                    </div>
                                </Tooltip>
                            </div>
                        </Sider>
                    ) : (
                        <Drawer
                            title={null}
                            closeIcon={null}
                            placement="left"
                            open={drawerVisible}
                            styles={{ body: { padding: '0px' } }}
                        >
                            <div>
                                <div className="flex w-full flex-row items-center justify-between bg-gradient-to-r from-blue-600 to-purple-700 px-4 py-3">
                                    <img src="/logo-me-u.png" alt="MeU" className="h-10 w-10" />
                                    <CloseOutlined
                                        onClick={toggleDrawer}
                                        className="cursor-pointer text-xl text-white transition-all hover:text-gray-300"
                                    />
                                </div>
                                <Menu
                                    mode="inline"
                                    selectedKeys={[activeKey]}
                                    onClick={(item) => {
                                        handleMenuClick(item);
                                        toggleDrawer();
                                    }}
                                    className="font-beVietnamPro"
                                >
                                    {NAVIGATION_ADMIN_MENU.map((item) => {
                                        if (item.children) {
                                            return (
                                                <Menu.SubMenu
                                                    key={item.key}
                                                    title={translate(`admin.${item.name}`)}
                                                    icon={item.icon}
                                                >
                                                    {item.children.map((childItem) => (
                                                        <Menu.Item key={childItem.key}>
                                                            {translate(`admin.${childItem.name}`)}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.SubMenu>
                                            );
                                        }
                                        return (
                                            <Menu.Item key={item.key} icon={item.icon}>
                                                {translate(`admin.${item.name}`)}
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu>
                            </div>
                        </Drawer>
                    )}

                    {/* CONTENT */}
                    <Layout>
                        <Content
                            className={`rounded-lg p-6 shadow-sm ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        >
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
                <Footer />
            </Layout>
        </ConfigProvider>
    );
}

export default AdminLayout;
