import { TimeLineCheckIcon } from '~/components/Icons';
import './ToeicModal.scss';
import { Timeline } from 'antd';
import React, { useRef, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPart, setCurrentQuestion } from '~/redux/topicSlice';

function ToeicModal(props) {
    const modalRef = useRef();

    const dispatch = useDispatch();
    const listPart = useSelector((state) => state.topic.listPart);
    const listFlag = useSelector((state) => state.topic.listFlag);
    const listChoice = useSelector((state) => state.topic.listChoice);
    const listQuestion = useSelector((state) => state.topic.listQuestion);
    const startPart = useSelector((state) => state.topic.startPart);

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            props.onClose();
        }
    };

    const btnQuestion = (partId, questionId) => {
        if (startPart.includes(partId)) {
            if (listQuestion) {
                const foundQuestion = listQuestion.find(
                    (item) =>
                        item.questionId === questionId ||
                        item.questionGroup?.some((group) => group.questionId === questionId),
                );
                dispatch(setCurrentQuestion(foundQuestion.questionId));
                dispatch(setCurrentPart(partId));
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [props]);

    if (!props.isOpen) return null;

    const getQuestionId = (questionStt) => {
        if (listQuestion) {
            for (const question of listQuestion) {
                if (question.questionGroup) {
                    if (question.numberical === questionStt) {
                        return question.questionGroup[0].questionId;
                    } else if (
                        question.numberical < questionStt &&
                        question.numberical + question.questionGroup.length - 1 >= questionStt
                    ) {
                        return question.questionGroup[questionStt - question.numberical].questionId;
                    }
                } else if (question.numberical === questionStt) {
                    return question.questionId;
                }
            }
        }
        return null;
    };

    function questionOfPart() {
        if (listPart) {
            let questionCount = 0;
            return listPart.map((part, partIndex) => (
                <Timeline.Item dot={<TimeLineCheckIcon />} key={partIndex}>
                    <div className="flex flex-col gap-4">
                        <div className="flex justify-start items-start font-BeVietnamMedium text-base text-hotlink-black-262626 ">
                            {part.partName.slice(0, 6)}
                        </div>
                        <div className="flex flex-wrap flex-row justify-start items-start gap-2">
                            {Array.from({ length: part.totalQuestion }, (_, index) => {
                                questionCount++;
                                const questionStt = questionCount;
                                const questionId = getQuestionId(questionStt);

                                return (
                                    <button
                                        key={questionStt}
                                        className={`w-8 h-8 flex justify-center items-center p-[1px] rounded-sm  ${
                                            listFlag.includes(questionId)
                                                ? 'bg-hotlink-yellow-f6ae2d'
                                                : listChoice.some((item) => item.questionId === questionId)
                                                ? 'bg-hotlink-blue-309'
                                                : 'border-hotlink-black-1a1a1a border-solid border-[1px]'
                                        }`}
                                        onClick={() => (questionId ? btnQuestion(part.partId, questionId) : null)}
                                    >
                                        <span
                                            className={`font-BeVietnamRegular text-xs text-center ${
                                                !listChoice.some((item) => item.questionId === questionId) &&
                                                !listFlag.includes(questionId)
                                                    ? 'text-black'
                                                    : 'text-white'
                                            }`}
                                        >
                                            {questionStt}
                                        </span>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </Timeline.Item>
            ));
        }
        return null;
    }

    return (
        <div
            className={`absolute right-4 lg:w-[37.7%] w-4/5 h-[466px] flex flex-col items-start gap-6 py-6 pl-10 pr-8 rounded-lg shadow-[-8px_8px_40px_0_rgba(0,0,0,0.15)] bg-white overflow-y-auto ${props.scrollModal}`}
            onClick={handleOutsideClick}
            ref={modalRef}
        >
            <Timeline>
                {questionOfPart()}
                <Timeline className="h-0" dot={<></>}></Timeline>
            </Timeline>
        </div>
    );
}

export default ToeicModal;
