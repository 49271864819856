import { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

const NavigationMenu = ({ children }) => {
    const [menuList, setMenuList] = useState([]);

    const updateActiveItem = ({ id, isActive }) => {
        setMenuList((prev) =>
            prev.map((item) => ({
                ...item,
                active: item.id === id ? isActive : item.active,
            })),
        );
    };

    return (
        <NavigationContext.Provider value={{ menuList, setMenuList, updateActiveItem }}>
            <div className="relative h-full w-full">{children}</div>
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

export default NavigationMenu;
