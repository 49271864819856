import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useNavigation } from '../NavigationMenu';
import { NavigationMenuTrigger } from './components';
import cn from 'classnames';

const NavigationMenuItem = ({ id, triggerElement, className, children }) => {
    const { menuList, updateActiveItem } = useNavigation();
    const timeOutRef = useRef(null);
    const isHoverContentRef = useRef(false);

    // Quản lý trạng thái active từ navigation
    const isActive = useMemo(() => menuList.find((item) => item.id === id)?.active, [menuList, id]);

    // State để điều khiển việc render menu content
    const [shouldRender, setShouldRender] = useState(isActive);

    // Khi isActive thay đổi, update shouldRender
    useEffect(() => {
        if (isActive) {
            // Khi mở menu, render ngay
            setShouldRender(true);
        } else {
            // Khi đóng, delay unmount để animation "flip up" hoàn thành (300ms)
            const timeoutId = setTimeout(() => {
                setShouldRender(false);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [isActive]);

    const cancelClose = useCallback(() => {
        if (timeOutRef.current) {
            clearTimeout(timeOutRef.current);
            timeOutRef.current = null;
        }
    }, []);

    const handleClose = useCallback(() => {
        cancelClose();
        timeOutRef.current = setTimeout(() => {
            if (!isHoverContentRef.current) {
                updateActiveItem({ id, isActive: false });
            }
        }, 500);
    }, [cancelClose, id, updateActiveItem]);

    const handleMouseEnter = () => {
        if (!children) return;
        cancelClose();
        isHoverContentRef.current = true;
        updateActiveItem({ id, isActive: true });
    };

    const handleMouseLeave = () => {
        if (!children) return;
        isHoverContentRef.current = false;
        handleClose();
    };

    return (
        <li className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <NavigationMenuTrigger>{triggerElement}</NavigationMenuTrigger>
            {shouldRender && (
                <div
                    key={isActive}
                    className={cn(
                        isActive ? 'animate-flip-down' : 'animate-flip-down animate-reverse',
                        'absolute inset-0 top-full transition-all duration-300 animate-duration-300 animate-ease-out',
                    )}
                    onMouseEnter={cancelClose}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="relative min-h-[128px]">
                        <div className="absolute left-0 top-0 h-auto w-full rounded-b-xl border-t-2 border-hotlink-yellow-f6ae2d bg-gradient-to-r from-white to-gray-50 p-8 px-20 shadow-sm">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
};

export default NavigationMenuItem;
