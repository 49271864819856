//Core
import React, { useState, useEffect } from 'react';
import { message, Upload, Button } from "antd";
import { InboxOutlined } from '@ant-design/icons';

//App
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';

//Internal
import { createTopicByExcel } from '../../../../../../../api/addexamApi/addexamApi';
import { Dragger } from './UploadStructure.constant';

//Component
const UploadStructure = ({ imageUrl,
    topicId,
    fileBlob,
    uploadedFileName,
    setFileBlob,
    setUploadedFileName,
    onTopicIdUpdate }) => {

    //States
    const axiosPrivate = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    useEffect(() => {
        if (topicId) {
            setUploadSuccess(true);
        }
    }, [topicId]);

    //Method
    const beforeUploadFile = (file, allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']) => {
        const isAllowedType = allowedTypes.includes(file.type);
        if (!isAllowedType) {
            message.error('Chỉ được upload file excel');
        }
        return isAllowedType;
    };

    const handleUploadExcel = async () => {
        if (!fileBlob) {
            message.warning('Vui lòng chọn file Excel trước khi upload!');
            return;
        }
        setUploading(true);
        const formDataexcel = new FormData();
        formDataexcel.append('file', fileBlob);

        try {
            const response = await createTopicByExcel(axiosPrivate, formDataexcel, imageUrl, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            onTopicIdUpdate(response.responseData.topicId);
            message.success('Upload excel thành công');
            setUploadSuccess(true);
        } catch (error) {
            message.error('Upload excel thất bại');
        } finally {
            setUploading(false);
        }
    };

    const handleChangeExcel = (info) => {
        const { file } = info;
        if (!imageUrl) {
            message.warning('Vui lòng chọn ảnh đại diện trước khi upload Excel!');
            return;
        }

        if (file.status === 'done' || file.status === 'uploading') {
            setUploadedFileName(file.name);
            setFileBlob(file.originFileObj);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center space-y-6 mt-12 text-center">
            <h3 className="text-black-600 font-bold mb-4 text-xl">Bước 2: Upload cấu trúc đề thi</h3>

            <Dragger
                name="file"
                className="w-80 h-48 border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col justify-center items-center"
                beforeUpload={(file) => beforeUploadFile(file, [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel'
                ])}
                onChange={handleChangeExcel}
                showUploadList={false}
            >
                {uploadedFileName ? (
                    <p className="text-lg">{uploadedFileName}</p>
                ) : (
                    <div className="flex flex-col items-center">
                        <InboxOutlined className="text-3xl" />
                        <p className="mt-4">Click hoặc kéo thả file</p>
                    </div>
                )}
            </Dragger>

            {!uploadSuccess && (
                <Button
                    type="primary"
                    onClick={handleUploadExcel}
                    className="bg-blue-500 text-white w-40 rounded-lg"
                    loading={uploading}
                >
                    Upload
                </Button>
            )}
        </div>
    );
};

export default UploadStructure;
