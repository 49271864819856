import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { useNavigate } from 'react-router-dom';

const Loading = () => {
    const navigate = useNavigate();
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercent((prev) => {
                return prev + 1;
            });
        }, 30);
        return () => clearInterval(interval);
    }, [navigate]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-blue-400 to-blue-200">
            <div className="absolute inset-0 overflow-hidden">
                <div className="animate-cloud-move absolute left-[-30%] top-8 h-28 w-48 rounded-full bg-white opacity-70 blur-md"></div>

                <div className="animate-cloud-move absolute right-[-20%] top-20 h-16 w-24 rounded-full bg-white opacity-60 blur-md delay-300"></div>

                <div className="animate-cloud-move absolute left-[-25%] top-32 h-20 w-32 rounded-full bg-white opacity-70 blur-md delay-500"></div>

                <div className="animate-cloud-move absolute right-[-35%] top-48 h-24 w-40 rounded-full bg-white opacity-60 blur-md delay-700"></div>

                <div className="animate-cloud-move delay-900 absolute left-[-20%] top-60 h-16 w-24 rounded-full bg-white opacity-70 blur-md"></div>
            </div>

            <div className="relative z-10 flex flex-col items-center">
                <div className="scale-[3]">
                    <img src="/kiki.gif" alt="Kiki Loading" className="animate-float h-16 w-16" />
                </div>

                <div className="mt-4 w-64">
                    <Progress
                        percent={percent}
                        showInfo={false}
                        strokeColor={{
                            '0%': '#93C5FD', // Blue-300
                            '100%': '#3B82F6', // Blue-500
                        }}
                        strokeWidth={8}
                        trailColor="rgba(255, 255, 255, 0.3)"
                    />
                </div>

                <div className="mt-2 font-beVietnamPro">
                    <p className="animate-pulse text-lg font-semibold text-white drop-shadow-lg">Đang tải...</p>
                </div>
            </div>
        </div>
    );
};

export default Loading;
