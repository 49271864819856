import './TableAdmin.scss';

import React, { useEffect, useState } from 'react';
import { Table, Space, Switch, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EditIcon, TrashIcon } from '~/components/Icons';

import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { setOptionAdminList } from '~/redux/adminSlice';

import { listTopic, enableTopic } from '~/api/topicApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

import AddExamModal from '../Exam/AddExam/AddExamModal/AddExamModal';

const TableAdmin = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const controller = new AbortController();

    const optionAdminList = useSelector((state) => state.admin.optionAdminList);

    const columns = [
        {
            title: 'STT',
            dataIndex: 'number',
            align: 'right',
        },
        {
            title: 'Tên đề thi',
            dataIndex: 'topicName',
        },
        {
            title: 'Số câu',
            dataIndex: 'numberQuestion',
            align: 'right',
        },
        {
            title: 'Hình thức kiểm tra',
            dataIndex: 'testForm',
        },
        {
            title: 'Thang điểm',
            dataIndex: 'pointLadder',
            align: 'right',
        },

        {
            title: 'Thời gian làm bài',
            dataIndex: 'workTime',
            align: 'right',
        },
        {
            title: 'Hiển thị',
            dataIndex: 'enable',
            align: 'center',
            render: (display, record) => (
                <Switch
                    className="bg-hotlink-gray-bfbfbf"
                    defaultChecked={display}
                    onChange={(checked) => handleSwitchChange(checked, record)}
                ></Switch>
            ),
        },
        {
            title: 'Thao tác',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Popover content={<div>Chỉnh sửa</div>}>
                        <button
                            className="flex flex-row gap-3 justify-center items-center"
                            onClick={() => {
                                props.setChangeSource(record);
                                props.setOpenEdit(true);
                            }}
                        >
                            <EditIcon className="cursor-pointer" />
                        </button>
                    </Popover>
                    <Popover content={<div>Xóa</div>}>
                        <button
                            className="flex flex-row gap-3 justify-center items-center"
                            onClick={() => {
                                props.setChangeSource(record);
                                props.setOpenDelete(true);
                            }}
                        >
                            <TrashIcon className="cursor-pointer" />
                        </button>
                    </Popover>
                </Space>
            ),
        },
    ];

    const handleSwitchChange = async (checked, record) => {
        try {
            dispatch(startLoading());
            const response = await enableTopic(
                record.key,
                {
                    params: { enable: checked },
                    signal: controller.signal,
                },
                axiosPrivate,
            );
            if (response?.status === 'OK') {
                dispatch(setStatusMessage('Success'));
                dispatch(setErrMessage(response.message));
            } else {
                dispatch(setStatusMessage('Fail'));
                dispatch(setErrMessage(response.message));
            }
        } catch (error) {
            dispatch(setStatusMessage('Fail'));
            dispatch(setErrMessage(error));
        } finally {
            dispatch(stopLoading());
        }
    };

    const getListTopic = async (page, pageSize) => {
        try {
            dispatch(startLoading());

            const setNewOptionAdmin = { ...optionAdminList, size: props.state.sizePage };
            const response = await listTopic(axiosPrivate, setNewOptionAdmin, {
                signal: controller.signal,
            });
            if (response?.status === 'OK') {
                props.setState((prevState) => ({ ...prevState, totalRecords: response.responseData.totalRecords }));

                const newData = response.responseData.listTopic.map((item, i) => ({
                    key: item.topicId,
                    number: (page - 1) * pageSize + i + 1,
                    packId: item.packId,
                    topicId: item.topicId,
                    topicName: item.topicName,
                    topicImage: item.topicImage,
                    topicDescription: item.topicDescription,
                    topicType: item.topicType,
                    listPart: item.listPart,
                    numberQuestion: item.numberQuestion,
                    testForm: `Online`,
                    pointLadder: '990',
                    workTime: item.workTime,
                    enable: item.enable,
                    startTime: item.startTime,
                    endTime: item.endTime,
                }));

                props.setState((prevState) => ({ ...prevState, dataSource: newData }));
            }
        } catch (error) {
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (optionAdminList.page === undefined) {
            props.setState((prevState) => ({ ...prevState, currentPage: 1 }));
            getListTopic(1, props.state.sizePage);
        } else {
            getListTopic(optionAdminList.page + 1, props.state.sizePage);
        }

        return () => { };
    }, [optionAdminList, props.updateTable]);

    const handlePageChange = (page, pageSize) => {
        props.setState((prevState) => ({ ...prevState, sizePage: pageSize }));
        props.setState((prevState) => ({ ...prevState, currentPage: page }));
        dispatch(setOptionAdminList({ ...optionAdminList, page: page - 1 }));
    };

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [props.state.dataSource]);

    return (
        <div className="w-full">
            <Table
                className="format-table"
                columns={columns}
                dataSource={props.state.dataSource}
                pagination={{
                    defaultPageSize: 10,
                    current: props.state.currentPage,
                    showSizeChanger: true,
                    position: ['bottomRight'],
                    pageSizeOptions: ['5', '10', '20'],
                    total: props.state.totalRecords,
                    locale: { items_per_page: '' },
                    onChange: handlePageChange,
                }}
            />

            {props.isOpenModal ? <AddExamModal closeModal={props.closeModal} /> : null}
        </div>
    );
};
export default TableAdmin;
