import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';

import Avatar from '~/components/Avatar';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { SendIcon } from '~/components/Icons';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import { setErrMessage, setStatusMessage } from '~/redux/errMessageSlice';
import { addCommentToComment, addCommentToTopic, addCommentToPost } from '~/api/commentApi/commentApi';

const { TextArea } = Input;
function AddComment(props) {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        dispatch(setErrMessage(''));
        dispatch(setStatusMessage(''));
    }, [value]);

    function validate() {
        if (value === '') {
            dispatch(setStatusMessage('Comment fail'));
            dispatch(setErrMessage('Please add comment'));
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let response = undefined;
        if (validate()) {
            try {
                dispatch(startLoading());

                if (props.commentId) {
                    response = await addCommentToComment(
                        axiosPrivate,
                        props.commentId,
                        JSON.stringify({
                            commentContent: value,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        },
                    );
                } else if (props.topicId) {
                    response = await addCommentToTopic(
                        axiosPrivate,
                        props.topicId,
                        JSON.stringify({
                            commentContent: value,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        },
                    );
                } else if (props.postId) {
                    response = await addCommentToPost(
                        axiosPrivate,
                        props.postId,
                        JSON.stringify({
                            commentContent: value,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        },
                    );
                }

                if (response?.violations === 401) {
                    dispatch(setStatusMessage('Comment fail'));
                    dispatch(setErrMessage('Unauthorized'));
                } else if (response?.status === 'fail') {
                    dispatch(setStatusMessage('Comment fail'));
                    dispatch(setErrMessage(response?.message));
                } else {
                    dispatch(setStatusMessage('Comment successful'));
                    dispatch(setErrMessage('Successful'));
                    if (props.commentId) {
                        props.closeAddComment();
                    }
                    setValue('');
                }
            } catch (err) {
                console.log(err);
                dispatch(setStatusMessage('Comment fail'));
                dispatch(setErrMessage('Comment Failed'));
            } finally {
                dispatch(stopLoading());
            }
        }
    };

    return (
        <div className="w-full bg-white lg:px-4 px-2 pb-4 gap-4 flex flex-col font-BeVietNamRegular text-xs">
            <div className="flex flex-row gap-3 items-start w-full">
                <Avatar
                    className="rounded-1/2 cursor-pointer bg-hotlink-white-bab9ba h-8 w-8 min-w-[32px]"
                    src={user && user.User.avatar ? user.User.avatar : ''}
                    alt="user"
                />
                <div className="flex flex-col gap-2 w-full">
                    <form
                        className="flex flex-row gap-1 bg-hotlink-gray-f6f6f6 px-3 py-2 rounded-xl items-end"
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <TextArea
                            rows={2}
                            placeholder="Enter your comment"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        <button className="flex-row gap-2 items-center bg-hotlink-purple-714da6 stroke-white border border-slate-200 col-span-2 flex justify-center rounded-lg p-1 duration-300 ">
                            <SendIcon />
                            <span className="text-base font-BeVietnamMedium text-white">Gửi</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default AddComment;
