import FlashCardBreadCrumb from "~/components/FlashCard/FlashCardBreadCrumb";
import FlashCardList from "~/components/FlashCard/FlashCardList";


function FlashCard() {
    return (
        <div className="bg-hotlink-purple-f7f3ff pb-[82px]">
            <div className="flex flex-col gap-[18px] pt-20 lg:mx-20 mx-5 min-w-[100px]">
                <FlashCardBreadCrumb />
                <FlashCardList/>
            </div>
        </div>
    );
}

export default FlashCard;
