// Core
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Antd
import { Popover } from 'antd';

// App
import UnitedKingdom from '~/assets/svg/flags/UnitedKingdom.svg';
import Vietnam from '~/assets/svg/flags/Vietnam.svg';

// Constant
const languageList = {
    vn: {
        icon: Vietnam,
        title: 'Vietnam',
    },
    en: {
        icon: UnitedKingdom,
        title: 'English',
    },
};

// Component
const LanguagePicker = ({ rounded = false }) => {
    // Hooks
    const { i18n } = useTranslation('common');

    // States
    const [currentLanguage, setCurrentLanguage] = useState('vn');

    // Methods
    const handleLanguageChange = (language) => {
        setCurrentLanguage(language);
        i18n.changeLanguage(language);
    };

    // Template
    return (
        <Popover
            content={
                <ul className={`${rounded && 'rounded-full'}`}>
                    {Object.entries(languageList).map(([key, value]) => (
                        <li
                            key={key}
                            onClick={() => handleLanguageChange(key)}
                            className={`flex cursor-pointer items-center gap-2 p-2 ${
                                currentLanguage === key && 'bg-gray-200'
                            } hover:bg-gray-200`}
                        >
                            <img src={value.icon} alt="united_kingdom" className="h-8 w-8" />
                            <div className="font-BeVietNamMedium text-base font-medium">{value.title}</div>
                        </li>
                    ))}
                </ul>
            }
        >
            <div className="flex flex-col items-center justify-center">
                <img src={languageList[currentLanguage].icon} alt="united_kingdom" className="h-8 w-8" />
            </div>
        </Popover>
    );
};

export default LanguagePicker;
