// Core
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Divider, Popover } from 'antd';
import {
    ClusterOutlined,
    FileSearchOutlined,
    HomeOutlined,
    IdcardOutlined,
    LoginOutlined,
    UserOutlined,
} from '@ant-design/icons';

// App
import { useAuthStore } from '~/stores/modules/auth';
import { useLogout } from '~/hooks/modules/auth';
import paths from '../../../constants/paths';

const featureListByRole = {
    admin: [
        {
            name: 'Manager Application',
            to: '/admin/statistic',
            icon: <ClusterOutlined size={50} />,
        },
        {
            name: 'Profile',
            to: '/profile',
            icon: <IdcardOutlined size={50} />,
        },
        {
            name: 'Flash Cards',
            to: paths.flashCard,
            icon: <FileSearchOutlined size={50} />,
        },
    ],
    user: [
        {
            name: 'Home',
            to: '/',
            icon: <HomeOutlined size={50} />,
        },
        {
            name: 'Profile',
            to: '/profile',
            icon: <IdcardOutlined size={50} />,
        },
    ],
};

// Component
const UserInformation = () => {
    const { t: translation } = useTranslation('common');
    const { mutate: logOut } = useLogout();
    const { userInfo } = useAuthStore();

    const handleLogOut = async () => {
        await logOut();
    };

    const role = useMemo(
        () => (userInfo?.role === 'USER' ? 'user' : userInfo?.role === 'ADMIN' ? 'admin' : 'user'),
        [userInfo],
    );

    // Template
    return (
        <div>
            <Popover
                content={
                    <ul className="flex flex-col items-center justify-center p-0 transition-colors duration-300">
                        {featureListByRole[role].map((featureItem) => (
                            <li key={featureItem.to} className="w-full cursor-pointer px-4 py-2 hover:bg-gray-200">
                                <Link to={featureItem.to} className="space-x-4">
                                    {featureItem.icon}
                                    <span className="text-base font-medium">{featureItem.name}</span>
                                </Link>
                            </li>
                        ))}

                        <Divider style={{ margin: '2px 0px' }} />
                        <li
                            className="group w-full cursor-pointer space-x-4 px-4 py-2 transition-colors duration-300 hover:bg-gray-200"
                            onClick={handleLogOut}
                        >
                            <LoginOutlined className="group-hover:text-red-400" size={50} />
                            <span className="text-base font-medium group-hover:text-red-400">
                                {translation('log_out')}
                            </span>
                        </li>
                    </ul>
                }
                trigger="hover"
            >
                <Avatar
                    src={
                        userInfo.avatar ??
                        'https://rukminim2.flixcart.com/image/850/1000/xif0q/poster/s/d/v/medium-anime-girls-fantasy-anime-girls-hd-matte-finish-poster-original-imagh8k9taqepyzs.jpeg?q=20&crop=false'
                    }
                    size={40}
                    icon={<UserOutlined />}
                    className="border-2 border-[#6c47b3]"
                />
            </Popover>
        </div>
    );
};

export default UserInformation;
