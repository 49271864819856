import './Navbar.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentUrl = `${location.pathname}`;
    const parts = currentUrl.split('/');

    const desiredPart = `${parts[0]}/${parts[1]}`;

    const title = props.title;

    const { t } = useTranslation('common');
    return (
        <div
            className={`flex items-center lg:h-full h-12 px-5 relative ${
                props.href === desiredPart
                    ? `lg:border-hotlink-yellow-f6ae2d lg:border-b-2 lg:border-r-0 lg:bg-white border-r-2 border-x-hotlink-blue-309 bg-hotlink-white-d7cceb`
                    : ''
            }`}
        >
            <button
                onClick={() => {
                    navigate(props.href);
                    props.closeMenu && props.closeMenu();
                }}
                className={`navbar-title text-base font-BeVietnamSemiBold  text-left leading-[22px] ${
                    props.href === desiredPart
                        ? ` lg:text-hotlink-yellow-f6ae2d text-hotlink-blue-309`
                        : ` text-hotlink-black-1a1a1a`
                }`}
            >
                {t(title)}
            </button>
        </div>
    );
};

export default Navbar;
