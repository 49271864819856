import './ToeicExamHeader.scss';
import { Link } from 'react-router-dom';
import config from '~/config';
import images from '~/assets/images';
import ModalBtn from '~/assets/svg/ModalExam.svg';
import { useEffect, useState } from 'react';
import ToeicModal from '../ToeicModal/ToeicModal';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { Button, Result, Spin } from 'antd';

import { useSelector } from 'react-redux';
import { inforTopic } from '~/api/topicApi';
import { useNavigate } from 'react-router-dom';

function ToeicExamHeader(props) {
    const navigate = useNavigate();

    const { width } = useWindowSize();
    const controller = new AbortController();
    const axiosPrivate = useAxiosPrivate();

    const [sticky, setSticky] = useState('h-24');
    const [scrollModal, setScrollModal] = useState('top-16');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState();

    const [submitDone, setSubmitDone] = useState(false);

    const user = useSelector((state) => state.user.user);

    const [timerInterval, setTimerInterval] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [startTime, setStartTime] = useState(Date.now());

    const location = useLocation();
    const topicId = new URLSearchParams(location.search).get('id');

    const listChoice = useSelector((state) => state.topic.listChoice);
    const listQuestion = useSelector((state) => state.topic.listQuestion);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isSubmit, setIsSubmit] = useState(false);

    const changeSubmit = () => {
        setIsSubmit(!isSubmit);
    };

    const checkQuestionToChoice = () => {
        return listQuestion.every((question) => listChoice.some((choice) => choice.questionId === question.questionId));
    };

    const [mockTestID, setMockTestID] = useState(null);

    const submitResult = async () => {
        try {
            setIsLoading(true);
            const endTime = Date.now();
            const timeTaken = (endTime - startTime) / 1000;

            let scoreTopic = 0;

            const response = await axiosPrivate.post(
                '/mockTest/create',
                JSON.stringify({
                    score: scoreTopic,
                    time: formatDurationDisplay(timeTaken),
                    topic_id: topicId,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                },
            );

            if (response.data.status === 'OK') {
                const mockTestID = response.data.responseData.mockTestID;
                setMockTestID(mockTestID);
                const answerIdList = listChoice.map((item) => item.answerId);

                const responseAdd = await axiosPrivate.post(
                    '/mockTest/' + response.data.responseData.mockTestID + '/submitResult',
                    answerIdList,
                );
                if (responseAdd.data.status === 'OK') {
                    clearInterval(timerInterval);
                    setSubmitDone(true);
                    setIsSubmit(false);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };
    const [beforeUnloadActive, setBeforeUnloadActive] = useState(true);

    useEffect(() => {
        const handlePageReload = (e) => {
            if (beforeUnloadActive) {
                e.preventDefault();
                e.returnValue = '';
            } else {
                window.location.replace('/');
            }
        };

        window.addEventListener('beforeunload', handlePageReload);

        return () => {
            window.removeEventListener('beforeunload', handlePageReload);
        };
    }, [beforeUnloadActive]);

    const handleGoToHome = () => {
        setBeforeUnloadActive(false);

        setTimeout(() => {
            window.location.replace('/');
        }, 0);
    };


    function formatDurationDisplay(duration) {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);
        const seconds = Math.floor(duration - hours * 3600 - minutes * 60);

        const formatted = [hours, minutes, seconds].map((n) => (n < 10 ? '0' + n : n)).join(':');

        return formatted;
    }

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                const newTime = prevTime - 1;

                if (newTime <= 0) {
                    // Time has reached 0, submit the result
                    submitResult();
                    clearInterval(timer); // Stop the timer
                }

                return newTime;
            });
        }, 1000);
        setTimerInterval(timer);

        return () => {
            clearInterval(timerInterval);
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 80 ? 'h-20' : 'h-24';
        setScrollModal(scrollTop >= 80 ? 'top-14' : 'top-16');
        setSticky(stickyClass);
    };

    const getInforTopic = async () => {
        try {
            const response = await inforTopic(axiosPrivate, topicId, {
                signal: controller.signal,
            });

            if (response?.status === 'OK') {
                setTimeLeft(response.responseData.workTime * 60);
                props.setInfor(response.responseData);
            }
        } catch (error) { }
    };

    useEffect(() => {
        getInforTopic();

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <header
            className={`flex justify-start items-center mb-4 lg:px-20 px-10 bg-white shadow-shadow-header fixed z-[999] w-full ${sticky} top-[0]`}
        >
            <div className={`hidden lg:flex h-[240px] w-[30%] z-10`}>
                <div className="relative h-28 w-28 xl:h-[238px] xl:w-[238px] rounded-1/2 ml-2 xl:ml-[26px] bg-hotlink-yellow-f6ae2d mb-0.5">
                    <div className="absolute flex items-center justify-center h-28 w-28 xl:h-[238px] xl:w-[238px] right-5 lg:right-[26px] bg-hotlink-blue-309 rounded-1/2 top-0.5">
                        <Link to={config.routes.home}>
                            <img
                                className="logo-link object-contain h-16 w-16 xl:h-32 xl:w-32"
                                src={images.logo}
                                alt="MeU"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="lg:w-[40%] w-full flex justify-evenly items-center">
                <button className="bg-[#ff7839] box-shadow p-4 text-base" onClick={changeSubmit}>
                    Nộp bài
                </button>
                {timeLeft ? (
                    <p className="text-[#309] text-[22px]"> {formatDurationDisplay(timeLeft)}</p>
                ) : null}
                <p className="text-base">{user?.User?.name ? user.User.name.split(' ').pop() : ''}</p>
                <button className="bg-[#7158d7] box-shadow p-5" onClick={openModal}>
                    <img className="w-full h-full" src={ModalBtn} alt="" />
                </button>
            </div>
            <ToeicModal isOpen={isModalOpen} onClose={closeModal} scrollModal={scrollModal} />

            {isSubmit ? (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50 z-10">
                    <Result
                        className="flex justify-center items-center flex-col bg-white rounded-sm"
                        status="warning"
                        title="WARNING"
                        subTitle={
                            checkQuestionToChoice() === false
                                ? 'There is a question you have not chosen the answer yet. Do you want to continue submitting?'
                                : 'Are you sure you want to submit'
                        }
                        extra={[
                            <Button type="primary" danger key="1" onClick={changeSubmit}>
                                Continue the exam
                            </Button>,
                            <Button type="primary" className="bg-[#1677ff]" key="2" onClick={submitResult}>
                                Submit
                            </Button>,
                        ]}
                    />
                </div>
            ) : (
                ''
            )}

            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50 z-10">
                    <Spin tip="Loading" size="large" />
                </div>
            )}

            {submitDone && (
                <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50 z-10">
                    <Result
                        className="flex justify-center items-center flex-col bg-white rounded-sm"
                        status="success"
                        title="SUBMITTED SUCCESSFULLY"
                        extra={
                            <div className="flex flex-col">
                                <Button type="primary" danger key="1" onClick={handleGoToHome}>
                                    Go to home
                                </Button>
                                <Button type="primary" danger key="2"
                                    onClick={() => navigate('/test/detail?result=' + mockTestID)}
                                    className="mt-2">
                                    Go to details
                                </Button>
                            </div>
                        }
                    />
                </div>

            )}
        </header>
    );
}

export default ToeicExamHeader;
