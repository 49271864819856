import { RightArrowIcon, LeftArrowIcon } from '~/components/Icons';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import './HotNews.scss';
import HotNewsItem from './HotNewsItem';

import { listNewsUser } from '~/api/newsApi';

function HotNews() {
    const slider = useRef(null);
    const controller = new AbortController();

    const [data, setData] = useState([]);

    const getListNews = async () => {
        try {
            const response = await listNewsUser(
                { size: '5' },
                {
                    signal: controller.signal,
                },
            );

            if (response?.status === 'success') {
                const newData = response.responseData.map((item, i) => ({
                    key: item.newsId,
                    hotNewPicture: item.newsImage,
                    title: item.newsTitle,
                    description: item.newsContent,
                    day: item.updateAt.split(' ')[0],
                }));

                setData(newData);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        getListNews();

        return () => {
            controller.abort();
        };
    }, []);

    const listHotNews = data.map((element, index) => {
        return (
            <HotNewsItem
                key={index}
                hotNewPicture={element.hotNewPicture}
                title={element.title}
                day={element.day}
                description={element.description}
            />
        );
    });

    const settings = {
        customPaging: (i) => <div className="w-4 h-[3px] rounded-[1px] bg-hotlink-gray-a7a7a7"></div>,
        dots: true,
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        
    };

    return (
        <div className="w-full flex flex-col justify-start items-start lg:gap-8 gap-6 bg-hotlink-orange-fff2ec lg:px-20 px-6 py-10 relative">
            <span className="font-BeVietNamBold text-[40px] text-hotlink-blue-309 font-semibold">Tin nổi bật</span>
            <div className="w-full">
                {/* <Slider ref={slider} {...settings}>
                    {listHotNews}
                </Slider> */}
            </div>

            <div className="flex justify-between items-start absolute lg:bottom-7 bottom-[87%] lg:right-20 right-5">
                <div className="flex flex-row gap-4 h-8">
                    <button
                        onClick={() => slider?.current?.slickPrev()}
                        className="w-8 h-full flex justify-center items-center cursor-pointer bg-hotlink-white-e6e6e6 rounded-1/2 "
                    >
                        <LeftArrowIcon />
                    </button>
                    
                    <button
                        onClick={() => slider?.current?.slickNext()}
                        className="w-8 h-full flex justify-center items-center cursor-pointer bg-hotlink-white-e6e6e6 rounded-1/2"
                    >
                        <RightArrowIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HotNews;
