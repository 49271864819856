import React from 'react';
import images from '~/assets/images';

const LatestNewsItem = ({ news }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between h-full">
      <img 
        src={news.image || images.image404} 
        alt={news.name} 
        className="w-full h-48 object-cover rounded-t-lg"
      />
      <div className="p-4 flex-grow">
        <h3 className="text-2xl font-semibold text-gray-900 mb-2">{news.name}</h3>
        <p className="text-gray-600 text-base mb-4">{news.description}</p>
      </div>
      <div className="p-4 text-right text-sm text-gray-400">
        <span>{news.created_at}</span>
      </div>
    </div>
  );
};

export default LatestNewsItem;
