export const createTopicByExcel = (axiosPrivate, file,url, config = {}) => {
    return axiosPrivate
        .post(`/topic/createTopicByExcelFile?url=${url}`, file, config)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addListQuestionPart12 = (axiosPrivate,file, topicId, part, config = {}) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addListQuestionPart12ToTopicByExcelFile?part=${part}`, file, config )
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addListQuestionPart34 = (axiosPrivate, file, topicId, part, config = {}) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addListQuestionPart34ToTopicByExcelFile?part=${part}`, file, config )
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addListQuestionPart5 = (axiosPrivate, file, topicId, part, config = {}) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addListQuestionPart5ToTopicByExcelFile?part=${part}`, file, config )
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addListQuestionPart67 = (axiosPrivate,file, topicId, part, config = {}) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addListQuestionPart34ToTopicByExcelFile?part=${part}`, file, config )
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const addAllParts = (axiosPrivate, file, topicId, config = {}) => {
    return axiosPrivate
        .post(`/topic/${topicId}/addAllPartsToTopicByExcelFile`, file, config )
        .then((response) => {
            console.log("API Response:", response);
            console.log("Response Data:", response.data);
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};




