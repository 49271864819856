import images from '~/assets/images';
import './Intro.scss';
import { useTranslation } from 'react-i18next';

import { SearchIcon, CustomRectangle1 } from '~/components/Icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Intro = () => {
    const navigate = useNavigate();

    const { t } = useTranslation('common');

    const [searchValue, setSearchValue] = useState();

    console.log(searchValue)

    return (
        <div className="lg:h-[542px] h-[500px] lg:mb-[132px] mb-16 lg:pt-[87px] pt-10 pr-1 pb-[88px] bg-hotlink-orange-fff2ec relative">
            <CustomRectangle1 className="absolute z-[1]" />
            <div className="absolute flex">
                <div className="z-[2] lg:mt-[88px] lg:ml-[80px] ml-4 mt-16 flex flex-col justify-start items-start gap-10">
                    <div className="flex flex-col gap-2">
                        <span className="lg:text-[40px] text-[35px] font-semibold font-BeVietNamBold leading-[1.1] text-hotlink-blue-309">
                            Master English with
                            <span className="text-hotlink-orange-ff7839"> MEU</span>
                        </span>

                        <span className="w-full lg:text-[13px] text-xs font-BeVietNamRegular leading-[1.31] text-hotlink-gray-666666 lg:w-[400px] text-left">
                            "With MeU English, you can improve your English proficiency through comprehensive test
                            preparation materials and achieve great results in your exams."
                        </span>
                    </div>

                    <button className="lg:w-[328px] lg:h-[56px] w-[250px] h-[50px] flex justify-center items-center py-1 px-2.5 rounded-[100px] shadow-[0_2px_20px_0_rgba(0,0,0,0.25)] border-intro bg-hotlink-blue-309">
                        <a
                            className="font-poppinBold text-[BeVietNam22px] font-bold leading-none text-white first-letter:capitalize"
                            href=""
                        >
                            {t('register_course')}
                        </a>
                    </button>
                </div>

                <img
                    className="hidden lg:flex h-[480px] w-[786px] object-contain mt-[-17px]"
                    alt="intro"
                    src={images.introImage}
                ></img>
            </div>

            <div className="absolute lg:h-[152px] h-24 w-full flex lg:top-[482px] top-[440px]">
                <div className="lg:w-[calc(100%-160px)] w-full flex flex-row justify-center items-center gap-10 lg:mx-20 mx-6 bg-white shadow-search rounded-lg">
                    <span className="hidden lg:flex h-[44px] font-BeVietNamBold text-[34px] font-semibold leading-[1.29] text-hotlink-blue-309 uppercase">
                        {t('search_word')}
                    </span>
                    <div className="lg:w-[824px] w-4/5 lg:h-[72px] h-14 flex flex-row justify-start items-stretch rounded-[20px] border-search">
                        <button className="w-[48px] flex justify-center items-center py-[9px] px-3">
                            <SearchIcon />
                        </button>

                        <input
                            className="flex justify-start items-center lg:rounded-none rounded-[20px] lg:px-3 py-2 font-BeVietNamRegular lg:text-xl text-lg text-hotlink-black-000000D8 grow"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={t('enter_word')}
                        ></input>

                        <button
                            className="hidden lg:flex lg:items-center lg:justify-center w-[98px] border-solid border-[0.2px] border-hotlink-white-d9d9d9 bg-hotlink-blue-309 rounded-r-[20px]"
                            onClick={() => {
                                if(searchValue){
                                    navigate('dictionary?search=' + searchValue)
                                }
                            }}
                        >
                            <span className="text-base text-white first-letter:capitalize font-BeVietNamRegular leading-6 ">
                                {t('search')}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
