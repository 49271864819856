import React, { useState, useEffect } from 'react';
import { Input, Upload, message, notification, Spin } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { addFlashCardUser, updateFlashCard } from '~/api/flashCardApi';
import { CloseIcon } from '~/components/Icons';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';

const { TextArea } = Input;

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const AddFlashCard = (props) => {
    const [api, contextHolder] = notification.useNotification();
    const axiosPrivate = useAxiosPrivate();

    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(props.flashCard ? props.flashCard.flashCardImage : null);

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0].originFileObj;
            if (file) {
                setContentFlashCard((prevContentFlashCard) => ({
                    ...prevContentFlashCard,
                    image: file,
                }));
                const reader = new FileReader();
                reader.onload = (info) => {
                    setImageUrl(info.target.result);
                };
                reader.readAsDataURL(file);
            }
        } else {
            setImageUrl(null);
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const [contentFlashCard, setContentFlashCard] = useState({
        title: props.flashCard ? props.flashCard.flashCardTitle : '',
        description: props.flashCard ? props.flashCard.flashCardDescription : '',
        image: '',
        err: {},
    });

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };

    function validate() {
        if (contentFlashCard.title === '') {
            setErrMsg('Please enter title');
            setStatusMsg('Create flashCard fail');
            setContentFlashCard((prevContentFlashCard) => ({
                ...prevContentFlashCard,
                err: {
                    ...prevContentFlashCard.err,
                    title: `Please enter title`,
                },
            }));
            return false;
        }

        if (contentFlashCard.description === '') {
            setErrMsg('Please enter description');
            setStatusMsg('Create flashCard fail');
            setContentFlashCard((prevContentFlashCard) => ({
                ...prevContentFlashCard,
                err: {
                    ...prevContentFlashCard.err,
                    description: `Please enter description`,
                },
            }));
            return false;
        }

        if (!props.flashCard) {
            if (contentFlashCard.image === '') {
                setErrMsg('Please insert image');
                setStatusMsg('Create flashCard fail');
                setContentFlashCard((prevContentFlashCard) => ({
                    ...prevContentFlashCard,
                    err: {
                        ...prevContentFlashCard.err,
                        image: `Please insert image`,
                    },
                }));
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        setErrMsg('');
        setStatusMsg('');
    }, [contentFlashCard]);

    useEffect(() => {
        if (errMsg) {
            openNotification('top', statusMsg, errMsg);
        }
        if (errMsg !== 'Successful') {
            setErrMsg('');
            setStatusMsg('');
        }
    }, [errMsg, statusMsg]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                setIsLoading(true);
                const formData = new FormData();
                formData.append('flashCardTitle', contentFlashCard.title);
                formData.append('flashCardDescription', contentFlashCard.description);
                if (contentFlashCard.image !== '') {
                    formData.append('flashCardImage', contentFlashCard.image);
                }

                let response;

                if (props.flashCard) {
                    response = await updateFlashCard(axiosPrivate, props.flashCard.flashCardId, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                } else {
                    response = await addFlashCardUser(axiosPrivate, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                }

                if (response?.violations === 401) {
                    setErrMsg('Unauthorized');
                    setStatusMsg('Create flashCard fail');
                } else if (response?.status === 'fail') {
                    setErrMsg(response?.message);
                    setStatusMsg('Create flashCard fail');
                } else {
                    if (props.flashCard) {
                        setErrMsg('Successful');
                        setStatusMsg('Update flashCard successful');
                        props.updateFlashCard(response.responseData);
                    } else {
                        setErrMsg('Successful');
                        setStatusMsg('Create flashCard successful');
                        props.addFlashCardList(response.responseData);
                    }

                    props.closeAdd();
                }
            } catch (err) {
                console.log(err);
                setErrMsg('Create flashCard Failed');
                setStatusMsg('Create flashCard fail');
            } finally {
                setIsLoading(false);
            }
        }
    };
    return (
        <div className="fixed z-[101] top-0 left-0 w-full h-full bg-hotlink-gray-c6c6c6 bg-opacity-[0.75] flex justify-center items-center">
            {contextHolder}
            <div className="lg:w-1/2 w-[90%] h-4/5 flex flex-col bg-white lg:p-8 px-4 py-8 gap-8 rounded-xl overflow-y-auto">
                <header className="flex flex-row justify-between items-center">
                    <span className="text-xl text-hotlink-black-161616 after:max-w-full after:w-1/2 after:h-[3px] after:block after:bg-hotlink-red-ff4d4f">
                        {props.flashCard ? 'Cập nhật' : 'Thêm FlashCard'}
                    </span>
                    <button
                        onClick={() => {
                            props.closeAdd();
                        }}
                    >
                        <CloseIcon width="30" height="32" className="cursor-pointer" />
                    </button>
                </header>

                <form className="flex flex-col gap-8" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-row items-center justify-start gap-8">
                        <span className="font-BeVietNamRegular text-lg font-bold lg:w-1/3 w-4/5">Ảnh đại diện</span>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: "100%",
                                        objectFit: "contain",
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-4">
                        <span className="font-BeVietNamRegular text-lg font-bold">Nhập tiêu đề</span>
                        <Input
                            className="font-BeVietNamRegular text-base"
                            placeholder="Nhập tiêu đề"
                            defaultValue={contentFlashCard.title}
                            onChange={(e) => {
                                setContentFlashCard((prevContentFlashCard) => ({
                                    ...prevContentFlashCard,
                                    title: e.target.value,
                                    err: {
                                        ...prevContentFlashCard.err,
                                        title: e === '' ? 'Please enter your title' : null,
                                    },
                                }));
                            }}
                        />
                        {contentFlashCard.err.title && (
                            <div className="text-red-500 text-sm ml-8">{contentFlashCard.err.title}</div>
                        )}
                    </div>

                    <div className="flex flex-col items-start justify-start gap-4">
                        <span className="font-BeVietNamRegular text-lg font-bold">Nhập mô tả</span>
                        <TextArea
                            className="font-BeVietNamRegular text-base"
                            placeholder="Nhập mô tả"
                            rows={4}
                            defaultValue={contentFlashCard.description}
                            onChange={(e) => {
                                setContentFlashCard((prevContentFlashCard) => ({
                                    ...prevContentFlashCard,
                                    description: e.target.value,
                                    err: {
                                        ...prevContentFlashCard.err,
                                        description: e === '' ? 'Please enter your description' : null,
                                    },
                                }));
                            }}
                        />
                        {contentFlashCard.err.description && (
                            <div className="text-red-500 text-sm ml-8">{contentFlashCard.err.description}</div>
                        )}
                    </div>

                    <div className="flex justify-end items-center">
                        <button
                            className="h-12 cursor-pointer flex justify-center items-center py-2 px-4 rounded-lg shadow-shadow-new bg-hotlink-blue-4d27b3"
                            type="submit"
                        >
                            <span className="text-base font-BeVietNamBold leading-normal text-center font-medium text-white">
                                {props.flashCard ? 'Cập nhật' : 'Thêm FlashCard'}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
            {isLoading && (
                <div className="fixed z-[101] top-0 left-0 w-full h-screen flex justify-center items-center bg-black bg-opacity-50">
                    <Spin tip="Loading..." size="large"></Spin>
                </div>
            )}
        </div>
    );
};

export default AddFlashCard;
