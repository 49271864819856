import React, { useState, useEffect } from 'react';
import { ChevronDown, XmarkIcon } from '~/components/Icons';

import { Input, Select, DatePicker, InputNumber } from 'antd';

import { format } from 'date-fns';
import dayjs from 'dayjs';

import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { listPack } from '~/api/packApi';
import { listPart } from '~/api/partApi';

import UploadImage from '~/components/Upload/UploadImage';

const { RangePicker } = DatePicker;

const DescriptionExam = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const { topic, contentTopic, setContentTopic } = props;

    const [menuPacks, setMenuPacks] = useState([]);
    const [menuPart, setMenuPart] = useState([]);

    const [listPartExam, setListPartExam] = useState([]);

    const getListPack = async () => {
        try {
            const responsePack = await listPack(axiosPrivate);
            if (responsePack?.status === 'OK') {
                const items = responsePack.responseData.map((item, i) => ({
                    value: item.packId,
                    label: item.packName,
                }));
                setMenuPacks(items);
            }
            const responsePart = await listPart();
            if (responsePart?.status === 'OK') {
                const items = responsePart.responseData.map((item, i) => ({
                    value: item.partId,
                    label: item.partName.slice(0, 6),
                }));
                setMenuPart(items);
                setListPartExam(items);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getListPack();

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <div className="flex flex-col pb-8">
            <form className="flex flex-col gap-6 px-8 pt-4">
                <div className="flex flex-col items-center justify-start gap-4">
                    {/*Insert topic Name */}
                    <div className="flex flex-row items-start justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Tên đề thi:</span>
                        <Input
                            className="font-BeVietNamRegular text-base w-1/2"
                            placeholder="Tên đề thi"
                            defaultValue={contentTopic.topicName}
                            onChange={(e) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    topicName: e.target.value,
                                    err: {
                                        ...prevContentTopic.err,
                                        topicName: e === '' ? 'Please enter topic name' : null,
                                    },
                                }));
                            }}
                        />
                    </div>

                    {/*Insert topic Image */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Ảnh đại diện:</span>
                        <UploadImage
                            imageLink={contentTopic.linkImage}
                            setContent={(value) =>
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    topicImage: value,
                                }))
                            }
                        />
                    </div>

                    {/*Insert topic Description */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Mô tả:</span>
                        <Input
                            className="font-BeVietNamRegular text-base w-1/2"
                            placeholder="Mô tả đề thi"
                            defaultValue={contentTopic.topicDescription}
                            onChange={(e) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    topicDescription: e.target.value,
                                    err: {
                                        ...prevContentTopic.err,
                                        topicDescription: e === '' ? 'Please enter topic description' : null,
                                    },
                                }));
                            }}
                        />
                    </div>

                    {/*Insert topic type */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Loại đề thi:</span>
                        <Select
                            suffixIcon={<ChevronDown />}
                            className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            options={[
                                {
                                    value: 'Full',
                                    label: 'Full',
                                },
                                {
                                    value: 'Part',
                                    label: 'Part',
                                },
                                {
                                    value: 'Listening',
                                    label: 'Listening',
                                },
                                {
                                    value: 'Reading',
                                    label: 'Reading',
                                },
                            ]}
                            defaultValue={contentTopic.topicType}
                            placeholder="Chọn loại đề thi"
                            onChange={(value) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    topicType: value,
                                }));
                            }}
                        ></Select>
                    </div>
                    {/* Add part to exam */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Chọn Part:</span>
                        <Select
                            className="findExam-select w-1/2 h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            mode="multiple"
                            defaultValue={contentTopic.listPart}
                            allowClear={{ clearIcon: <XmarkIcon /> }}
                            options={menuPart}
                            onChange={(value) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    listPart: value,
                                }));
                            }}
                            placeholder="Chọn Part"
                        />
                    </div>

                    {/*Insert topic start time */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Thời gian làm bài:</span>
                        <InputNumber
                            className="w-1/2 font-BeVietNamRegular text-base"
                            placeholder="Thời gian làm bài"
                            defaultValue={contentTopic.workTime}
                            onChange={(value) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    workTime: value,
                                    err: {
                                        ...prevContentTopic.err,
                                        workTime: value === '' ? 'Please enter work time' : null,
                                    },
                                }));
                            }}
                        />
                    </div>

                    {/*Insert count question */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Số lượng câu hỏi:</span>
                        <InputNumber
                            className="w-1/2 font-BeVietNamRegular text-base"
                            placeholder="Số lượng câu hỏi"
                            defaultValue={contentTopic.numberQuestion}
                            onChange={(value) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    numberQuestion: value,
                                    err: {
                                        ...prevContentTopic.err,
                                        numberQuestion: value === '' ? 'Please enter work time' : null,
                                    },
                                }));
                            }}
                        />
                    </div>

                    {/*Insert packId */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Bộ đề thi:</span>
                        <Select
                            suffixIcon={<ChevronDown />}
                            className="findExam-select w-[28%] h-full rounded-none bg-hotlink-gray-f4f4f4 border-b-[1px] border-b-hotlink-gray-8d8d8d"
                            defaultValue={contentTopic.topicPack}
                            options={menuPacks}
                            placeholder="Chọn bộ đề thi"
                            onChange={(value) => {
                                setContentTopic((prevContentTopic) => ({
                                    ...prevContentTopic,
                                    topicPack: value,
                                }));
                            }}
                        ></Select>
                    </div>

                    {/*Insert startTime and endTime */}
                    <div className="flex flex-row items-center justify-start gap-4 w-full">
                        <span className="font-BeVietNamRegular text-base font-bold w-1/4">Thời hạn đề thi:</span>
                        <RangePicker
                            showTime={{
                                format: 'HH:mm',
                            }}
                            format="YYYY-MM-DD HH:mm"
                            defaultValue={[dayjs(contentTopic.startTime), dayjs(contentTopic.endTime)]}
                            onChange={(value) => {
                                if (value && value.length === 2) {
                                    const [startTime, endTime] = value.map((dateString) =>
                                        format(new Date(dateString), `yyyy-MM-dd'T'HH:mm:ss'`),
                                    );
                                    setContentTopic((prevContentTopic) => ({
                                        ...prevContentTopic,
                                        startTime: startTime,
                                        endTime: endTime,
                                    }));
                                }
                            }}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DescriptionExam;
