import { SoundIcon } from '~/components/Icons';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';

import axios from '~/api/axios';

const playSound = (sound) => {
    const audioElement = document.createElement('audio');
    audioElement.src = sound;
    audioElement.play();
};

const Meanings = (props) => {
    const location = useLocation();

    const [results, setResults] = useState([]);
    const [linkImage, setLinkImage] = useState([]);

    const searchWord = useCallback(() => {
        const currentWord = new URLSearchParams(location.search).get('search');
        return currentWord === null ? props.defaultKeyword : currentWord;
    }, [location.search, props.defaultKeyword]);

    useEffect(() => {
        const handleDictionaryResponse = async () => {
            try {
                const response = await axios.get(`/dictionary/search/${searchWord()}`);
                setResults(response.data.responseData);

                if (response.data.status === 'successful') {
                    const responseImage = await axios.get(`/dictionary/image/${searchWord()}`);
                    responseImage.data.responseData !== null
                        ? setLinkImage(responseImage.data.responseData.full)
                        : setLinkImage(null);
                } else {
                    setLinkImage(null);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        handleDictionaryResponse();
    }, [searchWord]);

    const dataSound = results[0]?.phonetics || [];

    const listSound = dataSound.map((element, index) => (
        <div key={index} className="flex flex-row items-center gap-2">
            <button onClick={() => playSound(element.audio)}>
                <SoundIcon />
            </button>

            <div className="text-xs text-hotlink-gray-5b5b5b">{element.text}</div>
        </div>
    ));

    const dataMeanings = results[0]?.meanings || [];

    const dataDefinitions = (index) => {
        return dataMeanings[index]?.definitions || [];
    };

    const dataSynonyms = (index) => {
        return dataMeanings[index]?.synonyms || [];
    };
    const dataAntonyms = (index) => {
        return dataMeanings[index]?.antonyms || [];
    };

    const listDefinitions = (count) => {
        return dataDefinitions(count).map((element, index) => (
            <li key={index} className="text-hotlink-black-333333 text-base list-[circle]">
                {element.definition}
            </li>
        ));
    };

    const listSynonyms = (count) => {
        if (dataSynonyms(count) && dataSynonyms(count).length > 0) {
            const synonymsText = dataSynonyms(count).join(', ');
            return (
                <div className="flex flex-row gap-2 text-base font-medium text-hotlink-black-3d3d3d">
                    <span>Synonyms:</span>
                    {synonymsText}
                </div>
            );
        } else {
            return null;
        }
    };

    const listAntonyms = (count) => {
        if (dataAntonyms(count) && dataAntonyms(count).length > 0) {
            const antonymsText = dataAntonyms(count).join(', ');
            return (
                <div className="flex flex-row gap-2 text-base font-medium text-hotlink-black-3d3d3d">
                    <span>Antonyms:</span>
                    {antonymsText}
                </div>
            );
        } else {
            return null;
        }
    };

    const listMeaning = dataMeanings.map((element, index) => (
        <div
            key={index}
            className="flex flex-col gap-2 text-base font-BeVietNamRegular font-medium text-hotlink-black-3d3d3d first-letter:uppercase"
        >
            <div className="first-letter:uppercase font-bold text-xl">{element.partOfSpeech}</div>
            <ul className="px-4">{listDefinitions(index)}</ul>
            {listSynonyms(index)}
            {listAntonyms(index)}
        </div>
    ));

    const listItemExample = (count) => {
        return dataDefinitions(count).map((element, index) =>
            element.example ? (
                <li key={index} className="text-[13px] text-hotlink-black-333333 text-base list-[circle]">
                    {element.example}
                </li>
            ) : null,
        );
    };

    const listExample = () => {
        return dataMeanings.map((element, index) =>
            listItemExample(index) ? (
                <div
                    key={index}
                    className="text-base font-medium text-hotlink-black-3d3d3d first-letter:uppercase"
                >
                    {element.partOfSpeech}
                    <ul className="px-4">{listItemExample(index)}</ul>
                </div>
            ) : null,
        );
    };

    return (
        <div className="flex flex-col justify-center items-start w-full lg:mb-[132px] mb-24 gap-4 bg-white shadow-shadow-meanings rounded-xl lg:p-8 py-8 px-4 pb-12">
            {results !== null && results.length > 0 ? (
                results === `Don't search` ? (
                    `Word does't have dictionary`
                ) : (
                    <div className="flex flex-col justify-start items-start gap-2">
                        <h1 className="text-lg text-hotlink-black-085 first-letter:uppercase">
                            {results[0].word}
                        </h1>
                        <div className="flex flex-row justify-start items-center gap-10">{listSound}</div>

                        <div className="flex lg:flex-row flex-col justify-start items-center gap-4 rounded-2xl shadow-[0_8px_24px_0_rgba(0, 0, 0, 0.1)] border-solid border border-[#fafaff] lg:p-8 lg:pl-0 py-8 px-4">
                            {linkImage !== null ? <img alt="test" className="lg:w-[19.4%] w-2/3" src={linkImage}></img> : null}
                            <div className="flex flex-col justify-center items-stretch gap-2 lg:px-4">{listMeaning}</div>
                        </div>
                        {listExample() === null ? (
                            <div className="flex flex-col gap-2 py-4 px-12 w-full rounded-xl bg-hotlink-blue-f3f7ff">
                                {listExample()}
                            </div>
                        ) : null}
                    </div>
                )
            ) : (
                'Searching...'
            )}
        </div>
    );
};

export default Meanings;
