import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const FlashCardBreadCrumb = () => {
    const breadcrumbItems = [
        {
            title: 'Trang chủ',
        },
        {
            title: (
                <Link to="/flash-card" className='!text-hotlink-blue-309 !cursor-pointer'>
                    FlashCard
                </Link>
            ),
        },
    ];

    return (
        <Breadcrumb className='text-sm' items={breadcrumbItems} />
    );
}

export default FlashCardBreadCrumb;
