import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { startLoading, stopLoading } from '~/redux/loadingSlice';
import Next from '~/assets/svg/exam-next.svg';
import Prev from '~/assets/svg/exam-prev.svg';

import { listPart } from '~/api/mockTestApi/mockTestApi';
import { getResultMockTestByPartAndMockTest, getResultMock } from '~/api/resultmocktestApi';
import ResultExamQuestion from '../ResultExamQuestion';

function ResultExamPart(props) {
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const mockTestId = new URLSearchParams(location.search).get('result');
    
    const getListPartTopic = async () => {
        try {
            dispatch(startLoading());

            const resultMockResponse = await getResultMock(axiosPrivate, mockTestId, {
                signal: controller.signal,
            });
            let totalScore = 0;

            let actualTime = 'N/A'; 
            if (resultMockResponse.status === 'success') {
                actualTime = resultMockResponse.responseData.time; 
                totalScore = resultMockResponse.responseData.score;
            }

            const response = await listPart(axiosPrivate, mockTestId, {
                signal: controller.signal,
            });

            if (response.status === 'success') {
                let listPartData = Array.isArray(response.responseData.Part)
                    ? response.responseData.Part
                    : [];

                listPartData = listPartData.sort((a, b) => {
                    const partA = a.partName.match(/\d+/) ? parseInt(a.partName.match(/\d+/)[0]) : 0;
                    const partB = b.partName.match(/\d+/) ? parseInt(b.partName.match(/\d+/)[0]) : 0;
                    return partA - partB;
                });

                const inforTopic = listPartData.length > 0
                    ? {
                        name: response.responseData.TopicName,
                        workTime: response.responseData.TopicTime, 
                        actualTime: actualTime, 
                        id: response.responseData.TopicId,
                        score: totalScore,
                    }
                    : {
                        name: 'N/A',
                        workTime: 0, 
                        actualTime: actualTime, 
                        id: '',
                        score: totalScore,
                    };

                const partsWithScores = await Promise.all(
                    listPartData.map(async (part) => {
                        try {
                            const scoreResponse = await getResultMockTestByPartAndMockTest( 
                                axiosPrivate,
                                part.partId,
                                mockTestId,
                                { signal: controller.signal }
                            );

                            if (scoreResponse.status === 'success' && scoreResponse.responseData.length > 0) {
                                const scoreData = scoreResponse.responseData[0];
                                return { ...part, score: scoreData.score };
                            } else {
                                return { ...part, score: 0 };
                            }
                        } catch (error) {
                            console.error(`Lỗi khi lấy điểm cho phần ${part.partId}:`, error);
                            return { ...part, score: 0 };
                        }
                    })
                );

                props.setState((prevState) => ({
                    ...prevState,
                    listPart: partsWithScores,
                    currentPart: partsWithScores[0] || null,
                    inforTopic: inforTopic, 
                }));
            }
        } catch (err) {
            console.error('Lỗi khi lấy danh sách phần:', err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const checkPart = () => {
        if (!props.state.currentPart) return -1;
        return props.state.listPart.findIndex((item) => item.partId === props.state.currentPart.partId);
    };

    const nextPart = (index) => {
        if (index + 1 < props.state.listPart.length) {
            scroll.scrollToTop();
            props.setState((prevState) => ({
                ...prevState,
                currentPart: prevState.listPart[index + 1],
            }));
        }
    };

    const prevPart = (index) => {
        if (index - 1 >= 0) {
            scroll.scrollToTop();
            props.setState((prevState) => ({
                ...prevState,
                currentPart: prevState.listPart[index - 1],
            }));
        }
    };

    const handlePartClick = (part) => {
        scroll.scrollToTop();
        props.setState((prevState) => ({
            ...prevState,
            currentPart: part,
        }));
    };

    useEffect(() => {
        getListPartTopic();
        return () => {
            controller.abort();
        };
    }, []);

    return (
        <div className={`w-full h-full flex text-center flex-col py-10 bg-[#f7f3ff]`}>
            <h1 className="lg:text-[40px] text-3xl font-BeVietnamSemiBold text-[#309]">{props.state.inforTopic.name}</h1>
            <div className="mt-2">
                <span className="text-lg font-BeVietnamSemiBold mb-2 block">
                    {t('time')}: {props.state.inforTopic.workTime} phút
                </span>
                <span className="text-lg font-BeVietnamSemiBold mb-2 block">
                    {t('Thời gian làm bài')}: {props.state.inforTopic.actualTime}
                </span>
            </div>
            <span className="text-lg font-BeVietnamSemiBold text-gray-800 mt-1 block">
                Tổng điểm: {props.state.inforTopic.score} 
            </span>
            <div className="flex justify-between lg:px-20 px-2">
                <div className="rounded-lg flex px-2 py-1 bg-white shadow cursor-pointer">
                    {props.state.listPart && props.state.listPart.length > 0 ? (
                        props.state.listPart.map((part, index) => (
                            <p
                                key={part.partId}
                                className={`lg:px-4 px-3 lg:py-3.5 py-1 lg:text-base text-sm font-BeVietNamMedium text-hotlink-black-262626 italic lowercase first-letter:uppercase ${
                                    props.state.currentPart.partId === part.partId ? 'active bg-[#f6ae2d] text-white' : 'hover:bg-gray-200'
                                }`}
                                onClick={() => handlePartClick(part)}
                            >
                                {part.partName.slice(0, 6)}
                            </p>
                        ))
                    ) : (
                        <p>Không có phần nào.</p>
                    )}
                </div>
            </div>

            {props.state.currentPart && (
                <div className="mt-4 lg:px-20 px-4">
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                        <p className="text-lg font-BeVietnamSemiBold text-gray-800 mb-2">
                            {props.state.currentPart.partName}
                        </p>
                        <p className="text-base font-medium text-yellow-500">
                            Điểm: {props.state.currentPart.score !== undefined
                                ? props.state.currentPart.score
                                : 'Chưa có điểm'}
                        </p>
                    </div>
                </div>
            )}

            <ResultExamQuestion state={props.state} />

            <div className="lg:px-20 px-10 mt-4 flex justify-end">
                {checkPart() === 0 || checkPart() === -1 ? (
                    <button className="bg-[#fff] p-4 mr-2 cursor-not-allowed" disabled>
                        <img src={Prev} alt="Prev" />
                    </button>
                ) : (
                    <button
                        className="bg-[#fff] p-4 mr-2 rotate-180 cursor-pointer"
                        onClick={() => prevPart(checkPart())}
                    >
                        <img src={Next} alt="Prev" />
                    </button>
                )}

                {checkPart() === props.state.listPart.length - 1 ? (
                    <button className="bg-[#fff] p-4 rotate-180 cursor-not-allowed" disabled>
                        <img src={Prev} alt="Prev" />
                    </button>
                ) : (
                    <button
                        className="bg-[#fff] p-4 cursor-pointer"
                        onClick={() => nextPart(checkPart())}
                    >
                        <img src={Next} alt="Next" />
                    </button>
                )}
            </div>
        </div>
    );
}

export default ResultExamPart;
