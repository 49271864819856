import React, { useState, useEffect } from 'react';

import { listExamResultsUser } from '~/api/userApi';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { RightIcon, LeftIcon } from '~/components/Icons';
import ProfileTestItem from './ProfileTestItem';

function ProfileTest() {
    const axiosPrivate = useAxiosPrivate();
    const controller = new AbortController();

    const [listExamResult, setListExamResult] = useState([]);
    const [totalPage, setTotalPage] = useState();

    const [queryParams, setQueryParams] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    const getListExamResultsUser = async () => {
        try {
            const response = await listExamResultsUser(axiosPrivate, {
                params: queryParams,
                signal: controller.signal,
            });
            if (response.status === 'OK') {
                setListExamResult(response.responseData.listTopicUser);
                setTotalPage(response.responseData.totalPage);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getListExamResultsUser();
        const page = queryParams && queryParams.page ? parseInt(queryParams.page, 10) + 1 : 1;
        setCurrentPage(page);

        return () => {
            controller.abort();
        };
    }, [queryParams]);

    function changePage(pageNumber) {
        const newQueryParams = { ...queryParams };
        newQueryParams.page = pageNumber;
        setQueryParams(newQueryParams);
    }
    function nextPage() {
        const newQueryParams = { ...queryParams };
        newQueryParams.page = currentPage;
        setQueryParams(newQueryParams);
    }
    function prevPage() {
        const newQueryParams = { ...queryParams };
        newQueryParams.page = currentPage - 2;
        setQueryParams(newQueryParams);
    }

    return (
        <div className="w-full flex flex-col gap-4 lg:mt-16 mt-4">
            {listExamResult && totalPage !== 0 && totalPage
                ? listExamResult.map((exam) => (
                    <ProfileTestItem key={exam.Topic.topicId} topic={exam.Topic} listMockTest={exam.listMockTest} />
                ))
                : null}

            {totalPage && totalPage !== 0 ? (
                <div className="flex flex-row justify-center items-center gap-[10px] p-2">
                    {currentPage !== 1 ? (
                        <button
                            className=" flex items-center justify-center h-8 w-8 p-[10px] rounded-sm border-solid border border-hotlink-white-d9d9d9 bg-white"
                            onClick={() => prevPage()}
                        >
                            <LeftIcon />
                        </button>
                    ) : null}

                    {totalPage &&
                        Array.from({ length: totalPage }).map((_, index) => {
                            if (
                                index === 0 ||
                                index === totalPage - 1 ||
                                (index >= currentPage - 2 && index <= currentPage + 2)
                            ) {
                                return (
                                    <button
                                        key={index}
                                        className={`flex items-center justify-center h-8 w-8 p-[10px] rounded-sm border-solid border bg-white  ${index + 1 === currentPage
                                                ? 'border-hotlink-blue-309'
                                                : ' border-hotlink-white-d9d9d9'
                                            }`}
                                        onClick={() => changePage(index)}
                                    >
                                        <span
                                            className={`font-BeVietNamRegular text-sm ${index + 1 === currentPage
                                                    ? 'text-hotlink-blue-309'
                                                    : 'text-hotlink-black-085'
                                                }`}
                                        >
                                            {index + 1}
                                        </span>
                                    </button>
                                );
                            }
                            if (
                                index === 1 ||
                                index === totalPage - 2 ||
                                (index >= currentPage - 2 && index <= currentPage + 2)
                            ) {
                                return (
                                    <span
                                        key={index}
                                        className="flex items-center justify-center h-8 w-8 p-[7px] font-BeVietNamRegular text-sm text-hotlink-black-025 text-center tracking-[2px] leading-[2.29]"
                                    >
                                        ...
                                    </span>
                                );
                            }
                            return null;
                        })}

                    {totalPage && currentPage !== totalPage ? (
                        <button
                            className=" flex items-center justify-center h-8 w-8 p-[10px] rounded-sm border-solid border border-hotlink-white-d9d9d9 bg-white"
                            onClick={() => nextPage()}
                        >
                            <RightIcon />
                        </button>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
export default ProfileTest;
