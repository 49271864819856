import { useEffect } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

import TopicCommentItem from '~/components/CommentTopic/TopicCommentItem';
import { getAllCommentToPost } from '~/api/postApi';

const BASE_URL = process.env.REACT_APP_API_URL;
var stompClient = null;

function PostComment(props) {
    const controller = new AbortController();

    const getListComment = async () => {
        try {
            const response = await getAllCommentToPost(props.postId, null, {
                signal: controller.signal,
            });

            if (response.status === 'OK') {
                props.setListComment(response.responseData);
            }
        } catch (err) { }
    };

    useEffect(() => {
        const fetchDataAndSubscribe = async () => {
            if (props.postId) {
                await getListComment();

                const socket = new SockJS(BASE_URL + '/ws');
                stompClient = Stomp.over(socket);
                stompClient.debug = () => { };
                try {
                    stompClient.connect(
                        {},
                        () => {
                            stompClient.subscribe(`/comment/post/${props.postId}`, (message) => {
                                const newComment = JSON.parse(message.body);
                                props.setListComment((prevComments) => [newComment, ...prevComments]);
                            });
                        },
                        (err) => { },
                    );
                } catch (error) {
                    // console.error("Error connecting to STOMP:", error);
                }
            }
        };

        fetchDataAndSubscribe();
        return () => {
            if (stompClient && stompClient.connected) {
                stompClient.disconnect();
            }
            controller.abort();
        };
    }, []);

    return (
        <div className="w-full bg-white py-4 gap-4 flex flex-col font-BeVietNamRegular text-xs border-t-[1px] border-hotlink-gray-dadbdd pt-4 mt-4">
            {props.listComment
                ? props.listComment.map((comment) => (
                    <TopicCommentItem
                        key={comment.commentId}
                        comment={comment}
                        listComment={props.listComment}
                        setListComment={props.setListComment}
                    />
                ))
                : null}
        </div>
    );
}
export default PostComment;
