import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';
import { Outlet } from 'react-router-dom';
import { FloatButton } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';

function DefaultLayout() {
    return (
        <div className="min-h-dvh grid grid-rows-[1fr_auto]">
            <div className="grid grid-rows-[auto_1fr]">
                {/* Header */}
                <Header />
                {/* Main Content */}
                <main className="min-w-screen min-h-screen overflow-hidden">
                    <Outlet />
                </main>
            </div>

            {/* Footer */}
            <Footer />

            {/* Scroll To Top Button */}
            <FloatButton.BackTop
                icon={<ArrowUpOutlined style={{ color: 'black', fontSize: '1.25rem' }} />}
                style={{
                    background: 'linear-gradient(135deg, #4F46E5, #3B82F6)',
                    height: '3rem',
                    width: '3rem',
                    border: 'none',
                    borderRadius: '50%',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s ease-in-out',
                }}
                className="transition-transform duration-300 hover:scale-110"
            />
        </div>
    );
}

export default DefaultLayout;
