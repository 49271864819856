import React, { useState, useEffect } from 'react';
import ShortTest from '../ShortTest/ShortTest';
import './Minitest.scss';
import { useTranslation } from 'react-i18next';
import { listTopicUser } from '~/api/topicApi';

function Minitest() {
    const { t } = useTranslation('common');
    const [showAll, setShowAll] = useState(false);
    const [shortTest, setShortTest] = useState([]);
    const controller = new AbortController();

    const fetchMiniTests = async () => {
        try {
            const response = await listTopicUser(
                { size: '12', topicType: 'Part' },
                { signal: controller.signal }
            );

            if (response?.status === 'OK') {
                const fetchedTests = response.responseData.listTopic.map((item) => ({
                    key: item.topicId,
                    title: item.topicName,
                    time: item.workTime,
                    quantity: item.numberQuestion,
                }));
                setShortTest(fetchedTests);
            }
        } catch (error) {
            console.error('Failed to fetch mini tests:', error);
        }
    };

    useEffect(() => {
        fetchMiniTests();

        return () => {
            controller.abort();
        };
    }, []);

    const displayedTests = showAll ? shortTest : shortTest.slice(0, 6);

    return (
        <div
            className="w-full flex justify-center text-center flex-col py-10 bg-[#f7f3ff]"
            data-aos="fade-right"
            data-aos-duration="1000"
        >
            <h1 className="lg:text-[40px] text-3xl font-BeVietnamSemiBold text-[#309]">{t('mini_test')}</h1>
            <div className="lg:px-20 px-5 flex flex-row flex-wrap justify-between lg:mt-8 mt-4">
                {displayedTests.map((test, index) => (
                    <ShortTest key={test.key} test={test} t={t} />
                ))}
            </div>
            <div className="mt-8">
                <button
                    className="px-20 py-3 rounded-xs shadow bg-[#4d27b3] hover:bg-[#3d1f8f] transition-colors duration-300"
                    onClick={() => setShowAll(!showAll)}
                >
                    <span className="flex justify-center text-base leading-normal text-center font-medium text-white">
                        {showAll ? t('show_less') : t('see_more')}
                    </span>
                </button>
            </div>
        </div>
    );
}

export default Minitest;
